import * as React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AxiosContext } from '../hoc/withAxios';

export const Muisnackbar = () => {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { toast, showToast } = React.useContext(AxiosContext);

  const handleClose = React.useCallback(() => {
    showToast({ show: false, message: '', severity: 'info' });
  }, [showToast]);

  return (
    <Snackbar
      autoHideDuration={10000}
      open={toast.show}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity={toast.severity}
        sx={{ width: '100%', borderRadius: 50, fontWeight: 'bold' }}
      >
        {toast.message}
      </Alert>
    </Snackbar>
  );
};
