import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth } from '../../../hooks/useAuth';
import { SignUpOnPremForm } from '../../../types/SignUpOnPrem';
import signupOnPremSchema from './schema';
import useCustomForm from '../../../components/form';
import styles from '../SignIn/style';
import {
  SubTitle,
  BannerTitle,
  Para,
  Margin,
  styles as TypographyStyles,
} from '../../../components/Typography';
import ThemedButton from '../../../components/ThemedButton';
import ControlledTextField from '../../../components/form/widgets/ControlledTextField';
import { Logo } from '../../../components/Logo';

const SignUpOnPrem = () => {
  const { registerExecutePrem } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useCustomForm(
    {
      name: '',
      email: '',
      password: '',
    },
    signupOnPremSchema
  );
  const handleData = (data: SignUpOnPremForm) => {
    registerExecutePrem({
      ...data,
    });
  };

  const classes = styles();

  const typographyStyles = TypographyStyles();

  return (
    <Grid item xs={12} md={5} lg={5} className={classes.loginForm}>
      <form
        key={1}
        className={classes.form}
        onSubmit={handleSubmit(handleData)}
      >
        <BannerTitle>Welcome</BannerTitle>
        <Margin />
        <SubTitle bold={true}>Create new account</SubTitle>

        <Margin />
        <Margin />

        <ControlledTextField
          placeholder="Enter your fullname"
          type="name"
          name="name"
          label="Fullname"
          fullWidth
          control={control}
          errors={errors}
        />

        <Margin />

        <ControlledTextField
          placeholder="e.g. username@domain.com"
          type="email"
          name="email"
          label="Email address"
          fullWidth
          control={control}
          errors={errors}
        />

        <Margin />

        <ControlledTextField
          placeholder="Password"
          type={'password'}
          fullWidth
          label="Password"
          name="password"
          control={control}
          errors={errors}
          ActiveIcon={Visibility}
          InActiveIcon={VisibilityOff}
        />

        <Margin />

        <Box textAlign={'center'} className={typographyStyles.mt5}>
          <ThemedButton type="submit" size="large" variant="contained">
            Sign Up
          </ThemedButton>
        </Box>

        <Box textAlign={'center'} className={typographyStyles.mt5}>
          <Para className={classes.inline} bold={true}>
            I already have an account?&nbsp;
            <Link to="/">
              <Para bold={true}>Sign-in Here</Para>
            </Link>
          </Para>
        </Box>
        <Logo
          style={{
            width: '100%',
          }}
        />
      </form>
    </Grid>
  );
};

export default SignUpOnPrem;
