import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';

type ResponsePdf = Blob;
type UseGetPdfParams = {
  namePdfFile: string;
  queryParams?: UseQueryOptions<ResponsePdf>;
};

export const useGetPdf = ({ namePdfFile, queryParams }: UseGetPdfParams) => {
  const { axios } = useContext(AxiosContext);
  return useQuery<ResponsePdf>({
    ...queryParams,
    queryKey: ['get-pdf', namePdfFile],
    queryFn: () => {
      return axios({
        url: `/pdf/${namePdfFile}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        if (response.data) {
          return response.data as ResponsePdf;
        }
        return Promise.reject(response.data.msg ?? 'pdf load success false');
      });
    },
  });
};
