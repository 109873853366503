import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import ExecuteWorkFlowContextProvider, {
  ExecuteWorkFlowContextProps,
  ExecuteWorkFlowContext,
} from './context';
import { Application } from './types/application';
import { Connection } from './types/connection';
import { Workflow } from './types/workflow';
import { Endpoint } from './types/endpoint';
import schema from './schema';
import { useEnvironment } from '../../hooks/useEnvironment';
import { ResizeLine } from '../../components/ResizeLine';
import ThemedButton from '../../components/ThemedButton';
import { ControlledTextArea } from '../../components/ControlledTextArea';
import {
  AutoCompleteOption,
  ControlledAutoComplete,
} from '../../components/ControlledAutoComplete';
import { ControlledJsonEditor } from '../../components/ControlledJsonEditor';
import useCustomForm from '../../components/form';
import ResultTable from './ResultTable';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import { colorsTheme } from '../../constants';
import { useExecuteWorkflow } from '../../hooks/api/mutations';

type OwnProps = unknown;

const controlledTextAreaStyle: React.CSSProperties = {
  width: '99%',
  borderRadius: '5px',
  resize: 'none', // Disable resizing
  border: 'none',
  outline: 'white',
};

const logTableStyle: React.CSSProperties = {
  maxWidth: '700px',
  whiteSpace: 'normal',
  overflowWrap: 'anywhere',
  paddingTop: '1rem',
};

const ExecuteWorkFlow = () => {
  const { connections, workflows, endpoints } =
    useContext<ExecuteWorkFlowContextProps>(ExecuteWorkFlowContext);

  const [logs, setLogs] = useState([]);
  const [results, setResults] = useState('');
  const [loading, setLoading] = useState(false);
  const { environment } = useEnvironment();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useCustomForm(
    {
      connection: null,
      isHl7Format: false,
      workflow: null,
      application: null,
      endpoint: null,
      payload: null,
    },
    schema
  );
  const { mutateAsync: mutateExecuteWorkflow } = useExecuteWorkflow();

  const connection = watch('connection') as Connection;
  const endpoint = watch('endpoint') as Endpoint;
  const workflow = watch('workflow') as Workflow;
  const application = watch('application') as AutoCompleteOption<Application>;
  const payload = watch('payload');
  const isHl7Format = watch('isHl7Format') as boolean;

  const showEditor = useMemo(
    () => endpoint && endpoint.type === 'application/json',
    [endpoint]
  );

  const endpointUrl = useMemo(() => {
    if (!endpoint) return null;
    let url: string = endpoint.url;
    if (connection) {
      url = url.replace('{connectionId}', connection.connectionId);
    }

    if (workflow) {
      url = url.replace('{workflowId}', workflow.workflowId);
    }

    if (application) {
      url = url.replace('{applicationId}', application.id);
    }

    url = url.replace(
      '{formatData}',
      endpoint?.id === 'executegeneric' && isHl7Format ? 'hl7' : 'json'
    );

    return url;
  }, [endpoint, connection, application, workflow, isHl7Format]);

  const onSubmit = useCallback(() => {
    setLoading(true);
    mutateExecuteWorkflow({
      contentType: endpoint.type,
      payload: payload as object,
      url: endpointUrl,
    })
      .then(({ data }) => {
        setResults(data.Result);
        setLogs(data.Logs);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [endpointUrl, payload, environment, endpoint, isHl7Format]);

  const [applications, setApplications] = useState<
    AutoCompleteOption<Application>[]
  >([]);

  useEffect(() => {
    if (connection) {
      const data = connection.applications
        ? Object.keys(connection.applications).map((i) => ({
            id: i,
            label: i,
          }))
        : [];
      setApplications(data as AutoCompleteOption<Application>[]);
      setValue('application', null);
    } else {
      setValue('application', null);
      setApplications([]);
    }
  }, [connection]);

  useEffect(() => {
    setValue('payload', endpoint?.type === 'application/json' ? {} : '');
  }, [endpoint]);

  const memoizedLogStyle = useCallback((i: number) => {
    const style: { backgroundColor: string; padding: number } = {
      backgroundColor: '',
      padding: 10,
    };
    if (i % 2 === 0) {
      style.backgroundColor = 'rgb(173, 170, 170,0.3)';
    }
    return style;
  }, []);

  const loadingStyle = useMemo(
    () => ({ display: 'flex', justifyContent: 'center' }),
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={3} className="applications-info">
            <ControlledAutoComplete
              control={control}
              controlName="endpoint"
              options={endpoints}
              label="EndPoint"
            />
          </Grid>
          <Grid item xs={3} className="applications-info">
            <ControlledAutoComplete
              control={control}
              controlName="connection"
              options={connections}
              label="Connections"
            />
          </Grid>
          <Grid item xs={3} className="applications-info">
            <ControlledAutoComplete
              control={control}
              controlName="application"
              options={applications}
              label="Applications"
            />
          </Grid>
          <Grid item xs={3} className="applications-info">
            <ControlledAutoComplete
              control={control}
              controlName="workflow"
              options={workflows}
              label="Workflows"
            />
          </Grid>
          {endpoint?.id === 'executegeneric' && (
            <Grid item>
              <Controller
                name="isHl7Format"
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label="HL7"
                  />
                )}
                control={control}
                defaultValue={false}
              />
            </Grid>
          )}
        </Grid>
        <Box sx={{ pt: 2, pb: 2 }}>
          <TextField
            label="Execution URL"
            variant="standard"
            sx={{ width: '100%' }}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: !!endpointUrl }}
            value={endpoint ? endpointUrl : ''}
          />
        </Box>

        <PanelGroup direction="horizontal">
          <Panel minSize={1} order={1}>
            <Grid item xs={12}>
              <Box sx={{ p: 2 }}>
                <Card sx={{ width: '98%', boxShadow: 8 }}>
                  {showEditor && (
                    <ControlledJsonEditor
                      control={control}
                      controlName="payload"
                      mode="code"
                    />
                  )}
                  {!showEditor && (
                    <ControlledTextArea
                      control={control}
                      controlName="payload"
                      style={controlledTextAreaStyle}
                      title="Add Inbound Payload"
                    />
                  )}
                </Card>
              </Box>
            </Grid>
          </Panel>
          <PanelResizeHandle>
            <ResizeLine />
          </PanelResizeHandle>
          <Panel minSize={1} order={2}>
            <Grid item xs={12} sx={{ pl: 4 }}>
              {!loading && (
                <>
                  <ResultTable
                    results={results}
                    style={controlledTextAreaStyle}
                  />
                  <TableContainer>
                    <Table aria-label="table">
                      <TableHead sx={{ '& th': { color: 'primary', p: 0.5 } }}>
                        <TableRow>
                          <TableCell>
                            {' '}
                            <Typography
                              sx={{
                                textTransform: 'capitalize',
                                px: 4,
                                fontWeight: 'bold',
                              }}
                            >
                              Logs
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        id="tb"
                        sx={{
                          '& td': {
                            color: 'text.primary',
                            p: 0,
                            height: '10px',
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell colSpan={5} style={logTableStyle}>
                            <Box className="loginExecutorClass">
                              {logs && logs.length > 0 ? (
                                logs.map((i, index) => (
                                  <div
                                    key={index}
                                    style={memoizedLogStyle(index)}
                                  >
                                    {i}
                                  </div>
                                ))
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  No Records Found
                                </div>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              {loading && (
                <div style={loadingStyle}>
                  <CircularProgress
                    sx={{
                      color: 'primary',
                    }}
                    size="2rem"
                  />
                </div>
              )}
            </Grid>
          </Panel>
        </PanelGroup>
        <Grid item xs={12} sm={12} lg={12}>
          <Grid container direction="row" justifyContent="center">
            <Box sx={{ position: 'fixed', bottom: 40 }}>
              <ThemedButton
                disabled={!isValid}
                type="submit"
                size="large"
                variant="contained"
                sx={{
                  backgroundColor: 'primary',
                  borderRadius: '20px 20px 20px 20px',
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'primary',
                  },
                  color: colorsTheme.white,
                  width: {
                    lg: '300px',
                    md: '200px',
                    sm: '100px',
                    xs: '50px',
                  },
                  fontSize: '1.1rem',
                }}
              >
                Execute{' '}
              </ThemedButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

const ExecuteWorkFlowWrapper: React.FC<OwnProps> = () => {
  return (
    <ExecuteWorkFlowContextProvider>
      <ExecuteWorkFlow />
    </ExecuteWorkFlowContextProvider>
  );
};

export default ExecuteWorkFlowWrapper;
