import React, { useContext, memo, useMemo } from 'react';
import { Grid } from '@mui/material';
import { getTableInfo } from './data';
import { ConfigurationType } from '../../../types/Configuration';
import ConfigCard from './ConfigCard';
import { AuthenticatedRouteContext } from '../../../Navigation';

const ConfigType = memo(() => {
  const { schema } = useContext(AuthenticatedRouteContext);

  const tables: ConfigurationType[] = useMemo(() => {
    return getTableInfo(schema);
  }, [schema]);

  if (tables.length == 0) {
    return;
  }

  return (
    <Grid container sx={{ marginBottom: 12 }}>
      {tables.length &&
        tables.map((config: ConfigurationType, index: number) => {
          return <ConfigCard key={index} {...config} />;
        })}
    </Grid>
  );
});

ConfigType.displayName = 'ConfigType';

export default ConfigType;
