import { SxProps } from '@mui/material';
import List from '@mui/material/List';
import ListItem, { listItemClasses } from '@mui/material/ListItem';
import React from 'react';
import { colorsTheme } from '../constants';
import { MenuItem } from './data';

const stylesHover = {
  '&:hover': {
    cursor: 'pointer',
  },
};

interface MenuProps {
  onLinkClick: (route: string) => void;
  items: MenuItem[];
  sx?: SxProps;
}

export const Menu: React.FC<MenuProps> = ({ onLinkClick, items, sx = {} }) => {
  return (
    <List
      sx={{
        [`& .${listItemClasses.root}:hover`]: {
          backgroundColor: 'primary',
          fontWeight: 'bold',
        },
        ...sx,
      }}
    >
      {items.map((i, index) => {
        const { optIcon: Icon } = i;

        return (
          <ListItem
            key={index}
            sx={{
              display: 'block',
              ...stylesHover,
              ...(index === 0 && { mt: 2 }),
            }}
            style={
              (i.route !== '/' && location.pathname.includes(i.route)) ||
              location.pathname === i.route
                ? { backgroundColor: colorsTheme.tiffanyBlue }
                : {}
            }
            onClick={() =>
              !i.targetBlank
                ? onLinkClick(i.route)
                : window.open(i.route, '_blank')
            }
          >
            {!i.optIcon &&
              i.icon &&
              i.icon({
                width: '35px',
                marginRight: '20px',
                verticalAlign: 'middle',
              })}
            {Icon && (
              <Icon
                sx={{
                  color: colorsTheme.white,
                  width: '35px',
                  height: '35px',
                  marginRight: '20px',
                  verticalAlign: 'middle',
                }}
              />
            )}
            <span style={{ fontWeight: 'bold', color: colorsTheme.white }}>
              {i.name}
            </span>
          </ListItem>
        );
      })}
    </List>
  );
};
