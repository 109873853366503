import { CSSProperties } from 'react';
import logo from '../assets/images/logo.jpg';
import smallLogo from '../assets/images/smallLogo.jpg';

export type LogoProps = {
  type?: 'small' | 'standart';
  style?: CSSProperties;
};

export const Logo = ({ type = 'standart', style }: LogoProps) => {
  return <img src={type === 'standart' ? logo : smallLogo} style={style} />;
};
