import React, { FC } from 'react';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { useAuth } from '../hooks/useAuth';

import { Muisnackbar } from '../hooks/snackbar';
import Box from '@mui/material/Box';
import { useConfirmationDialog } from '../hooks/useConfirmationDialog';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import { Sidebar } from './Sidebar';
import { colorsTheme } from '../constants';

interface LayoutProps {
  children?: React.ReactNode;
}

const FullLayout: FC<LayoutProps> = ({ children }) => {
  const { res, setres, open } = useAuth();
  const { confirmation } = useConfirmationDialog();
  const navigate = useNavigate();

  const handleChangeLink = async (link: string) => {
    if (res) {
      const confirmationBack = await confirmation();
      if (confirmationBack) {
        setres(false);
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <Sidebar onLinkClick={handleChangeLink} />
        <Box
          sx={{
            flex: 1,
            transition: 'all 0.5s ease-in-out',
            marginLeft: open ? '200px' : '40px',
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              height: '100%',
              minHeight: '100vh',
              backgroundColor: colorsTheme.antiFlashWhite,
              paddingTop: '60px',
              margingBottom: '40px',
              paddingLeft: '36px!important',
            }}
          >
            <CustomBreadcrumb
              handleChangeLink={handleChangeLink}
            ></CustomBreadcrumb>
            {children}
          </Container>
          <Muisnackbar />
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default FullLayout;
