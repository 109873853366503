import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';

type ResponseConfigList = {
  data: object[];
  extra: {
    column: string[];
    schema: string;
  };
  success: boolean;
  msg?: string;
};
type UseGetConfigListParams = {
  endpoint: string;
  queryParams?: UseQueryOptions<ResponseConfigList>;
};

export const useGetConfigList = ({
  queryParams,
  endpoint,
}: UseGetConfigListParams) => {
  const { axios } = useContext(AxiosContext);
  return useQuery<ResponseConfigList>({
    ...queryParams,
    queryKey: ['get-status-check', endpoint],
    queryFn: () => {
      return axios({
        url: `/api/${endpoint}`,
        method: 'GET',
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseConfigList;
        }
        return Promise.reject(
          response.data.msg ?? 'config list load success false'
        );
      });
    },
  });
};
