import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { UserData } from './types';
import { SchemaProperties } from '../models/configuration/types';

type Storage = {
  value: UserData & {
    components?: {
      schemas: SchemaProperties;
    };
  };
  setValue: (value?: unknown) => void;
  clear: (value?: unknown) => void;
};

export const useStorage = (keyName: string, defaultValue?: object): Storage => {
  const [value, setValue] = useLocalStorage(keyName, defaultValue);
  const clear = useCallback(() => setValue(undefined), [setValue]);
  return { value, setValue, clear };
};
