import { memo, useMemo, useCallback, useEffect, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAuth } from '../hooks/useAuth';
import { useStorage } from '../hooks/useStorage';
import { ConfirmationDialog } from './ConfirmationDialog';
import ThemedButton from './ThemedButton';
import { colorsTheme } from '../constants';
// To parse the JWT token

const SessionAlert: FC<{
  expiringTime: number;
  getRemainingTime: number;
  onClose: () => void;
  reset: () => void;
}> =
  // eslint-disable-next-line react/prop-types
  memo(({ getRemainingTime, expiringTime, onClose, reset }) => {
    const { refreshToken, logout } = useAuth();
    const { setValue: setPrompt } = useStorage('prompt', null);
    // To calculate the Remaining seconds and minutes
    const formattedTime = useMemo(() => {
      const minutes = Math.floor(getRemainingTime / 1000 / 60);
      const remainingSeconds = Math.floor((getRemainingTime / 1000) % 60);
      return minutes === 0
        ? `${remainingSeconds} seconds`
        : `${minutes} minutes ${remainingSeconds} seconds`;
    }, [getRemainingTime]);

    // To logout and close the popup
    const handlelogout = useCallback(() => {
      onClose();
      logout();
    }, [onClose]);

    const keepLoggingIn = useCallback(() => {
      refreshToken()
        .then(() => {
          reset();
          onClose();
        })
        .catch(() => logout());
    }, [onClose, reset]);

    useEffect(() => {
      setPrompt(true as unknown);
    }, []);

    useEffect(() => {
      if (expiringTime - 1000 < getRemainingTime) {
        onClose();
      }
    }, [expiringTime, getRemainingTime]);

    return (
      <ConfirmationDialog
        open={true}
        title="Session Expiration Warning"
        isPrompt
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Session Expiration Warning
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <>
            Your session will expire <strong>{formattedTime}</strong>.
            <br />
            To continue working, select &quot;Keep me logged in.&quot;To end
            your session, select &quot;End session.&quot;
          </>
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <ThemedButton
            onClick={keepLoggingIn}
            size="large"
            sx={{
              backgroundColor: 'primary',
              borderRadius: '20px 20px 20px 20px',
              textTransform: 'capitalize',
              fontWeight: 'bold',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'primary',
              },
              color: colorsTheme.white,
              pr: 2,
              pl: 2,
              mr: 3,
            }}
          >
            Keep me logged in
          </ThemedButton>
          <ThemedButton
            onClick={handlelogout}
            size="medium"
            sx={{
              backgroundColor: 'primary',
              borderRadius: '20px 20px 20px 20px',
              textTransform: 'capitalize',
              fontWeight: 'bold',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'primary',
              },
              color: colorsTheme.white,
              pr: 2,
              pl: 2,
            }}
          >
            End Session
          </ThemedButton>
        </Box>
      </ConfirmationDialog>
    );
  });
SessionAlert.displayName = 'SessionAlert';

export default SessionAlert;
