import RJSForm from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, IconButton, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import './style.scss';
import ArrayFieldTpl from './ArrayFieldTemplate';
import ErrorListTemplate from './ErrorListTemplate';
import InputFieldTemplate from './InputFieldTemplate';
import MyObjectFieldTemplate from './MyObjectFieldTemplate';
import { ResizeLine } from '../../../components/ResizeLine';
import KeyboardDoubleArrowDownOutlined from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import { ConfigDetailContextType } from '../../../models/configuration/types';
import ThemedButton from '../../../components/ThemedButton';
import useConfigDetail, { ConfigDetailProps } from './useConfigDetail';

import { RawJsonEditor } from '../../../components/ControlledJsonEditor';
import Loader from './Loader';
import { colorsTheme } from '../../../constants';

export const ConfigDetailContext = createContext<ConfigDetailContextType>({
  collapse: true,
  updateSelected: () => {
    return;
  },
});
type ConfigFormProps = ConfigDetailProps;

export const ConfigForm = (props: ConfigFormProps) => {
  const [collapse, setCollapseState] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formVisibility, setFormVisibility] = useState(true);
  const handleToggleFormVisibility = () => setFormVisibility(!formVisibility);
  const [jsonFormVisibility, setJsonFormVisibility] = useState(true);
  const handleToggleJsonFormVisibility = () =>
    setJsonFormVisibility(!jsonFormVisibility);
  const {
    formDataRes,
    handelCancel,
    onSubmit,
    schema,
    formschema,
    handleChange,
    setSelectedItem,
    endpoint,
    actionType,
    onError,
  } = useConfigDetail(props);
  useEffect(() => {
    setEditing(false);
  }, [props.actionType, props.configId, props.endpoint, props.formData]);

  const updateCollapse = useCallback(() => {
    setCollapseState(!collapse);
  }, [collapse]);

  const contextValue: ConfigDetailContextType = useMemo(
    () => ({
      collapse,
      updateSelected: (data) => {
        setSelectedItem(data as object);
      },
    }),
    [collapse]
  );

  const ref = schema ? `#/components/schemas/${schema}` : null;

  if (ref && Object.keys(formschema).length) {
    return (
      <>
        <Grid container spacing={1}>
          <PanelGroup direction="horizontal">
            {endpoint !== 'transforms' && formVisibility && (
              <Panel minSize={1} defaultSize={65} order={1}>
                <Grid item xs={12}>
                  <Box sx={{ p: 2, pb: 8 }}>
                    <Card
                      sx={{
                        boxShadow: 10,
                        overflowY: 'auto',
                        pl: 3,
                        pr: 6,
                        pb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          top: '0px',
                          backgroundColor: 'white',
                          zIndex: 1,
                          position: 'sticky',
                          height: '0px',
                          marginRight: '-40px',
                        }}
                      >
                        <Stack direction="row" justifyContent="flex-end">
                          <Box
                            sx={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Stack direction="row" justifyContent="flex-end">
                              <Button onClick={updateCollapse}>
                                {!collapse ? (
                                  <KeyboardDoubleArrowDownOutlined />
                                ) : (
                                  <KeyboardDoubleArrowUpOutlinedIcon />
                                )}
                                {collapse ? 'Collapse All' : 'Expand All'}
                              </Button>
                              <IconButton
                                onClick={handleToggleJsonFormVisibility}
                              >
                                {jsonFormVisibility ? (
                                  <FullscreenIcon />
                                ) : (
                                  <FullscreenExitIcon />
                                )}
                              </IconButton>
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                      <ConfigDetailContext.Provider value={contextValue}>
                        <RJSForm
                          schema={{ ...formschema, $ref: ref }}
                          formData={formDataRes}
                          liveValidate={editing}
                          onChange={handleChange}
                          omitExtraData={true}
                          onError={onError}
                          liveOmit={true}
                          validator={validator}
                          formContext={{
                            isEdit: actionType === 'edit',
                          }}
                          templates={{
                            ErrorListTemplate: ErrorListTemplate,
                            ObjectFieldTemplate: MyObjectFieldTemplate,
                            FieldTemplate: InputFieldTemplate,
                            ArrayFieldTemplate: ArrayFieldTpl,
                          }}
                          onSubmit={onSubmit}
                          id="myform"
                          noHtml5Validate={true}
                          uiSchema={{
                            'ui:options': {
                              orderable: true,
                            },
                          }}
                        />
                      </ConfigDetailContext.Provider>
                    </Card>
                  </Box>
                </Grid>
              </Panel>
            )}
            <PanelResizeHandle>
              <ResizeLine />
            </PanelResizeHandle>
            {jsonFormVisibility && (
              <Panel minSize={1} order={2}>
                <Grid item xs={12}>
                  <Box sx={{ p: 2 }}>
                    <Card sx={{ boxShadow: 10 }}>
                      <Box position="relative">
                        <IconButton
                          onClick={handleToggleFormVisibility}
                          sx={{
                            position: 'absolute',
                            right: 0,
                            zIndex: 1,
                            color: colorsTheme.white,
                            opacity: '0.8',
                          }}
                        >
                          {formVisibility ? (
                            <FullscreenIcon />
                          ) : (
                            <FullscreenExitIcon />
                          )}
                        </IconButton>
                      </Box>
                      <RawJsonEditor
                        value={formDataRes}
                        onChange={(data) =>
                          handleChange({
                            formData: data,
                            editing: true,
                          })
                        }
                        mode="code"
                        schema={formschema}
                        buttons={endpoint === 'transforms'}
                        actionType={actionType}
                        copy={props.copy}
                        submitFunc={onSubmit}
                        onCancel={handelCancel}
                      />
                    </Card>
                  </Box>
                </Grid>
              </Panel>
            )}
          </PanelGroup>
          {endpoint !== 'transforms' && (
            <Grid item xs={12} sm={12} lg={12}>
              <Grid container direction="row" justifyContent="center">
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: 40,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '25%',
                  }}
                >
                  <ThemedButton
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      setEditing(true);
                    }}
                    form="myform"
                  >
                    {actionType === 'edit'
                      ? 'Update'
                      : actionType === 'create' && props.copy
                      ? 'Add to folder'
                      : 'Create'}
                  </ThemedButton>

                  <ThemedButton onClick={handelCancel} size="large" negative>
                    Cancel
                  </ThemedButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  } else {
    return <Loader />;
  }
};
