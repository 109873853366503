import { useEffect } from 'react';
import { useAtom } from 'jotai';

import { workflowsAtom } from '../state';
import { normalizatorTree } from '../helpers';
import { useGetFolders } from '../../../hooks/api/queries';

export const useGetWorksFlows = () => {
  const { data: dataFolders, isLoading, refetch } = useGetFolders();
  const [workflows, setWorkflows] = useAtom(workflowsAtom);
  const getWorkFlows = () => refetch();
  useEffect(() => {
    if (!isLoading && dataFolders?.data) {
      setWorkflows(normalizatorTree(dataFolders.data));
    }
  }, [isLoading, dataFolders]);

  return {
    loadingWorksFlows: isLoading,
    workflows,
    setWorkflows,
    getWorkFlows,
  };
};
