import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/material';

const ResultTable = ({ results, style }) => {
  return (
    <TableContainer>
      <Table aria-label="table">
        <TableHead sx={{ '& th': { color: 'primary', p: 0.5 } }}>
          <TableRow>
            <TableCell>
              {' '}
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  px: 4,
                  fontWeight: 'bold',
                }}
              >
                Results
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="tb" sx={{ '& td': { color: 'text.primary', p: 0 } }}>
          {results && results.length > 0 ? (
            <TextareaAutosize
              value={results}
              style={style}
              className="resultExecutorClass"
              readOnly
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className="resultExecutorClass"
            >
              No Results Found
            </div>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultTable;
