import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';

type ResponseExecuteWorkflow = {
  data: {
    Result: string;
    Logs: unknown[];
  };
  success: boolean;
  message?: string;
};
type UseExecuteWorkflowValiables = {
  url: string;
  contentType: 'text/plain' | 'application/json';
  payload: object;
};
type UseExecuteWorkflowParams = {
  mutationParams?: UseMutationOptions<
    ResponseExecuteWorkflow,
    unknown,
    UseExecuteWorkflowValiables
  >;
};

export const useExecuteWorkflow = ({
  mutationParams,
}: UseExecuteWorkflowParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['execute-workflow'],
    mutationFn: ({
      url,
      payload,
      contentType,
    }: UseExecuteWorkflowValiables) => {
      return axios({
        url: `/api/${url}`,
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': contentType,
        },
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseExecuteWorkflow;
        }
        return Promise.reject(
          response.data.msg ?? 'create config success false'
        );
      });
    },
  });
};
