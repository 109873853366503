import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function Error404Page() {
  return (
    <Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '20%' }}>
        <Grid>
          <h2>Ooops... 404!</h2>

          <p>The page you requested could not be found.</p>

          <Link to="/">Back to Home</Link>
        </Grid>
      </Box>
    </Grid>
  );
}

export default Error404Page;
