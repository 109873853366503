import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth } from '../../../hooks/useAuth';
import useCustomForm from '../../../components/form';
import { LoginForm } from '../../../types/SignIn';
import ForgotPassword from '../../../modals/ForgotPassword';
import ThemedButton from '../../../components/ThemedButton';
import ControlledTextField from '../../../components/form/widgets/ControlledTextField';
import loginSchema from './schema';
import {
  SubTitle,
  BannerTitle,
  Para,
  Margin,
  styles as TypographyStyles,
} from '../../../components/Typography';
import styles from './style';
import { Logo } from '../../../components/Logo';

const SignIn = () => {
  const {
    login,
    response,
    forgot,
    setResponse,
    setVerificationBoxopen,
    verificationBoxopen,
    success,
    setsuccess,
    resetPassword,
  } = useAuth();

  useEffect(() => {
    if (response) {
      setVerificationBoxopen(response);
    }
    if (success) {
      setsuccess(false);
    }
  }, [response, success]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useCustomForm(
    {
      email: '',
      password: '',
    },
    loginSchema
  );

  const handleData = (data: LoginForm) => {
    login(data);
  };

  const close = () => {
    setVerificationBoxopen(false);
    setResponse(false);
    setsuccess(true);
  };

  const classes = styles();

  const typographyStyles = TypographyStyles();

  return (
    <>
      <Grid item xs={12} md={5} lg={5} className={classes.loginForm}>
        <form className={classes.form} onSubmit={handleSubmit(handleData)}>
          <BannerTitle>Welcome</BannerTitle>
          <Margin />
          <SubTitle bold={true}>Login to your account</SubTitle>

          <Margin />

          <ControlledTextField
            placeholder="e.g. username@domain.com"
            type="email"
            name="email"
            label="Email address"
            fullWidth
            control={control}
            errors={errors}
          />

          <Margin />

          <ControlledTextField
            placeholder="Password"
            type={'password'}
            fullWidth
            label="Password"
            name="password"
            control={control}
            errors={errors}
            ActiveIcon={Visibility}
            InActiveIcon={VisibilityOff}
          />
          <Box textAlign={'right'} className={typographyStyles.mt5}>
            <Para
              onClick={() => setVerificationBoxopen(true)}
              className={classes.clickable}
              color="primary"
            >
              Forgot password ?
            </Para>
          </Box>

          <Box textAlign={'center'} className={typographyStyles.mt5}>
            <ThemedButton>Login</ThemedButton>
          </Box>

          <Box textAlign={'center'} className={typographyStyles.mt5}>
            <Para className={classes.inline} bold={true}>
              Don&lsquo;t have an account ?&nbsp;
              <Link to="/sign-up">
                <Para bold={true} color="primary">
                  Create new
                </Para>
              </Link>
            </Para>
          </Box>
          <Logo
            style={{
              width: '100%',
            }}
          />
        </form>
      </Grid>
      <ForgotPassword
        close={close}
        isOpen={verificationBoxopen}
        forgot={forgot}
        response={response}
        resetPassword={resetPassword}
      />
    </>
  );
};

export default SignIn;
