import { AxiosContext } from '../../../hoc/withAxios';
import { useDeleteConfigById } from '../../../hooks/api/mutations';
import { useGetConfigList } from '../../../hooks/api/queries';
import { useAuth } from '../../../hooks/useAuth';
import { useEffect, useCallback, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';

const useConfigList = () => {
  const { showToast } = useContext(AxiosContext);
  const { endpoint } = useParams();
  const [ItemData, setItemData] = useState(null);

  const { setErrMsg, errMsg = '' } = useAuth();

  const {
    data: dataConfigList,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetConfigList({
    endpoint,
  });
  useEffect(() => {
    if (!isLoading) {
      setItemData(dataConfigList);
    }
  }, [dataConfigList, isLoading]);
  useEffect(() => {
    if (isError) {
      setErrMsg(`${error}`);
    } else {
      setErrMsg('');
    }
  }, [error, isError]);

  const { mutateAsync: mutateDeleteConfig } = useDeleteConfigById({
    mutationParams: {
      onSettled: () => {
        refetch();
        showToast({
          show: true,
          message: 'Successfully deleted the record',
          severity: 'success',
        });
      },
      onError: (error: string) => {
        showToast({
          show: true,
          message: error ?? 'Error delete record',
          severity: 'error',
        });
      },
    },
  });
  const deleteList = useCallback(
    (id) => {
      mutateDeleteConfig({
        configId: id,
        endpoint,
      });
    },
    [ItemData]
  );

  const data = useMemo(() => {
    return ItemData && ItemData.data && ItemData.data.length
      ? ItemData.data
      : [];
  }, [ItemData]);

  const keys = useMemo(
    () => (data && data.length > 0 ? Object.keys(data[0]) : []),
    [data]
  );

  const columns = useMemo(() => {
    return ItemData &&
      ItemData.extra &&
      ItemData.extra.column &&
      ItemData.extra.column.length
      ? ItemData.extra.column
      : [];
  }, [ItemData]);

  return {
    data: data && data.length > 0 ? data : null,
    columns,
    setData: setItemData,
    deleteListItem: deleteList,
    errorMessage: errMsg,
    keys,
  };
};

export default useConfigList;
