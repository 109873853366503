import React, { useContext, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ObjectFieldTemplateProps,
  FormContextType,
  RJSFSchema,
} from '@rjsf/utils';

import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import ComboBox from '../../ComboBox';
import { FormConfigs } from '../../../models/workFlowTree/types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ConfigDetailContext } from './ConfigForm';
import { colorsTheme } from '../../../constants';

function FormGrid(
  props: ObjectFieldTemplateProps<FormConfigs, RJSFSchema, FormContextType>
) {
  const { properties, title, schema, onAddClick, formContext } = props;
  return (
    <Grid container={true} spacing={2} sx={{ mt: '10px' }}>
      {properties.map((element, index) => {
        const isPrimaryKey = element?.content?.props?.schema['x-primary-key'];
        const updatedelement =
          isPrimaryKey && typeof element === 'object' && formContext?.isEdit
            ? {
                ...element,
                content: {
                  ...element?.content,
                  props: {
                    ...element?.content?.props,
                    readonly: true,
                  },
                },
              }
            : element;
        return (
          <Grid
            item={true}
            xs={12}
            key={index}
            style={{ marginBottom: '10px' }}
          >
            {updatedelement.content}
          </Grid>
        );
      })}
      {title != 'applications' && schema.additionalProperties && (
        <Grid container justifyContent="flex-end" style={{ marginTop: '10px' }}>
          <Grid item={true}>
            <Button
              sx={{ mt: 1 }}
              color="secondary"
              onClick={onAddClick(schema)}
            >
              <AddIcon /> Add {title}
            </Button>
          </Grid>
        </Grid>
      )}
      {title === 'applications' && schema.additionalProperties && (
        <Grid container className="applications-info">
          <Grid item={true}>
            <ComboBox
              val={''}
              label={'Select Application'}
              endpoint={'applications'}
              name={'applications'}
            />

            <Box sx={{ mt: 1, mx: 2 }}>
              <strong>OR</strong>
            </Box>

            <Button
              sx={{ mt: 1 }}
              color="secondary"
              onClick={onAddClick(schema)}
            >
              <AddIcon /> Add {title}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const MyObjectFieldTemplate = (
  props: ObjectFieldTemplateProps<FormConfigs, RJSFSchema, FormContextType>
) => {
  const { description, title, idSchema } = props;
  const { collapse } = useContext(ConfigDetailContext);
  const [expanded, setExpanded] = React.useState(collapse);

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => !oldExpanded);
  };

  useEffect(() => {
    setExpanded(collapse);
  }, [collapse]);

  return (
    <Grid item xs={12}>
      <Box sx={{ px: 2, py: 2 }}>
        {!(idSchema.$id === 'root') && (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              <IconButton onClick={handleExpandClick}>
                {expanded ? (
                  <ExpandMoreIcon htmlColor={colorsTheme.black} />
                ) : (
                  <NavigateNextIcon />
                )}
              </IconButton>
              {title ? title : ''}
            </Typography>
          </Box>
        )}

        {description && (
          <Typography id={`${idSchema.$id}-description`}>
            {description}
          </Typography>
        )}
        {title || !(idSchema.$id === 'root') ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <FormGrid {...props} />
          </Collapse>
        ) : (
          <FormGrid {...props} />
        )}
      </Box>
    </Grid>
  );
};

export default MyObjectFieldTemplate;
