import Typography, { TypographyProps } from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import React, { memo } from 'react';
import { colorsTheme } from '../constants';

const styles = makeStyles({
  banner: {
    width: '70%',
    backgroundColor: 'primary',
    padding: '0 32px',
    height: '50px',
    borderRadius: '0 20px 20px 0',
    display: 'flex',
    alignItems: 'center',
  },
  mt5: {
    marginTop: '1.5rem',
  },
  mt2: {
    marginTop: '0.5rem',
  },
});

type OwnProps = {
  bold?: boolean;
} & TypographyProps;

const Title: React.FC<OwnProps> = ({ children, color, bold, ...props }) => (
  <Typography
    variant="h5"
    color={color || 'text.primary'}
    style={{
      ...(bold && { fontWeight: 'bold' }),
    }}
    {...props}
  >
    {children}
  </Typography>
);

const SubTitle: React.FC<OwnProps> = ({ children, color, bold, ...props }) => (
  <Typography
    variant="h6"
    color={color || 'text.primary'}
    style={{
      ...(bold && { fontWeight: 'bold' }),
    }}
    {...props}
  >
    {children}
  </Typography>
);

const Para: React.FC<OwnProps> = ({ children, color, bold, ...props }) => (
  <Typography
    variant="subtitle1"
    color={color || 'text.primary'}
    style={{
      ...(bold && { fontWeight: 'bold' }),
    }}
    {...props}
  >
    {children}
  </Typography>
);

const Caption: React.FC<OwnProps> = ({ children, bold, color, ...props }) => (
  <Typography
    variant="caption"
    color={color || 'text.primary'}
    style={{
      ...(bold && { fontWeight: 'bold' }),
    }}
    {...props}
  >
    {children}
  </Typography>
);

const BannerTitle: React.FC<OwnProps> = memo(({ children, ...props }) => {
  const classes = styles();
  return (
    <Box className={classes.banner}>
      <Title {...props} color={colorsTheme.white}>
        {children}
      </Title>
    </Box>
  );
});

BannerTitle.displayName = 'BannerTitle';

const Margin = memo(() => {
  const classes = styles();
  return <Box className={classes.mt5} />;
});

const MarginSecondary = memo(() => {
  const classes = styles();
  return <Box className={classes.mt2} />;
});

MarginSecondary.displayName = 'MarginSecondary';

Margin.displayName = 'Margin';

export {
  Title,
  SubTitle,
  Caption,
  BannerTitle,
  Para,
  Margin,
  MarginSecondary,
  styles,
};
