import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { FormConfigs } from '../../../models/workFlowTree/types';

type ResponseEditConfigById = {
  data: FormConfigs;
  success: boolean;
  message?: string;
};
type UseEditConfigByIdValiables = {
  endpoint: string;
  configId: string;
  data: FormConfigs;
};
type UseEditConfigByIdParams = {
  mutationParams?: UseMutationOptions<
    ResponseEditConfigById,
    unknown,
    UseEditConfigByIdValiables
  >;
};

export const useEditConfigById = ({
  mutationParams,
}: UseEditConfigByIdParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['config-config'],
    mutationFn: ({ data, configId, endpoint }: UseEditConfigByIdValiables) => {
      return axios({
        url: `/api/${endpoint}/@${configId}`,
        method: 'PUT',
        data: data,
      })
        .then((response) => {
          if (response.data.success) {
            return response.data as ResponseEditConfigById;
          }
          return Promise.reject(
            response.data.msg ?? 'update config success false'
          );
        })
        .catch((response) => {
          return Promise.reject(response.response.data);
        });
    },
  });
};
