import React from 'react';
import { FieldTemplateProps, FormContextType, RJSFSchema } from '@rjsf/utils';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import WrapIfAdditional from './wrap-if-additional';
import ComboBox from '../../ComboBox';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';

import { FormConfigs } from '../../../models/workFlowTree/types';

const referenceToEndpoint = (reference: string) => {
  if (reference === 'Application') return 'applications';
  if (reference === 'Connection') return 'connections';
  if (reference === 'API') return 'APIs';
  if (reference === 'Transform') return 'transforms';
  if (reference === 'Workflow') return 'workflows';
  if (reference === 'Template') return 'templates';
  if (reference === 'Rule') return 'rules';
  if (reference === 'HL7MessageTemplate') return 'hl7messagetemplate';
  if (reference === 'KMSLocalKeyClient') return 'kmslocalkey';
  if (reference === 'Keycontainer') return 'keymanagement';
  if (reference === 'Secrets') return 'secrets';
  if (reference === 'DataValue') return 'datavalues';
  if (reference === 'StoredProcedure') return 'storedprocedures';
  return '';
};

const InputFieldTemplate = ({
  id,
  children,
  required,
  displayLabel,
  rawErrors = [],
  rawHelp,
  rawDescription,
  classNames,
  disabled,
  formContext,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  schema,
}: FieldTemplateProps<FormConfigs, RJSFSchema, FormContextType>) => {
  const isDropDown =
    (schema?.IsManualReference ?? false) && schema?.type !== 'object';
  const endpointDropDown = referenceToEndpoint(schema?.Reference ?? '');

  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      formContext={formContext}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      <FormControl
        fullWidth={true}
        error={rawErrors.length ? true : false}
        required={required}
        variant="standard"
        size="small"
      >
        {isDropDown && (
          <Grid container className="applications-info">
            <Box sx={{ display: 'flex' }}>
              {isDropDown && (
                <ComboBox
                  label={`Select ${label}`}
                  endpoint={endpointDropDown}
                  val={children.props.children[0].props.formData}
                  required={required}
                  name={label}
                  onChange={children.props.children[0].props.onChange}
                  enableCustomValue
                />
              )}
            </Box>
          </Grid>
        )}
        {!isDropDown && children}
        {displayLabel && rawDescription ? (
          <Typography variant="caption" color="textSecondary">
            {rawDescription}
          </Typography>
        ) : null}
        {rawErrors.length > 0 && (
          <List dense={true} disablePadding={true}>
            {rawErrors.map((error, i) => {
              return (
                <ListItem key={i} disableGutters={true}>
                  {/* <FormHelperText id={id}>{error}</FormHelperText> */}
                </ListItem>
              );
            })}
          </List>
        )}
        {rawHelp && <FormHelperText id={id}>{rawHelp}</FormHelperText>}
      </FormControl>
    </WrapIfAdditional>
  );
};

export default InputFieldTemplate;
