import { ConfigurationType } from '../../../types/Configuration';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { SubTitle, Caption } from '../../../components/Typography';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import {
  ThemedCard,
  ThemedContent,
  ThemedIcon,
} from '../../../components/Card';

const styles = makeStyles({
  description: {
    overflow: 'auto',
  },
});
const styleIcon = {
  height: '50px',
  width: '50px',
};

const ConfigCard: React.FC<ConfigurationType> = memo(({ ...config }) => {
  const classes = styles();

  return (
    <Grid item key={config.endpoint} xs={12} sm={12} lg={4}>
      <Box
        sx={{
          mt: { md: 0, xs: 1 },
          height: { md: '100%', xs: 'auto' },
        }}
      >
        <Box sx={{ p: 2, height: '100%' }}>
          <Link to={`${config.endpoint}`}>
            <ThemedCard>
              <ThemedIcon sx={{ m: 2 }}>{config.icon(styleIcon)}</ThemedIcon>
              <ThemedContent sx={{ ml: 2 }}>
                <SubTitle bold color="text.secondary">
                  {config.title}
                </SubTitle>
                <Caption
                  className={classes.description}
                  bold
                  color="text.disabled"
                >
                  {config.description}
                </Caption>
              </ThemedContent>
            </ThemedCard>
          </Link>
        </Box>
      </Box>
    </Grid>
  );
});

ConfigCard.displayName = 'ConfigCard';

export default ConfigCard;
