import React, { useState, useMemo, useCallback, useLayoutEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { FormConfigs, TypeConfigs } from '../../../models/workFlowTree/types';
import { configFormNormalization } from '../../../models/workFlowTree/helpers';
import { ConfigForm } from '../../../components/form/configForm/ConfigForm';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { useGetConfigById } from '../../../hooks/api/queries';
import { useStorage } from '../../../hooks/useStorage';

const ConfigurationDetails = () => {
  const { pathname } = useLocation();
  const locationParts = useMemo(() => pathname.split('/'), [pathname]);
  const { confirmation } = useConfirmationDialog();

  const navigate = useNavigate();
  const { endpoint, '*': configId } = useParams();
  const { setres, res } = useAuth();
  const actionType = useMemo(
    () => (locationParts.includes('create') ? 'create' : 'edit'),
    [locationParts]
  );
  const [form, setForm] = useState<FormConfigs | null>(null);
  const enabledGetConfigById = !!(
    actionType === 'edit' &&
    endpoint &&
    configId
  );
  const { data: dataConfig, isLoading } = useGetConfigById({
    endpoint,
    configId,
    queryParams: {
      enabled: enabledGetConfigById,
      refetchOnWindowFocus: false,
    },
  });

  const { value: formschema } = useStorage('formschema');
  useLayoutEffect(() => {
    if (enabledGetConfigById && !isLoading && formschema) {
      setForm(configFormNormalization(endpoint, formschema, dataConfig.data));
    }
  }, [enabledGetConfigById, dataConfig, isLoading, formschema]);

  const handelCancel = useCallback(async () => {
    if (res) {
      const confirmationBack = await confirmation();
      if (confirmationBack) {
        setres(false);
        navigate(`/configuration/${endpoint}/`);
      }
    } else {
      setres(false);
      navigate(`/configuration/${endpoint}/`);
    }
  }, [endpoint, res]);
  const handleSuccess = useCallback(async () => {
    setres(false);
    navigate(`/configuration/${endpoint}/`);
  }, [endpoint, res]);

  return (
    <ConfigForm
      formData={form}
      onSuccess={handleSuccess}
      configId={configId}
      onClose={handelCancel}
      actionType={actionType}
      endpoint={endpoint as TypeConfigs}
      copy={false}
    />
  );
};
export default ConfigurationDetails;
