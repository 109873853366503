import { DropOptions, isAncestor, NodeModel } from '@minoru/react-dnd-treeview';
import { useEffect, useState, useContext, useRef } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import {
  generateNodeTreeId,
  generateNumericGUID,
  getParentsFolder,
  getRootParentFolder,
  isConfigType,
  isFolderType,
  serialization,
} from '../helpers';
import {
  currentConfigWorkflowAtom,
  workflowsAtom,
  workflowsTreeAtom,
  workflowsTreeHashAtom,
  workflowToNodeModel,
} from '../state';
import {
  WorkflowFolder,
  WorkflowConfig,
  TypeConfigs,
  WorkItemType,
  Workflow,
  WorkflowItem,
  CreateConfigAction,
} from '../types';
import { useDeleteTree } from './useDeleteTree';
import { AxiosContext } from '../../../hoc/withAxios';
import {
  useCreateFolder,
  useEditFolderById,
} from '../../../hooks/api/mutations';

export function useWorkflows() {
  const [workflows, setWorkflows] = useAtom(workflowsAtom);
  const setCurrentConfigWorkflow = useAtom(currentConfigWorkflowAtom)[1];
  const workflowsTreeHash = useAtom(workflowsTreeHashAtom)[0];
  const [workflowsTree] = useAtom(workflowsTreeAtom);
  const { showToast } = useContext(AxiosContext);
  const refTreeList = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { mutateAsync: mutateEditFolder } = useEditFolderById({
    mutationParams: {
      onError: () => {
        showToast({
          show: true,
          message: 'Error edit folder',
          severity: 'error',
        });
      },
    },
  });
  const { deleteFolder, deleteConfig } = useDeleteTree();
  const { mutate: mutateCreateFolder } = useCreateFolder({
    mutationParams: {
      onError: (error: string) => {
        showToast({
          show: true,
          message: error ?? 'Error create root folder',
          severity: 'error',
        });
      },
    },
  });
  const saveWorkflows = (
    workflowsUpdate: Workflow[],
    workflowsId: Workflow['id'][],
    workflowsSearct?: Workflow[]
  ) => {
    const serverData = serialization(workflowsUpdate);
    const rootFolders = workflowsId.map(
      (workflowId) =>
        getRootParentFolder(workflowsSearct ?? workflowsUpdate, workflowId)
          ?.folderId ?? ''
    );
    serverData.forEach((data) => {
      if (rootFolders.includes(data.folderId)) {
        mutateEditFolder({
          data,
          folderId: data.folderId,
        });
      }
    });
  };
  const updateTreeName = (id: NodeModel['id'], text: NodeModel['text']) => {
    const workflow = workflows.find((workflow) => workflow.id === id) as
      | WorkflowFolder
      | undefined;
    if (workflow) {
      const updateWorkflows = workflows.map((workflow) => {
        if (workflow.id === id && isFolderType(workflow)) {
          return {
            ...workflow,
            name: text,
            edit: false,
            localCreate: false,
          };
        }
        return workflow;
      });

      if (workflow?.localCreate === true && workflow?.level === 0) {
        createRootFolderTree({
          ...workflow,
          name: text,
        });
      } else if (workflow?.localCreate === false && workflow?.level === 0) {
        editRootFolderTree(workflow, updateWorkflows);
      } else {
        saveWorkflows(updateWorkflows, [workflow.id]);
      }

      setWorkflows(updateWorkflows);
    }
  };

  const editConfig = (id: NodeModel['id']) => {
    const workflow = workflows.find(
      (workflow) => workflow.id === id && isConfigType(workflow)
    );
    if (workflow && isConfigType(workflow)) {
      navigate(`/collections/${workflow.itemId}`);
      setCurrentConfigWorkflow(workflow);
    }
  };

  const deleteTree = (
    id: NodeModel['id'],
    type?: WorkItemType,
    softDelete = false
  ) => {
    const workflow = workflows.find((workflow) => workflow.id === id);
    if (workflow) {
      const updateWorkflows = workflows.filter(
        (workflow) => workflow.id !== id && workflow.parentId !== id
      );
      if (
        isFolderType(workflow) &&
        workflow.localCreate === false &&
        workflow?.level === 0
      ) {
        deleteFolder(workflow, () => setWorkflows(updateWorkflows));
      } else if (
        type === WorkItemType.folder ||
        (type === WorkItemType.config && softDelete)
      ) {
        setWorkflows(updateWorkflows);
        saveWorkflows(updateWorkflows, [id as number], workflows);
      } else if (isConfigType(workflow) && !softDelete) {
        deleteConfig(workflow, () => {
          const updateWorkflowsConfig = workflows.filter(
            (workflowItem) =>
              isFolderType(workflowItem) ||
              (isConfigType(workflowItem) &&
                workflowItem.configId !== workflow.configId &&
                workflowItem.typeConfig === workflow.typeConfig)
          );
          setWorkflows(updateWorkflowsConfig);
        });
      }
      setCurrentConfigWorkflow(false);
      navigate('/collections');
    }
  };
  const cloneTree = (id: NodeModel['id']) => {
    const findTree = workflows.find((tree) => tree.id === id);
    const newId = generateNumericGUID();
    if (findTree) {
      if (isFolderType(findTree)) {
        const newWorkflowFolder: WorkflowFolder = {
          ...findTree,
          id: newId,
          name: findTree.name + ' (copy)',
          edit: true,
          localCreate: true,
        };
        setWorkflows([...workflows, newWorkflowFolder]);
        handleSingleSelect(workflowToNodeModel(newWorkflowFolder));
      } else {
        const newWorkflowConfig: WorkflowConfig = {
          ...findTree,
          id: newId,
          edit: false,
          localCreate: true,
          copy: true,
          duplicateId: findTree.configId,
        };
        setCurrentConfigWorkflow(newWorkflowConfig);
        setWorkflows([...workflows, newWorkflowConfig]);
        handleSingleSelect(workflowToNodeModel(newWorkflowConfig));
      }
    }
  };
  const createRootFolderTree = async (newFolder: WorkflowFolder) => {
    mutateCreateFolder({
      data: {
        sort: newFolder.sort,
        folderId: newFolder.folderId,
        displayName: newFolder.name,
        configurations: [],
        folders: [],
      },
    });
  };
  const editRootFolderTree = (
    oldFolder: WorkflowFolder,
    workflowsUpdate: Workflow[]
  ) => {
    const folder = workflowsUpdate.find(
      (x) => x.id === oldFolder.id
    ) as WorkflowFolder;
    const serverData = serialization(workflowsUpdate);
    const findTreeEdit = serverData.find(
      (item) => item.folderId === folder.folderId
    );
    if (findTreeEdit) {
      mutateEditFolder({
        data: findTreeEdit,
        folderId: folder.folderId,
      });
    }
  };

  const createFolderTreeLocal = (id: NodeModel['id'], level = 0) => {
    const parentId = typeof id === 'string' ? parseInt(id) : id;
    const sortsLevel =
      workflows
        .filter(
          (item) =>
            isFolderType(item) &&
            item.parentId === parentId &&
            item.level === level
        )
        .map((item) => item.sort) ?? [];
    const sortMax = Math.max(...sortsLevel, 0);
    const newFolder: WorkflowFolder = {
      id: generateNumericGUID(),
      folderId: generateNumericGUID().toString(),
      parentId: parentId,
      level: level,
      name: 'new folder',
      edit: true,
      open: false,
      sort: sortMax + 1,
      type: WorkItemType.folder,
      localCreate: true,
    };
    setWorkflows((prevs) => [
      ...prevs.map((prev) => {
        if (prev.id === parentId) {
          return {
            ...prev,
            open: true,
          };
        }
        if (prev.parentId === parentId) {
          return {
            ...prev,
            sort: prev.sort + 1,
          };
        }
        return prev;
      }),
      newFolder,
    ]);
    scrollToFolder(newFolder.id);
  };
  const createConfigTree = (
    id: NodeModel['id'],
    configType: TypeConfigs,
    configId: string,
    typeAction: CreateConfigAction
  ) => {
    const parentId = typeof id === 'string' ? parseInt(id) : id;
    makeNewAndSet({
      configType,
      configId,
      name: configId,
      parentId,
      typeAction,
    });
  };

  const makeNewAndSet = ({
    configType,
    configId,
    parentId,
    typeAction,
    name,
  }: {
    configType: TypeConfigs;
    configId: string;
    parentId: number;
    typeAction: CreateConfigAction;
    name: string;
  }) => {
    const sort = workflows.filter(
      (item) => isConfigType(item) && item.parentId === parentId
    ).length;
    const newConfig: WorkflowConfig = {
      id: generateNumericGUID(),
      parentId: parentId,
      edit: false,
      open: false,
      sort: sort + 1,
      type: WorkItemType.config,
      localCreate: true,
      typeConfig: configType,
      configId: configId,
      itemId: generateNumericGUID().toString(),
      name: name,
      copy: false,
    };
    if (typeAction === 'create-template') {
      newConfig.copy = true;
    }
    setCurrentConfigWorkflow(newConfig);
    const updateWorkflows = [
      ...workflows.map((prev) => {
        if (prev.id === parentId) {
          return {
            ...prev,
            open: true,
          };
        }
        return prev;
      }),
      newConfig,
    ];

    setWorkflows(updateWorkflows);
    handleSingleSelect(workflowToNodeModel(newConfig));
  };

  const [selectedNodes, setSelectedNodes] = useState<NodeModel<WorkflowItem>[]>(
    []
  );

  const [isDragging, setIsDragging] = useState(false);
  const [isCtrlPressing, setIsCtrlPressing] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key && e.key.toLowerCase() === 'escape') {
        setSelectedNodes([]);
      } else if (e.ctrlKey || e.metaKey) {
        setIsCtrlPressing(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (
        (e.key && e.key.toLowerCase() === 'control') ||
        (e.key && e.key.toLowerCase() === 'meta')
      ) {
        setIsCtrlPressing(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleSingleSelect = (node: NodeModel<WorkflowItem>) => {
    setSelectedNodes([node]);
  };

  const handleMultiSelect = (clickedNode: NodeModel<WorkflowItem>) => {
    const selectedIds = selectedNodes.map((n) => n.id);

    // ignore if the clicked node is already selected
    if (selectedIds.includes(clickedNode.id)) {
      return;
    }

    // ignore if ancestor node already selected
    if (
      selectedIds.some((selectedId) =>
        isAncestor(workflowsTree, selectedId, clickedNode.id)
      )
    ) {
      return;
    }

    let updateNodes = [...selectedNodes];

    // if descendant nodes already selected, remove them
    updateNodes = updateNodes.filter((selectedNode) => {
      return !isAncestor(workflowsTree, clickedNode.id, selectedNode.id);
    });

    updateNodes = [...updateNodes, clickedNode];
    setSelectedNodes(updateNodes);
  };

  const handleClick = (e: React.MouseEvent, node: NodeModel<WorkflowItem>) => {
    if (e.ctrlKey || e.metaKey) {
      handleMultiSelect(node);
    } else {
      handleSingleSelect(node);
    }
  };

  const handleDragStart = (node: NodeModel<WorkflowItem>) => {
    const isSelectedNode = selectedNodes.some((n) => n.id === node.id);
    setIsDragging(true);

    if (!isCtrlPressing && isSelectedNode) {
      return;
    }

    if (!isCtrlPressing) {
      setSelectedNodes([node]);
      return;
    }

    if (!selectedNodes.some((n) => n.id === node.id)) {
      setSelectedNodes([...selectedNodes, node]);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setIsCtrlPressing(false);
    setSelectedNodes([]);
  };

  const handleDrop = async (
    newTree: NodeModel<WorkflowItem>[],
    options: DropOptions<WorkflowItem>
  ) => {
    const { dragSource, dropTarget, dropTargetId } = options;
    const updatesId = [];
    if (
      dragSource.parent === 0 &&
      !!dropTarget &&
      dragSource.data.type === WorkItemType.folder
    ) {
      deleteFolder({
        edit: false,
        id:
          typeof dragSource.id === 'string'
            ? parseInt(dragSource.id)
            : dragSource.id,
        level: 0,
        name: dragSource.text,
        open: false,
        parentId: 0,
        folderId: dragSource.data.folderId,
        sort: newTree.findIndex((item) => item.id === dragSource.id),
        type: WorkItemType.folder,
        localCreate: false,
      });
    } else if (
      dragSource.parent !== 0 &&
      !dropTarget &&
      dragSource.data.type === WorkItemType.folder
    ) {
      const rootFolderId =
        typeof dragSource.id === 'string'
          ? parseInt(dragSource.id)
          : dragSource.id;
      updatesId.push(rootFolderId);
      await createRootFolderTree({
        edit: false,
        id: rootFolderId,
        level: 0,
        name: dragSource.text,
        open: false,
        parentId: 0,
        folderId: dragSource.data.folderId,
        sort: newTree.findIndex((item) => item.id === dragSource.id),
        type: WorkItemType.folder,
        localCreate: false,
      });
    }

    const updateWorkflows = workflows.map((workflow) => {
      if (
        selectedNodes.some((selectedNode) => selectedNode.id === workflow.id)
      ) {
        const resultWorkflow = {
          ...workflow,
          sort: newTree.findIndex((item) => item.id === workflow.id),
          parentId:
            typeof dropTargetId === 'string'
              ? parseInt(dropTargetId)
              : dropTargetId,
        };
        if (resultWorkflow.type === WorkItemType.folder && !!dropTarget) {
          resultWorkflow.level = dropTarget.data.level + 1;
        }
        return resultWorkflow;
      }
      if (
        selectedNodes.some(
          (selectedNode) => selectedNode.id === workflow.parentId
        )
      ) {
        return {
          ...workflow,
          parentId:
            typeof dragSource.id === 'string'
              ? parseInt(dragSource.id)
              : dragSource.id,
        };
      }
      return {
        ...workflow,
        sort: newTree.findIndex((item) => item.id === workflow.id),
      };
    });
    setWorkflows(updateWorkflows);
    const ids = selectedNodes.map((selectedNode) => selectedNode.id as number);
    if (dropTargetId === 0) {
      updatesId.push(
        ...updateWorkflows
          .filter((item) => item.parentId === 0)
          .map((item) => item.id)
      );
    } else {
      updatesId.push(...ids, dropTargetId as number);
    }
    saveWorkflows(
      updateWorkflows,
      updatesId,
      dropTargetId === 0 ? updateWorkflows : workflows
    );
    setSelectedNodes([]);
  };

  const canDrop = (
    tree: NodeModel<WorkflowItem>[],
    options: DropOptions<WorkflowItem>
  ) => {
    const { dragSource, dragSourceId, dropTargetId } = options;

    if (
      workflowsTreeHash[dragSourceId] &&
      workflowsTreeHash[dragSourceId].includes(`${dropTargetId}`)
    ) {
      return false;
    }

    if (dragSource.parent === 0 && !dropTargetId) {
      return true;
    }

    if (dragSource?.parent === dropTargetId) {
      return true;
    }
  };

  const enabledEdit = (id: string | number) => {
    setWorkflows(
      workflows.map((workflow) => {
        if (workflow.id === id) {
          return {
            ...workflow,
            edit: !workflow.edit,
          };
        }
        return workflow;
      })
    );
  };

  const toggleOpen = (id: string | number) => {
    setWorkflows(
      workflows.map((workflow) => {
        if (workflow.id === id) {
          return {
            ...workflow,
            open: !workflow.open,
          };
        }
        return workflow;
      })
    );
  };
  const initialOpenConfig = (workflowConfig: WorkflowConfig) => {
    const openIds = getParentsFolder(workflows, workflowConfig.id);
    setWorkflows(
      workflows.map((workflow) => {
        if (openIds.includes(workflow.id)) {
          return {
            ...workflow,
            open: true,
          };
        }
        return workflow;
      })
    );
  };

  const scrollToFolder = (id: NodeModel['id']) => {
    const isInViewport = (elem) => {
      const distance = elem.getBoundingClientRect();
      return (
        distance.top >= 0 &&
        distance.left >= 0 &&
        distance.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) - 40 &&
        distance.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };
    requestIdleCallback(() => {
      const treeNode = refTreeList?.current?.querySelectorAll(
        `#${generateNodeTreeId(id)}`
      )[0];
      if (treeNode && !isInViewport(treeNode)) {
        treeNode.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }); /* wait create folder */
  };

  return {
    updateTreeName,
    deleteTree,
    cloneTree,
    createRootFolderTree,
    createFolderTreeLocal,
    createConfigTree,
    enabledEdit,
    toggleOpen,
    initialOpenConfig,
    editConfig,
    saveWorkflows,

    handleDrop,
    handleDragStart,
    handleDragEnd,
    canDrop,
    isDragging,
    handleClick,
    selectedNodes,
    handleSingleSelect,
    refTreeList,
  };
}
