import {
  ConfirmMessage,
  ConfirmationDialog,
} from '../../../components/ConfirmationDialog';
import SearchBox from '../../../components/SearchBox';
import ThemedButton from '../../../components/ThemedButton';
import { Caption } from '../../../components/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Grid, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { colorsTheme } from '../../../constants';

const TopBar = ({ control, errors }) => (
  <Grid container direction="row" justifyContent="flex-end">
    <SearchBox control={control} name="search" errors={errors} />

    <Link to={'create'}>
      <ThemedButton
        sx={{
          display: { xs: 'none', md: 'block' },
        }}
      >
        Create
      </ThemedButton>

      <IconButton sx={{ display: { xs: 'block', md: 'none' } }}>
        <AddIcon sx={{ color: 'primary' }} />
      </IconButton>
    </Link>
  </Grid>
);

const DeleteConfirmation = ({ visible, deleteListItem, i, close }) => {
  return (
    i && (
      <Grid item>
        {
          <ConfirmationDialog
            open={visible}
            title="Are you sure you want to delete this?"
            onAction={(action: ConfirmMessage | string) => {
              if (action === ConfirmMessage.CONFIRM) {
                deleteListItem(Object.entries(i)[0][1]);
              } else {
                close();
              }
            }}
          >
            <Caption color={colorsTheme.black}>
              Completing this action will permanently delete your
            </Caption>

            <Caption color={colorsTheme.red}>{` ${
              Object.entries(i)[0][1]
            } Id. `}</Caption>
            <Caption color={colorsTheme.black}>
              You only want to do this after completing all of the necessary
              precautions because this cannot be undone.
            </Caption>
          </ConfirmationDialog>
        }
      </Grid>
    )
  );
};

export default TopBar;
export { DeleteConfirmation };
