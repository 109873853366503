import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { FormConfigs } from '../../../models/workFlowTree/types';

type ResponseConfigById = {
  data: FormConfigs;
  extra: {
    column: string[];
  };
  success: boolean;
  msg?: string;
};
type UseGetConfigByIdLazyParams = {
  endpoint: string;
  configId: string;
};

export const useGetConfigByIdLazy = () => {
  const { axios } = useContext(AxiosContext);
  const queryClient = useQueryClient();
  return ({ endpoint, configId }: UseGetConfigByIdLazyParams) =>
    queryClient.ensureQueryData<ResponseConfigById>({
      queryKey: ['get-config-by-id', endpoint, configId],
      queryFn: () => {
        return axios({
          url: `/api/${endpoint}/@${configId}`,
          method: 'GET',
        }).then((response) => {
          if (response.data.success) {
            return response.data as ResponseConfigById;
          }
          return Promise.reject(
            response.data.msg ?? 'config load success false'
          );
        });
      },
    });
};
