import { Grid } from '@mui/material';
import Header from '../components/Header';
import { Muisnackbar } from '../hooks/snackbar';
import React, { memo } from 'react';
import styles from './style';
import bgimage from '../assets/images/pixel.jpeg';

const AuthLayout: React.FC<{ children: JSX.Element }> = memo(({ children }) => {
  const classes = styles();

  return (
    <>
      <Header />
      <Grid item>
        <Grid container className={classes.container}>
          {children}
          <Grid
            item
            md={7}
            style={{
              backgroundImage: `url(${bgimage})`,
              backgroundSize: 'cover',
            }}
          ></Grid>
        </Grid>
        <Muisnackbar />
      </Grid>
    </>
  );
});

AuthLayout.displayName = 'AuthLayout';

export default AuthLayout;
