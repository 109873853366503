import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';

type ResponseLogoutOnprem = {
  success: boolean;
  message?: string;
};
type UseLogoutOnpremParams = {
  mutationParams?: UseMutationOptions<ResponseLogoutOnprem>;
};

export const useLogoutOnprem = ({
  mutationParams,
}: UseLogoutOnpremParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['logout-onprem'],
    mutationFn: () => {
      return axios({
        withCredentials: true,
        url: 'api/auth-onprem/logout',
        method: 'POST',
      }).then((response) => {
        if (response.status === 200) {
          return response.data as ResponseLogoutOnprem;
        }
        return Promise.reject(response.data.msg ?? 'logout success false');
      });
    },
  });
};
