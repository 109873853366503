import React, { MouseEvent, useState } from 'react';
import { NodeModel, useDragOver } from '@minoru/react-dnd-treeview';
import { Box, IconButton, Stack } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { TreeName } from '../treeName/TreeName';
import { TreeMenu } from '../treeMenu/TreeMenu';
import { TreeIconOpen } from '../treeIconOpen/TreeIconOpen';
import { TreeIconType } from '../treeIconType/TreeIconType';
import { CreateConfigAction, WorkflowItem, WorkItemType } from '../../types';
import { colorsTheme } from '../../../../constants';
import { generateNodeTreeId } from '../../helpers';

type TreeItemProps = {
  node: NodeModel<WorkflowItem>;
  depth: number;
  isOpen: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onEnabledEdit: (id: NodeModel['id']) => void;
  onEdit: (id: NodeModel['id'], text: NodeModel['text']) => void;
  hasChild: boolean;
  onDelete: (
    id: NodeModel['id'],
    type?: WorkItemType,
    softDelete?: boolean
  ) => void;
  onClone: (id: NodeModel['id']) => void;
  onCreateConfig: (
    id: NodeModel['id'],
    configType: string,
    configId: string,
    typeAction: CreateConfigAction
  ) => void;
  onCreateFolder: (id: NodeModel['id'], level: number) => void;
  isSelected: boolean;
  isDragging: boolean;
  onClick: (e: React.MouseEvent, node: NodeModel<WorkflowItem>) => void;
  testIdPrefix?: string;
};

export const TreeItem = ({
  node,
  hasChild,
  isOpen,
  onEnabledEdit,
  onEdit,
  onDelete,
  onClone,
  onCreateConfig,
  onCreateFolder,
  onToggle,
  isSelected,
  onClick,
  depth,
}: TreeItemProps) => {
  const { id, data } = node;
  const enabledEdit = data.edit;
  const indent = depth * 1.5;

  const handleToggle = (e: MouseEvent) => {
    if (node?.data?.type === WorkItemType.config) {
      onClick(e, node);
    } else {
      onToggle(id);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const elementId = `tree-menu-${id}`;

  const handleEdit = () => {
    onEnabledEdit(id);
  };

  const handleDelete = () => {
    onDelete(id, data?.type, false);
  };
  const handleRemove = () => {
    onDelete(id, data?.type, true);
  };
  const handleClone = () => {
    onClone(id);
  };
  const handleCreateFolder = () => {
    onCreateFolder(id, (node?.data?.level ?? 0) + 1);
  };
  const handleCreateConfig = (
    configType: string,
    configId: string,
    typeAction: CreateConfigAction
  ) => {
    onCreateConfig(id, configType, configId, typeAction);
  };
  const dragOverProps = useDragOver(id, isOpen, onToggle);

  const handleClick = (e: React.MouseEvent) => {
    if (node?.data?.type === WorkItemType.config) {
      onClick(e, node);
    }
  };

  return (
    <div className={'tree-node'} id={generateNodeTreeId(id)} {...dragOverProps}>
      <Box
        sx={{
          backgroundColor: isSelected ? colorsTheme.beauBlue : 'transparent',
        }}
      >
        <Stack
          sx={{ ml: indent }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={0}
          >
            <TreeIconOpen
              hasChild={hasChild && node?.data?.type === WorkItemType.folder}
              isOpen={isOpen}
              onOpen={handleToggle}
            />
            <div onClick={handleClick}>
              <TreeIconType
                sx={{ mt: '5px' }}
                type={node.data?.type}
                typeConfig={node.data?.typeConfig}
                onOpen={handleToggle}
              />
            </div>
            <div onClick={handleClick}>
              <TreeName
                node={node}
                onDelete={handleDelete}
                enabledEdit={enabledEdit}
                onOpen={handleToggle}
                onEnabledEdit={onEnabledEdit}
                onEdit={onEdit}
              />
            </div>
            {node.data.type === WorkItemType.config &&
              node.data.localCreate === true && (
                <SaveOutlinedIcon
                  onClick={handleClick}
                  htmlColor={colorsTheme.red}
                />
              )}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <IconButton
              id={elementId}
              onClick={handleOpenMenu}
              aria-controls={openMenu ? elementId : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
            >
              <MoreHorizIcon />
            </IconButton>
            <TreeMenu
              node={node}
              id={elementId}
              open={openMenu}
              enabledEdit={enabledEdit}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onRemove={handleRemove}
              onClone={handleClone}
              onCreateConfig={handleCreateConfig}
              onCreateFolder={handleCreateFolder}
              hideEdit={enabledEdit}
              hideCreateFolder={data?.type === WorkItemType.config}
              hideCreateConfig={data?.type === WorkItemType.config}
            />
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};
