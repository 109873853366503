import { CSSProperties, ReactNode } from 'react';
import { ConfigurationType } from '../../../types/Configuration';
import {
  ApisConfigIcon,
  ApplicationsConfigIcon,
  ConnectionsConfigIcon,
  Hl7messagetemplateConfigIcon,
  KeymanagementConfigIcon,
  KmslocalkeyConfigIcon,
  RulesConfigIcon,
  SecretsConfigIcon,
  TemplatesConfigIcon,
  TransformsConfigIcon,
  WorkflowsConfigIcon,
  DataOperationConfigIcon,
  StoredProceduresConfigIcon,
  GenericConfigurationConfigIcon,
  DefaultConfigIcon,
} from '../../../components/Icons';

const TableIcons: { [key: string]: (style: CSSProperties) => ReactNode } = {
  applications: (style) => <ApplicationsConfigIcon style={style} />,
  connections: (style) => <ConnectionsConfigIcon style={style} />,
  APIs: (style) => <ApisConfigIcon style={style} />,
  transforms: (style) => <TransformsConfigIcon style={style} />,
  workflows: (style) => <WorkflowsConfigIcon style={style} />,
  templates: (style) => <TemplatesConfigIcon style={style} />,
  rules: (style) => <RulesConfigIcon style={style} />,
  hl7messagetemplate: (style) => <Hl7messagetemplateConfigIcon style={style} />,
  kmslocalkey: (style) => <KmslocalkeyConfigIcon style={style} />,
  keymanagement: (style) => <KeymanagementConfigIcon style={style} />,
  secrets: (style) => <SecretsConfigIcon style={style} />,
  datavalues: (style) => <DataOperationConfigIcon style={style} />,
  storedprocedures: (style) => <StoredProceduresConfigIcon style={style} />,
  genericconfigurations: (style) => (
    <GenericConfigurationConfigIcon style={style} />
  ),
  default: (style) => <DefaultConfigIcon style={style} />,
};

export function getTableInfo(schema: object): ConfigurationType[] {
  if (schema == null || Object.keys(schema).length === 0) {
    return [];
  }

  const tables: ConfigurationType[] = [];
  for (const config in schema['components']['schemas']) {
    if ('Table' in schema['components']['schemas'][config]) {
      const info = schema['components']['schemas'][config]['Table'];
      const tableInfo: ConfigurationType = {
        title: info['Title'] ?? 'Missing!',
        endpoint: info['Endpoint'] ?? 'Missing!',
        description: info['Description'] ?? 'Missing!',
        schema: config,
        column: info['ColumnId'] ?? [],
        order: info['Order'] ?? 0,
        icon: TableIcons[info['Endpoint']] ?? TableIcons['default'],
      };
      tables.push(tableInfo);
    }
  }

  tables.sort((a, b) => a.order - b.order);

  return tables;
}
