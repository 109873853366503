export function getPrimaryKeyFieldsFunc(ConfigSchema: unknown): string {
  if (ConfigSchema) {
    for (const [key, value] of Object.entries(ConfigSchema)) {
      if (
        value &&
        Object.keys(value).includes('x-primary-key') &&
        value['x-primary-key']
      ) {
        return key;
      }
    }
  }
  return '';
}
