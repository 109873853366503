export const messageContactAdmin = 'Please contact admin.';
export const messageUserCreated = 'Successfully created user.';
export const messageIncorrectCode = 'Entered verification code is incorrect.';
export const messageAccountVerified = 'Successfully verified account!.';
export const messageAccountNotVerified = 'Could not verify account.';
export const messageLoginSuccessful = 'You are successfully logged in.';
export const messagePasswordChanged =
  'You have successfully changed your password.';
export const messageInvalidCode =
  'Invalid verification code provided. Please try again.';
export const messageLimitExceeded =
  'Attempt limit exceeded, please try after some time.';
export const messageIncorrectPassword =
  'You have entered an incorrect current password.';
export const messageLogoutSuccessful = 'You have successfully logged out.';
export const messageLoginError = 'Login error.';
export const messageRequiredFields = 'All required fields must be completed.';

export const messageUpdateSuccessful = 'Item updated successfully.';
export const messageReadonlyIdfield = 'You are not allowed to edit or use the ';

export const messageCreateSuccessful = 'Item added successfully.';
export const messageDeleteSuccessful =
  'You have successfully deleted the record.';

export const messageLoadingError = 'Error load config.';
export const messageSomethingWentWrong =
  'Oops, something went wrong. Please try again.';
export const messageInsertError = 'Error inserting record.';
export const messageEditError = 'Error editing record.';
export const messageAxiosError = 'Error record';
export const messageNoChanges =
  'No changes found to update. Please make changes before saving.';
export const messageUniqueName = 'Name must be unique.';
export const messageIncorrectEmailPassword = 'Incorrect email or password.';
export const messageServerError = 'Server error.';

export const messageTokenExpired = 'Token is expired.';
export const messageTokenInvalid = 'Token is invalid.';
export const messageLoginAgain =
  'Logout successful. Please log in again to continue.';
// Add more Messages as needed

export const colorsTheme = {
  black: '#000000',
  policeBlue: '#2e546f',
  tiffanyBlue: '#04b6c3',
  yankeesBlue: '#173041',
  harlequinGreen: '#33cc00',
  carnelian: '#b71c1c',
  graniteGray: '#616161',
  spanishGray: '#999999',
  auroMetalSaurus: '#6c757d',
  red: '#ff0000',
  bleuDeFrance: '#3883fa',
  antiFlashWhite: '#edf5f6',
  white: '#ffffff',
  beauBlue: '#c4dbf1',
  grey: '#808080',
};
