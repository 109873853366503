import * as React from 'react';
import * as yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import { useAuth } from '../../../hooks/useAuth';
import useCustomForm from '..';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import { LockIcon } from '../../Icons';
import ControlledTextField from '../widgets/ControlledTextField';
import { Stack } from '@mui/material';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'old password must be atleast 8 characters.'),

  /* eslint-disable  no-useless-escape */
  newpassword: yup
    .string()
    .required('Please enter your password.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must be at least 8 characters and it should contains Upper case, Lower case, Number and Special character'
    ),

  newpasswordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref('newpassword'), null],
      'Please make sure your passwords match'
    ),
});

const defaultValues = {
  password: '',
  newpassword: '',
  newpasswordConfirmation: '',
};

export type ChangePasswordForm = {
  password: string;
  newpassword: string;
  newpasswordConfirmation: string;
};

export type ChangePasswordProps = {
  open: boolean;
  onClose: () => void;
};

export const ChangePassword = ({ open, onClose }: ChangePasswordProps) => {
  const { headerChangePassword } = useAuth();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useCustomForm(defaultValues, schema);

  const changepassword = (data: ChangePasswordForm) => {
    headerChangePassword(data)
      .then(() => {
        onClose();
        reset();
      })
      .catch((error) => console.error(error));
  };

  return (
    <ConfirmationDialog
      open={open}
      onAction={() => onClose()}
      title="Change Password?"
      icon={<LockIcon style={{ width: '100px', height: '100px' }} />}
      actions={[
        {
          label: 'Change Password',
          onClick: handleSubmit(changepassword),
        },
      ]}
    >
      <Box sx={{ mt: 1 }}>
        <form>
          <Stack spacing={1.5}>
            <ControlledTextField
              type="password"
              required
              fullWidth
              label="Current Password"
              name="password"
              control={control}
              errors={errors}
              curvedBottom
              ActiveIcon={Visibility}
              InActiveIcon={VisibilityOff}
            />
            <ControlledTextField
              type="password"
              required
              fullWidth
              label="New Password"
              name="newpassword"
              control={control}
              errors={errors}
              curvedBottom
              ActiveIcon={Visibility}
              InActiveIcon={VisibilityOff}
            />
            <ControlledTextField
              type="password"
              required
              fullWidth
              label="Confirm New Password"
              name="newpasswordConfirmation"
              control={control}
              errors={errors}
              curvedBottom
              ActiveIcon={Visibility}
              InActiveIcon={VisibilityOff}
            />
          </Stack>
        </form>
      </Box>
    </ConfirmationDialog>
  );
};
