import useCustomForm from '../components/form';
import ControlledTextField from '../components/form/widgets/ControlledTextField';
import { confirmPasswordSchema } from './schema';
import React, { memo, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Margin } from '../components/Typography';

type OwnProps = {
  onChange: (value: Record<string, unknown>, isValid) => void;
};

const ConfirmPasswordForm: React.FC<OwnProps> = memo(({ onChange }) => {
  const {
    formState: { errors: confirmPasswordErrors, isValid },
    control: confirmPasswordControl,
    getValues,
  } = useCustomForm(
    {
      resetcode: '',
      password: '',
      confirmpassword: '',
    },
    confirmPasswordSchema
  );

  useEffect(() => {
    onChange(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    onChange(getValues(), false);
  }, []);

  return (
    <>
      <Margin />

      <ControlledTextField
        type="text"
        required
        fullWidth
        name="resetcode"
        label="Password Reset Code"
        control={confirmPasswordControl}
        errors={confirmPasswordErrors}
        curvedBottom
      />

      <Margin />

      <ControlledTextField
        type={'password'}
        fullWidth
        name="password"
        label="Enter New Password"
        control={confirmPasswordControl}
        errors={confirmPasswordErrors}
        curvedBottom
        ActiveIcon={Visibility}
        InActiveIcon={VisibilityOff}
      />

      <Margin />

      <ControlledTextField
        type={'password'}
        fullWidth
        name="confirmPassword"
        label="Confirm New Password"
        control={confirmPasswordControl}
        errors={confirmPasswordErrors}
        curvedBottom
        ActiveIcon={Visibility}
        InActiveIcon={VisibilityOff}
      />
    </>
  );
});

ConfirmPasswordForm.displayName = 'ConfirmPasswordForm';

export default ConfirmPasswordForm;
