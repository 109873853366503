import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const TemplatesConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    fill: colorsTheme.policeBlue,
  };
  return (
    <svg
      style={style}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 55.6 48.1"
    >
      <g>
        <path
          style={styleCls}
          d="M6.7,3.6c0,13.7,0,27.3,0,41c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2,0,0.3,0c10.4,0,20.9,0,31.3,0
		c0.4,0,0.7-0.1,0.9-0.4c3-3,6-6,9.1-9.1c0.2-0.2,0.3-0.4,0.3-0.7c0-10.5,0-21.1,0-31.6c0-0.4,0-0.5-0.5-0.5c-6.9,0-13.7,0-20.6,0
		c-6.8,0-13.6,0-20.4,0c-0.1,0-0.3,0-0.4,0C6.8,2.9,6.7,3,6.7,3.2C6.7,3.3,6.7,3.4,6.7,3.6z M9.2,24.1c0-6,0-12.1,0-18.1
		c0-0.5,0-0.5,0.5-0.5c12.1,0,24.1,0,36.2,0c0.4,0,0.5,0,0.5,0.5c0,9.1,0,18.2,0,27.3c0,0.4,0,0.5-0.5,0.5c-2.6,0-5.3,0-7.9,0
		c-0.5,0-0.5,0-0.5,0.5c0,2.6,0,5.2,0,7.8c0,0.6,0,0.6-0.6,0.6c-9,0-18.1,0-27.1,0c-0.6,0-0.6,0-0.6-0.6C9.2,36,9.2,30,9.2,24.1z"
        />
        <path
          style={styleCls}
          d="M12.9,25.6c1.6,0,3.2,0,4.8,0c0.3,0,0.4-0.1,0.4-0.4c0-0.8,0-1.5,0-2.3c0-0.5,0-0.5-0.5-0.5
		c-1.6,0-3.2,0-4.8,0c-0.5,0-0.5,0-0.5-0.5c0-4.3,0-8.5,0-12.8c0-0.5,0.1-0.6,0.6-0.6c4.3,0,8.5,0,12.8,0c0.5,0,0.5,0,0.5,0.5
		c0,1.6,0,3.2,0,4.8c0,0.3,0.1,0.4,0.4,0.4c0.8,0,1.6,0,2.4,0c0.3,0,0.4-0.1,0.4-0.4c0-1.6,0-3.2,0-4.8c0-0.5,0-0.5,0.5-0.5
		c4.2,0,8.5,0,12.7,0c0.6,0,0.6,0,0.6,0.6c0,4.2,0,8.5,0,12.7c0,0.5,0,0.5-0.5,0.5c-4.3,0-8.6,0-12.9,0c-0.4,0-0.4,0-0.4-0.5
		c0-1.6,0-3.2,0-4.8c0-0.3-0.1-0.4-0.4-0.4c-0.8,0-1.6,0-2.4,0c-0.3,0-0.4,0.1-0.4,0.4c0,1.6,0,3.2,0,4.8c0,0.4,0,0.4-0.4,0.4
		c-1.6,0-3.1,0-4.7,0c-0.5,0-0.5,0-0.5,0.5c0,0.7,0,1.5,0,2.2c0,0.5,0,0.5,0.5,0.5c1.6,0,3.1,0,4.7,0c0.5,0,0.5,0,0.5,0.5
		c0,2.2,0,4.3,0,6.5s0,4.3,0,6.5c0,0.5,0,0.5-0.5,0.5c-4.3,0-8.5,0-12.8,0c-0.6,0-0.6,0-0.6-0.6c0-4.2,0-8.5,0-12.7
		C12.3,25.6,12.4,25.6,12.9,25.6z"
        />
      </g>
    </svg>
  );
};
