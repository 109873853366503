import './style.scss';
import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { AuthenticatedRouteContext } from '../../Navigation';
import {
  ThemedCard,
  ThemedIcon,
  ThemedContent,
  ThemedStatus,
} from '../../components/Card';
import { Title } from '../../components/Typography';
import { MainIcon } from '../../components/Icons';

const Home = () => {
  const { status } = useContext(AuthenticatedRouteContext);

  return (
    <Box sx={{ display: 'flex' }}>
      <Grid container item xs={12} sm={12} md={8} lg={6}>
        <Box sx={{ p: 2 }}>
          <ThemedCard style={{ flexDirection: 'row' }}>
            <ThemedIcon>
              <MainIcon style={{ width: '80px', height: '80px' }} />
            </ThemedIcon>

            <ThemedContent
              sx={{
                ml: { md: 6, xs: 3 },
                mr: 'auto',
                mt: { xs: 0, md: 0, lg: 0 },
              }}
            >
              <Title variant="h5" bold={true}>
                HDC Application
              </Title>
              <Title variant="h5" bold={true}>
                Status
              </Title>
            </ThemedContent>

            <ThemedStatus status={status}></ThemedStatus>
          </ThemedCard>
        </Box>
      </Grid>
    </Box>
  );
};
export default Home;
