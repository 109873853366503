import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { SignUpOnPremForm } from '../../../types/SignUpOnPrem';

type ResponseRegisterOnprem = {
  data: {
    name: string;
    email: string;
  };
  success: boolean;
  message?: string;
};
type UseRegisterOnpremValiables = {
  data: SignUpOnPremForm;
};
type UseRegisterOnpremParams = {
  mutationParams?: UseMutationOptions<
    ResponseRegisterOnprem,
    unknown,
    UseRegisterOnpremValiables
  >;
};

export const useRegisterOnprem = ({
  mutationParams,
}: UseRegisterOnpremParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['register-onprem'],
    mutationFn: ({ data }: UseRegisterOnpremValiables) => {
      return axios({
        withCredentials: true,
        url: 'api/auth-onprem/register',
        method: 'POST',
        data: data,
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseRegisterOnprem;
        }
        return Promise.reject(response.data.msg ?? 'register success false');
      });
    },
  });
};
