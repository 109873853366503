import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { ServerDataFolder } from '../../../models/workFlowTree/types';

type ResponseFolders = {
  data: ServerDataFolder[];
  extra: {
    column: string[];
    schema: string;
  };
  success: boolean;
  msg?: string;
};
type UseGetFoldersParams = {
  queryParams?: UseQueryOptions<ResponseFolders>;
};

export const useGetFolders = ({ queryParams }: UseGetFoldersParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useQuery<ResponseFolders>({
    ...queryParams,
    queryKey: ['get-folders'],
    queryFn: () => {
      return axios({
        url: '/api/folders',
        method: 'GET',
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseFolders;
        }
        return Promise.reject(
          response.data.msg ?? 'folders load success false'
        );
      });
    },
  });
};
