import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  ADDITIONAL_PROPERTY_FLAG,
  FieldTemplateProps,
  FormContextType,
  RJSFSchema,
} from '@rjsf/utils';

import { FormConfigs } from '../../../models/workFlowTree/types';

import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
type WrapIfAdditionalProps = Pick<
  FieldTemplateProps<FormConfigs, RJSFSchema, FormContextType>,
  | 'classNames'
  | 'disabled'
  | 'formContext'
  | 'id'
  | 'label'
  | 'onDropPropertyClick'
  | 'onKeyChange'
  | 'readonly'
  | 'required'
  | 'schema'
  | 'children'
>;

const WrapIfAdditional = ({
  children,
  classNames,
  disabled,
  id,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  schema,
}: WrapIfAdditionalProps) => {
  const keyLabel = `${label} Key`; // i18n ?

  const additional = Object.prototype.hasOwnProperty.call(
    schema,
    ADDITIONAL_PROPERTY_FLAG
  );

  if (!additional) {
    return <div className={classNames}>{children}</div>;
  }

  const handleBlur = (e) => onKeyChange(e.currentTarget.value);

  return (
    <div className={classNames}>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            id={`${id}-key`}
            name={`${id}-key`}
            disabled={disabled || readonly}
            label={keyLabel}
            required={required}
            defaultValue={label}
            inputProps={{ onBlur: handleBlur }}
          />
        </Grid>
        <Grid item xs={6}>
          {children}
        </Grid>
        <Grid item xs>
          <Button
            className="button-danger"
            disabled={disabled || readonly}
            size="small"
            onClick={onDropPropertyClick(label)}
          >
            <ClearIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default WrapIfAdditional;
