import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const KmslocalkeyConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    fill: colorsTheme.policeBlue,
    stroke: colorsTheme.policeBlue,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  };
  return (
    <svg
      id="Layer_1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
    >
      <title>KEY</title>
      <path
        style={styleCls}
        d="M23.38,25.045a6.873,6.873,0,1,0,9.72,0A6.882,6.882,0,0,0,23.38,25.045Zm7.245,7.245a3.373,3.373,0,1,1,.988-2.386A3.379,3.379,0,0,1,30.625,32.29Z"
      />
      <path
        style={styleCls}
        d="M114.524,91.192l-10.66-10.66a1.75,1.75,0,0,0-2.474,0l-6.4,6.4-2.166-2.166L95.4,82.186a1.749,1.749,0,0,0,0-2.475l-8.048-8.047a1.748,1.748,0,0,0-2.474,0L82.3,74.246,63.207,55.152a16.75,16.75,0,0,1-3.959-17.923c2.9-7.381,2.349-13.83-1.512-17.69C53.6,15.4,46.5,15.1,38.581,18.659A15.278,15.278,0,0,0,16.994,40.246c-3.563,7.918-3.259,15.017.88,19.155,3.861,3.862,10.31,4.412,17.689,1.512a16.755,16.755,0,0,1,17.924,3.959l46.375,46.375a7.229,7.229,0,0,0,5.1,2.114,6.688,6.688,0,0,0,4.278-1.507,6.874,6.874,0,0,0,.5-10.17l-1.615-1.616,6.4-6.4A1.749,1.749,0,0,0,114.524,91.192Zm-28.4-15.816,5.573,5.572-1.344,1.345L84.775,76.72Zm20.895,33.779a3.523,3.523,0,0,1-4.677-.383L55.962,62.4a20.586,20.586,0,0,0-14.524-6.092,19.539,19.539,0,0,0-7.154,1.35c-5.936,2.334-11.145,2.06-13.935-.728-3.181-3.181-3.054-9.423.33-16.29a1.751,1.751,0,0,0-.381-2.058l-.208-.185c-.06-.053-.12-.1-.178-.162A11.777,11.777,0,0,1,36.568,21.577c.058.058.11.12.163.181l.178.2a1.75,1.75,0,0,0,2.063.385c6.869-3.384,13.109-3.511,16.29-.33,2.788,2.789,3.06,8,.728,13.935a20.252,20.252,0,0,0,4.742,21.678L81.058,77.953l0,0,.006,0,26.2,26.2a3.376,3.376,0,0,1-.25,5Zm-1.366-11.561-8.185-8.186,5.164-5.164,8.185,8.186Z"
      />
    </svg>
  );
};
