import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmationDialogProvider } from './context/ConfirmationDialog';
import CssBaseline from '@mui/material/CssBaseline';
import Navigation from './Navigation';
import { colorsTheme } from './constants';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: colorsTheme.tiffanyBlue,
        main: colorsTheme.tiffanyBlue,
        dark: colorsTheme.tiffanyBlue,
      },
      secondary: {
        light: colorsTheme.yankeesBlue,
        main: colorsTheme.yankeesBlue,
        dark: colorsTheme.yankeesBlue,
      },
      error: {
        light: colorsTheme.red,
        main: colorsTheme.red,
        dark: colorsTheme.red,
      },
      text: {
        primary: colorsTheme.policeBlue,
        secondary: colorsTheme.policeBlue,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmationDialogProvider>
        <Navigation />
      </ConfirmationDialogProvider>
    </ThemeProvider>
  );
};

export default App;
