import Box from '@mui/material/Box';
import { Grid, CircularProgress } from '@mui/material';

const Loader = () => (
  <Box
    sx={{
      position: 'fixed',
      height: '100vh',
      width: '100vw',
      top: 0,
      left: 0,
      backgroundColor: 'text.primary',
      opacity: 0.5,
      zIndex: '99',
    }}
  >
    <Grid>
      <CircularProgress
        sx={{
          color: 'primary',
          position: 'absolute',
          left: { md: '50%', xs: '40%' },
          top: { md: '50%', xs: '40%' },
        }}
        size="4rem"
      />
    </Grid>
  </Box>
);

export default Loader;
