import React, { useState } from 'react';
import { ListItem, ListItemButton, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { TreeIconType } from '../treeIconType/TreeIconType';
import { TemplateConfigurate } from '../templateConfigurate/TemplateConfigurate';
import { CreateConfigAction } from '../../types';

type ConfigItemProps = {
  onCreateConfig: (
    configType: string,
    configId: string,
    typeAction: CreateConfigAction
  ) => void;
  option: { name: string; value: string };
};

export const ConfigItem = ({ onCreateConfig, option }: ConfigItemProps) => {
  const [openMoreConfigTemplate, setOpenMoreConfigTemplate] = useState(false);
  const toggleOpenMoreConfigTemplate = () => {
    setOpenMoreConfigTemplate((prev) => !prev);
  };

  const handleCloneConfig = (
    configId: string,
    typeAction: CreateConfigAction
  ) => {
    onCreateConfig(option.value, configId, typeAction);
    toggleOpenMoreConfigTemplate();
  };

  return (
    <ListItem
      sx={{
        p: 0,
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <ListItemButton onClick={toggleOpenMoreConfigTemplate}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            sx={{ width: '100%' }}
          >
            {openMoreConfigTemplate ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            <Stack direction="row" justifyContent="start">
              <TreeIconType
                sx={{ px: 1 }}
                type="config"
                typeConfig={option.value}
              />
              {option.name}
            </Stack>
          </Stack>
        </ListItemButton>
        {openMoreConfigTemplate && (
          <TemplateConfigurate
            onChange={handleCloneConfig}
            configType={option.value}
          />
        )}
      </Stack>
    </ListItem>
  );
};
