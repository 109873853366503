const initial = {
  application: {
    applicationId: '',
    name: '',
    appType: '',
    clientId: '',
    appInfo: {
      launchParameters: {},
      privateKeyName: '',
      authSuccessWorkflowId: '',
      authFailureWorkflowId: '',
    },
  },
  connection: {
    connectionId: '',
    baseUrl: '',
    env: '',
    audience: '',
    openIdConfigurationEndpoint: '',
    tokenEndPoint: '',
    authorizeEndPoint: '',
    conformanceStatementPath: '',
    applications: {},
    oAuthHeaders: {},
  },
  api: {
    apiId: '',
    name: '',
    headerParameters: {},
    apiPath: '',
    requestMethod: '',
    bodyTemplateId: '',
  },
  workflowStep: {
    workflowStepId: '',
    name: '',
  },
  datatransform: {
    transformId: '',
    propertyGroups: [
      {
        properties: {},
        key: '',
        locator: '',
      },
    ],
  },
  workflow: {
    workflowId: '',
    description: '',
  },
  template: {
    templateId: '',
    templateBody: '',
  },
  rule: {
    ruleId: '',
    operator: '',
    compareValue: '',
    compareType: '',
  },
  hl7messagetemplate: {
    templateId: '',
    properties: [],
  },
  kmslocalkey: {
    keyId: '',
    publicKey: '',
    status: '',
    description: '',
    creationDate: '',
    keyType: '',
    keyUsage: '',
    keySpec: '',
    signingAlgorithms: [],
  },
  keymanagement: {
    id: '',
    managementKeyID: '',
  },
  secrets: {
    key: '',
    value: '',
  },
  datavalues: {
    dataValuesId: '',
    values: [],
  },
  storedprocedures: {
    storedProcedureId: '',
    name: '',
    connectionStringSecret: '',
    isQuery: false,
    parameters: [],
  },
  genericconfigurations: {
    endpointId: '',
    priority: 0,
    connectionId: '',
    workflowId: '',
    applicationId: '',
  },
};

export default initial;
