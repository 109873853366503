import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const useCustomForm = (
  defaultValues?: Record<string, unknown>,
  schema?: unknown
) => {
  const form = useForm<Record<string, unknown>>({
    defaultValues,
    mode: 'onChange',
    ...(schema && { resolver: yupResolver(schema) }),
  });
  return form;
};

export default useCustomForm;
