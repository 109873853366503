import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { ErrorListProps, FormContextType, RJSFSchema } from '@rjsf/utils';

import { FormConfigs } from '../../../models/workFlowTree/types';
import { colorsTheme } from '../../../constants';

const ErrorListTemplate = ({
  errors,
}: ErrorListProps<FormConfigs, RJSFSchema, FormContextType>) => {
  const [clicked, setClicked] = useState(false);
  const count = [];
  errors.map((x) => {
    count.push(x.message);
  });

  return (
    <AppBar
      sx={{ position: 'sticky', top: '0px', borderRadius: 5 }}
      style={
        clicked
          ? {
              backgroundColor: colorsTheme.antiFlashWhite,
              overflowY: 'auto',
              maxHeight: '100px',
            }
          : { backgroundColor: colorsTheme.antiFlashWhite }
      }
      onClick={() => setClicked(!clicked)}
    >
      <Box sx={{ justifyContent: 'center', color: 'red', zIndex: 99, p: 2 }}>
        <strong>Validation Errors:</strong>

        {errors.map((error, i) => (
          <p key={error.stack + i}>
            <strong style={{ textAlign: 'center' }}>{error.stack}</strong>
          </p>
        ))}
      </Box>
    </AppBar>
  );
};

export default ErrorListTemplate;
