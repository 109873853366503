import Box from '@mui/material/Box';
import React, { memo } from 'react';
import { Card, CardProps } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { HDCStatusType } from '../Navigation';
import { colorsTheme } from '../constants';

const cardStyles = {
  borderRadius: 4,
  boxShadow: 10,
  height: '100%',
};

const boxStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: { md: 'row', xs: 'column' },
};

const statusBoxStyles = {
  borderRadius: '100%',
  width: { md: '35px', xs: '20px' },
  height: { md: '35px', xs: '20px' },
};

type OwnProps = {
  status?: string;
} & CardProps;

const ThemedIcon: React.FC<OwnProps> = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>;
};
ThemedIcon.displayName = 'ThemedIcon';

const ThemedContent: React.FC<OwnProps> = memo(({ children, ...props }) => {
  return <Box {...props}>{children}</Box>;
});

ThemedContent.displayName = 'ThemedContent';

const ThemedStatus: React.FC<OwnProps> = memo(({ status }) => {
  return (
    <Box sx={{ mr: '0', mt: { md: -4, xs: -2 }, ml: 6 }}>
      <div>
        {status === HDCStatusType.SUCCESS && (
          <Box
            sx={statusBoxStyles}
            style={{ backgroundColor: colorsTheme.harlequinGreen }}
          >
            <p></p>
          </Box>
        )}
        {status === HDCStatusType.FAILED && (
          <Box
            sx={statusBoxStyles}
            style={{ backgroundColor: colorsTheme.carnelian }}
          >
            <p></p>
          </Box>
        )}

        {status === HDCStatusType.PENDING && (
          <Box
            sx={statusBoxStyles}
            style={{ backgroundColor: colorsTheme.graniteGray }}
          >
            <p></p>
          </Box>
        )}
      </div>
    </Box>
  );
});

ThemedStatus.displayName = 'ThemedStatus';

const ThemedCard: React.FC<OwnProps> = ({ children, ...props }) => {
  return (
    <Card sx={cardStyles} {...props}>
      <CardContent>
        <Box sx={boxStyles} {...props}>
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};
ThemedCard.displayName = 'ThemedCard';
export { ThemedCard, ThemedIcon, ThemedContent, ThemedStatus };
