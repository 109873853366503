import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const LeftBarIcon = ({ style }: IconsProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <title>Left bar icons</title>
      <path
        d="M6.36,22.38c0-1.67,0-3.34,0-5,0-.35-.1-.48-.46-.46-.54,0-1.09,0-1.63,0a.81.81,0,0,1-.69-.35A1.93,1.93,0,0,1,3.79,14c.89-.91,1.79-1.8,2.69-2.69q4-4,8-8a2,2,0,0,1,3.06,0L28.12,13.9a1.94,1.94,0,0,1,.38,2.54.86.86,0,0,1-.85.48c-.51,0-1,0-1.55,0-.35,0-.47.1-.47.46,0,2,0,4,0,6.05v3.84a2,2,0,0,1-2.14,2.16h-5c-.58,0-.59,0-.59-.57V22.21c0-1-.37-1.33-1.34-1.33H15.32c-.87,0-1.26.39-1.26,1.25,0,2.24,0,4.47,0,6.71,0,.58,0,.58-.59.58h-5a2,2,0,0,1-2.07-2.07C6.35,25.69,6.36,24,6.36,22.38Z"
        fill={colorsTheme.antiFlashWhite}
      />
    </svg>
  );
};
