import { NodeModel } from '@minoru/react-dnd-treeview';
import dataDefault from '../../components/form/configForm/initialValue';
import { UserData } from '../../hooks/types';
import { SchemaProperties } from '../configuration/types';
import {
  ServerDataFolder,
  WorkflowConfig,
  WorkflowFolder,
  WorkItemType,
  ServerDataConfig,
  Workflow,
  FormConfigs,
  TypeConfigs,
} from './types';

const initial = dataDefault;

const getFormConfig = (config: string, form: FormConfigs) => {
  type objKey = keyof typeof initial;
  const varKey = config as objKey;
  type tempType = (typeof initial)[typeof varKey];
  const formConfig = form as unknown as tempType;

  return formConfig;
};

export const isConfigType = (
  workflow: Workflow
): workflow is WorkflowConfig => {
  return workflow.type === WorkItemType.config;
};

export const isFolderType = (
  workflow: Workflow
): workflow is WorkflowFolder => {
  return workflow.type === WorkItemType.folder;
};

export const makeDataObjectFromFolder = (
  folder: WorkflowFolder,
  configurations: ServerDataConfig[],
  foldersChildrens: ServerDataFolder[]
): ServerDataFolder => {
  return {
    folderId: folder?.folderId ?? folder?.name,
    displayName: folder?.name,
    sort: folder.sort,
    configurations: configurations,
    folders: foldersChildrens,
  };
};

export const makeDataObjectFromConfiguration = (
  config: WorkflowConfig
): ServerDataConfig => {
  let typeConfig = config.typeConfig;
  if (typeConfig === 'keymanagement') typeConfig = 'KeyContainer';
  return {
    configId: config.configId,
    itemId: `${config.id}`,
    sort: config.sort,
    typeConfig,
  };
};

export const serialization = (
  workflowsSerialization: Workflow[]
): ServerDataFolder[] => {
  const resultFolders: ServerDataFolder[] = [];
  const folderIndex: { [key: string]: ServerDataFolder } = {};
  const sortWorkflowsSerialization = sortWorkflows([...workflowsSerialization]);
  sortWorkflowsSerialization.forEach((value: Workflow) => {
    if (isFolderType(value)) {
      let dataOjbect = null;
      if (value.parentId === 0) {
        dataOjbect = makeDataObjectFromFolder(value, [], []);
        resultFolders.push(dataOjbect);
      } else {
        const parent = folderIndex[value.parentId];
        if (parent) {
          dataOjbect = makeDataObjectFromFolder(value, [], []);
          parent.folders.push(dataOjbect);
        }
      }
      folderIndex[value.id] = dataOjbect;
    } else {
      const parent = folderIndex[value.parentId];
      if (parent) {
        parent.configurations.push(makeDataObjectFromConfiguration(value));
      }
    }
  });

  return resultFolders;
};

type Formschema = UserData & {
  components?: {
    schemas: SchemaProperties;
  };
};

export const configFormNormalization = (
  endpoint: string,
  formschema: Formschema,
  data: FormConfigs
) => {
  const formData = { ...data };

  const schema = endpointToSchema(endpoint);
  const mainFormSchema = formschema.components.schemas[schema] ?? {};

  const getDefaultValue = (type: string) => {
    if (type === 'string') return '';
    if (type === 'number') return '';
    if (type === 'array') return [];
    if (type === 'object') return {};
    return '';
  };

  const getSchema = (ref: string, parentSchema: Formschema) => {
    const subSchema = (ref ?? '').replace('#/components/schemas/', '');
    const formSubSchema = parentSchema.components.schemas[subSchema] ?? {};
    return formSubSchema?.properties ?? {};
  };

  const normalization = (valueFormData: object, formSchema) => {
    Object.entries(formSchema ?? {}).forEach(([keyField, value]) => {
      const formValue = valueFormData[keyField];
      const formType = value?.['type'];
      const subRef = value?.['$ref'];
      const subRefItems = value?.['items']?.['$ref'];
      const subRefPropertie = value?.['additionalProperties']?.['$ref'];
      if (
        formValue === null &&
        (formType !== undefined || subRef !== undefined)
      ) {
        valueFormData[keyField] = getDefaultValue(
          subRef !== undefined ? 'object' : formType
        );
      } else if (formValue !== null && subRef !== undefined) {
        // ref schema object
        const properties = getSchema(subRef, formschema);
        normalization(formValue, properties);
      } else if (formValue !== null && subRefItems !== undefined) {
        // ref schema array
        const properties = getSchema(subRefItems, formschema);
        formValue.forEach((item) => {
          normalization(item, properties);
        });
      } else if (
        // ref schema object value object
        formValue !== null &&
        subRefPropertie !== undefined &&
        formType === 'object'
      ) {
        const properties = getSchema(subRefPropertie, formschema);
        Object.keys(formValue).forEach((itemKey) => {
          normalization(formValue[itemKey], properties);
        });
      }
    });
  };

  normalization(formData, mainFormSchema?.properties ?? {});

  return formData;
};

export const getRootParentFolder = (
  workflows: Workflow[],
  workflowId: Workflow['id']
): WorkflowFolder => {
  const workflowIndex = workflows.reduce(
    (
      acculator: Record<Workflow['id'], Workflow>,
      value: Workflow
    ): Record<Workflow['id'], Workflow> => {
      acculator[value.id] = value;
      return acculator;
    },
    {}
  );

  const workflow = workflowIndex[workflowId];
  if (workflow.parentId === 0 && isFolderType(workflow)) {
    return workflow;
  }

  let parentId = workflow.parentId;
  let parentWorkflow = workflowIndex[parentId];
  while (parentWorkflow.parentId !== 0) {
    parentId = parentWorkflow.parentId;
    parentWorkflow = workflowIndex[parentId];
  }

  return parentWorkflow as WorkflowFolder;
};

export const getParentsFolder = (
  workflows: Workflow[],
  workflowId: Workflow['id']
): number[] => {
  const workflowIndex = workflows.reduce(
    (
      acculator: Record<Workflow['id'], Workflow>,
      value: Workflow
    ): Record<Workflow['id'], Workflow> => {
      acculator[value.id] = value;
      return acculator;
    },
    {}
  );

  const workflow = workflowIndex[workflowId];
  if (workflow.parentId === 0) {
    return [workflow.id];
  }

  const parentIds: number[] = [];
  let parentId = workflow.parentId;
  let parentWorkflow = workflowIndex[parentId];
  parentIds.push(parentWorkflow.id);
  while (parentWorkflow.parentId !== 0) {
    parentId = parentWorkflow.parentId;
    parentWorkflow = workflowIndex[parentId];
    parentIds.push(parentWorkflow.id);
  }

  return parentIds;
};

export const generateNumericGUID = () => Math.floor(Math.random() * 1e15);

export const initializationFormConfig = (
  endpoint: string,
  primaryKeyField: string,
  schema: string,
  name?: string
) => {
  const nameKey = name ?? endpoint;
  return {
    ...initial[schema.toLowerCase()],
    [primaryKeyField]: nameKey,
  };
};

export const getFormConfigId = (
  schema: string,
  primaryKeyField: string,
  form: FormConfigs
) => {
  if (schema && primaryKeyField) {
    const formConfig = getFormConfig(schema, form);
    if (schema === 'transforms') {
      return formConfig[
        Object.keys(formConfig).find(
          (key) => key.toLowerCase() === primaryKeyField.toLowerCase()
        )
      ];
    } else {
      return formConfig[primaryKeyField];
    }
  }
  return '';
};

export const endpointToSchema = (endpoint: string) => {
  if (endpoint === 'applications') return 'Application';
  if (endpoint === 'connections') return 'Connection';
  if (endpoint === 'APIs') return 'API';
  if (endpoint === 'transforms') return 'DataTransform';
  if (endpoint === 'workflows') return 'Workflow';
  if (endpoint === 'templates') return 'Template';
  if (endpoint === 'rules') return 'Rule';
  if (endpoint === 'hl7messagetemplate') return 'HL7MessageTemplate';
  if (endpoint === 'kmslocalkey') return 'KMSLocalKeyClient';
  if (endpoint === 'keymanagement') return 'KeyContainerClient';
  if (endpoint === 'secrets') return 'Secrets';
  if (endpoint === 'datavalues') return 'DataValues';
  if (endpoint === 'storedprocedures') return 'StoredProcedure';
  if (endpoint === 'genericconfigurations') return 'GenericConfiguration';
  return null;
};

export const normalizatorTree = (serverData: ServerDataFolder[]) => {
  const normalizatorWorkflow: Workflow[] = [];

  const normalizeTypeConfig = (typeConfig: string): TypeConfigs => {
    if (typeConfig === 'Applications') return 'applications';
    if (typeConfig === 'Connections') return 'connections';
    if (typeConfig === 'APIS') return 'APIs';
    if (typeConfig === 'Transforms') return 'transforms';
    if (typeConfig === 'Workflows') return 'workflows';
    if (typeConfig === 'Templates') return 'templates';
    if (typeConfig === 'Rules') return 'rules';
    if (typeConfig === 'HL7MessageTemplate') return 'hl7messagetemplate';
    if (typeConfig === 'KMSLocalKey') return 'kmslocalkey';
    if (typeConfig === 'KeyContainer') return 'keymanagement';
    if (typeConfig === 'Secrets') return 'secrets';
    if (typeConfig === 'DataValues') return 'datavalues';
    if (typeConfig === 'StoredProcedure') return 'storedprocedures';
    if (typeConfig === 'StoredProcedures') return 'storedprocedures';
    if (typeConfig === 'GenericConfigurations') return 'genericconfigurations';
  };

  const normalizato = (
    rootItem: ServerDataFolder,
    id: number,
    parentId = 0,
    level = 0
  ) => {
    normalizatorWorkflow.push({
      id: id,
      parentId: parentId,
      level: level,
      type: WorkItemType.folder,
      name: rootItem.displayName ?? rootItem.folderId,
      folderId: rootItem.folderId,
      sort: rootItem.sort,
      edit: false,
      open: false,
      localCreate: false,
    });
    (rootItem.configurations ?? []).forEach((rootConfig) => {
      normalizatorWorkflow.push({
        id: generateNumericGUID(),
        parentId: id,
        type: WorkItemType.config,
        typeConfig: normalizeTypeConfig(rootConfig.typeConfig),
        sort: rootConfig.sort,
        configId: rootConfig.configId,
        itemId: rootConfig.itemId,
        name: rootConfig.configId,
        edit: false,
        open: false,
      });
    });
  };

  const forEachTree = (
    serverData: ServerDataFolder[],
    parentId: number,
    level: number
  ) => {
    serverData.forEach((item) => {
      const id = generateNumericGUID();
      normalizato(item, id, parentId, level);
      if ((item?.folders ?? []).length > 0) {
        forEachTree(item?.folders, id, level + 1);
      }
    });
  };

  forEachTree(serverData, 0, 0);

  return normalizatorWorkflow;
};

export const sortWorkflows = (workflows: Workflow[]) => {
  return [...workflows]
    .sort((a, b) => {
      if (a.type > b.type) return -1;
      if (a.type < b.type) return 1;
      return 0;
    })
    .sort((a, b) => {
      if (isFolderType(a) && isFolderType(b)) {
        if (a.level > b.level) return 1;
        if (a.level < b.level) return -1;
        return 0;
      }
      return 0;
    });
};
export const hashFolderInConfigNames = (workflows: Workflow[]) => {
  const hash: Record<
    Workflow['id'],
    {
      folderName: string;
      folders: string[];
      configsId: string[];
      configsItemId: string[];
    }
  > = {};
  const resultSortWorkflows = sortWorkflows(workflows);
  resultSortWorkflows.forEach((workflow) => {
    if (isFolderType(workflow) && !hash[workflow.id]) {
      hash[workflow.id] = {
        configsId: [],
        configsItemId: [],
        folderName: workflow?.name?.toLowerCase(),
        folders: [],
      };
    } else if (isConfigType(workflow) && hash[workflow.parentId]) {
      hash[workflow.parentId].configsId.push(workflow?.configId?.toLowerCase());
      hash[workflow.parentId].configsItemId.push(workflow?.itemId);
    }
  });
  resultSortWorkflows.reverse().forEach((workflow) => {
    if (isFolderType(workflow) && hash[workflow.parentId]) {
      hash[workflow.parentId].configsId = [
        ...(hash[workflow.id]?.configsId ?? []),
        ...(hash[workflow.parentId]?.configsId ?? []),
      ];
      hash[workflow.parentId].configsItemId = [
        ...(hash[workflow.id]?.configsItemId ?? []),
        ...(hash[workflow.parentId]?.configsItemId ?? []),
      ];
      hash[workflow.parentId].folders = [
        hash[workflow.id]?.folderName ?? '',
        ...(hash[workflow.id]?.folders ?? []),
        ...(hash[workflow.parentId]?.folders ?? []),
      ];
    }
  });
  return hash;
};

export const generateNodeTreeId = (id: NodeModel['id']) => `tree-node-${id}`;
