import React, { useMemo, useCallback } from 'react';
import { Box, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { colorsTheme } from '../constants';

type CustomBreadcrumbProps = {
  handleChangeLink: (path: string) => void;
};

const WelcomeBreadCumb = ({ loggedInUser }) => (
  <Breadcrumbs
    sx={{
      mt: -4,
      height: '80px',
      fontSize: { md: '1.5rem', xs: '1rem' },
    }}
  >
    <Box sx={{ pt: 2 }}>
      <h5
        style={{
          fontWeight: 'bold',
          color: colorsTheme.policeBlue,
          textTransform: 'capitalize',
        }}
      >
        Hello {loggedInUser}!
      </h5>
    </Box>
  </Breadcrumbs>
);

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({
  handleChangeLink,
}): JSX.Element => {
  const location = useLocation();
  const { loggedInUser } = useAuth();

  const requireBreadcumbs = useMemo(
    () => location && location.pathname.slice(1) !== '',
    [location]
  );

  const pathname = useMemo(
    () =>
      location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname,
    [location]
  );
  const locationPaths = useMemo(() => {
    if (location.pathname.includes('/edit/')) {
      const [base, id] = location.pathname.split('/edit/');
      return [...base.split('/'), 'edit', id];
    }
    return location.pathname.split('/');
  }, [location]);

  const getPath = useCallback(
    (itm) => {
      const arr = pathname.split('/');
      return itm === arr[arr.length - 1] || ['edit', 'create'].includes(itm)
        ? '#'
        : `${location.pathname.split(itm)[0]}${decodeURI(itm)}`;
    },
    [pathname]
  );

  const highlight = useCallback(
    (itm) => {
      const arr = pathname.split('/');
      return ['edit', 'create'].includes(itm) || itm === arr[arr.length - 1];
    },
    [pathname]
  );

  return (
    <Box sx={{ mt: 1 }}>
      {requireBreadcumbs ? (
        <Box sx={{ ml: 1 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ height: '40px', ml: -1 }}
          >
            {locationPaths.map((itm: string) => {
              const path = getPath(itm);
              return (
                itm && (
                  <span
                    style={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                    }}
                    key={itm}
                  >
                    <Box sx={{ fontSize: { md: '1.2vw', xs: '0.8rem' } }}>
                      <Link
                        underline="none"
                        onClick={() =>
                          !highlight(itm) && handleChangeLink(path)
                        }
                        style={
                          highlight(itm)
                            ? { color: colorsTheme.tiffanyBlue }
                            : {
                                color: colorsTheme.policeBlue,
                                cursor: 'pointer',
                              }
                        }
                      >
                        {itm != 'transforms'
                          ? decodeURI(itm)
                          : decodeURI('DataTransforms')}
                      </Link>
                    </Box>
                  </span>
                )
              );
            })}
          </Breadcrumbs>
        </Box>
      ) : (
        <WelcomeBreadCumb loggedInUser={loggedInUser} />
      )}
      <Box sx={{ mt: { md: -1, xs: 1 } }}>
        <hr style={{ color: colorsTheme.spanishGray }} />
      </Box>
    </Box>
  );
};

export default CustomBreadcrumb;
