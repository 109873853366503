import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const WorkflowsIcon = ({ style }: IconsProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
    >
      <title>svg format icon for Development</title>
      <path
        d="M222.55,130.22c-1.42-.1-2.83-.29-4.24-.46-6.75-.81-8.79-2.71-10.32-9.27-.36-1.52.34-2.47,1.13-3.49,2.13-2.77,4.35-5.47,6.37-8.32,3.74-5.27,3.46-10.54-.92-15.17q-5.46-5.79-11.25-11.25c-4.64-4.38-9.9-4.64-15.18-.93a34.57,34.57,0,0,0-5.5,4.3c-3.25,3.44-6.49,3.65-10.66,1.5-2.69-1.39-4.46-2.71-4.52-5.93-.05-2.31-.68-4.61-.86-6.92a13.44,13.44,0,0,0-7.67-11.75H134.85a13.33,13.33,0,0,0-7.66,11.75c-.1,1.41-.3,2.83-.46,4.24-.82,6.85-2.59,8.75-9.28,10.32-1.52.36-2.47-.33-3.5-1.12-2.87-2.21-5.67-4.53-8.64-6.59C100.2,77.59,95,78,90.46,82.26q-5.77,5.46-11.25,11.25c-4.29,4.54-4.64,9.73-1.15,14.86a36.76,36.76,0,0,0,4.51,5.82c3.42,3.26,3.67,6.49,1.52,10.65-1.4,2.69-2.72,4.47-5.94,4.54-2.31.05-4.6.67-6.92.85-5.35.41-9.31,2.85-11.75,7.67V162a13.33,13.33,0,0,0,11.75,7.66c1.42.1,2.83.29,4.24.46,6.8.8,8.77,2.63,10.32,9.28.36,1.53-.33,2.47-1.12,3.5-2.13,2.77-4.36,5.47-6.38,8.32-3.76,5.28-3.47,10.52.92,15.17q5.46,5.77,11.25,11.25c4.53,4.28,9.73,4.63,14.86,1.13a35.27,35.27,0,0,0,5.82-4.5c3.26-3.41,6.49-3.67,10.66-1.51,2.69,1.4,4.46,2.72,4.52,5.94.05,2.31.68,4.61.86,6.92a13.46,13.46,0,0,0,7.67,11.75h24.08a13.38,13.38,0,0,0,7.66-11.74c.09-1.42.3-2.83.46-4.25.82-6.85,2.61-8.78,9.28-10.31,1.51-.35,2.47.31,3.5,1.11,2.77,2.14,5.47,4.36,8.32,6.38,5.28,3.75,10.52,3.46,15.17-.92q5.77-5.44,11.25-11.25c4.38-4.64,4.64-9.88.94-15.17-1.26-1.8-2.67-3.5-4-5.24-4.2-5.45-4.28-7.66-.61-13.35a4,4,0,0,1,3.2-1.86c2.69-.31,5.37-.79,8.08-1,5.45-.43,9.59-2.83,12.14-7.76V137.9C231.86,133.08,227.89,130.62,222.55,130.22Zm-75.66,68.7a49,49,0,1,1,49-49A49,49,0,0,1,146.89,198.92Z"
        fill={colorsTheme.white}
      />
      <path
        d="M134.27,172.07q-.3,0-.6,0a6.91,6.91,0,0,1-4.8-2.58l-8.94-11.31a6.88,6.88,0,0,1,10.8-8.53l4.41,5.57,28.73-25.63A6.88,6.88,0,0,1,173,139.82l-34.18,30.5A6.89,6.89,0,0,1,134.27,172.07Z"
        fill={colorsTheme.white}
      />
      <path
        d="M205.28,32.33a53.88,53.88,0,0,1,50.47,45.89,6,6,0,0,1,.1.94c0,1,.45,1.46,1.5,1.4s2.24,0,3.36,0c1.34,0,1.44.19.75,1.39Q258,88,254.42,94c-.4.7-.86,1.38-1.22,2.1-.51,1-.93,1-1.5,0-1.78-3.12-3.6-6.21-5.4-9.32-1-1.71-2-3.42-3-5.12-.51-.84-.38-1.22.66-1.19s2,0,3,0,1.15-.24,1-1.18A43.8,43.8,0,0,0,242.14,63c-7.7-12.65-18.92-20.09-33.56-22.36a34.56,34.56,0,0,0-4.55-.33c-1,0-1.47-.35-1.43-1.42a46.15,46.15,0,0,0,0-4.85c-.09-1.36.38-1.92,1.74-1.72A6,6,0,0,0,205.28,32.33Z"
        fill={colorsTheme.white}
      />
      <path
        d="M88.5,267.55A53.87,53.87,0,0,1,38,221.65a5.88,5.88,0,0,1-.1-.93c0-1-.45-1.46-1.5-1.4s-2.24,0-3.36,0c-1.34,0-1.44-.19-.75-1.39q3.51-6.06,7-12.1c.4-.7.85-1.38,1.21-2.1.52-1,.93-1,1.51,0,1.78,3.12,3.6,6.21,5.4,9.32,1,1.71,2,3.42,3,5.12.51.84.38,1.22-.66,1.19s-2,0-3,0-1.15.24-1,1.17a43.78,43.78,0,0,0,5.81,16.39c7.7,12.65,18.92,20.09,33.56,22.36a34.56,34.56,0,0,0,4.55.33c1,0,1.47.34,1.43,1.42a46.15,46.15,0,0,0,0,4.85c.09,1.36-.38,1.92-1.74,1.72A6,6,0,0,0,88.5,267.55Z"
        fill={colorsTheme.white}
      />
      <path
        d="M264.5,208.33a53.87,53.87,0,0,1-45.9,50.47,5.88,5.88,0,0,1-.93.1c-1,0-1.46.45-1.4,1.5s0,2.24,0,3.36c0,1.34-.19,1.44-1.39.75q-6.06-3.51-12.1-7c-.7-.4-1.38-.86-2.1-1.22-1-.51-1-.93,0-1.5,3.12-1.78,6.21-3.6,9.32-5.4,1.71-1,3.42-2,5.12-3,.84-.51,1.22-.38,1.19.66s0,2,0,3,.24,1.15,1.17,1a43.78,43.78,0,0,0,16.39-5.81c12.65-7.7,20.09-18.92,22.36-33.56a34.56,34.56,0,0,0,.33-4.55c0-1,.34-1.47,1.42-1.43a46,46,0,0,0,4.84,0c1.37-.09,1.93.38,1.73,1.74A6,6,0,0,0,264.5,208.33Z"
        fill={colorsTheme.white}
      />
      <path
        d="M29.28,91.55A53.88,53.88,0,0,1,75.17,41.08a6,6,0,0,1,.94-.1c1,0,1.46-.45,1.4-1.5s0-2.24,0-3.36c0-1.34.19-1.44,1.39-.75q6.06,3.51,12.1,7c.7.4,1.38.85,2.1,1.21,1,.52,1,.93,0,1.51-3.12,1.78-6.21,3.6-9.32,5.4-1.71,1-3.42,2-5.12,3-.84.51-1.22.38-1.19-.66s0-2,0-3-.24-1.15-1.18-1A43.8,43.8,0,0,0,60,54.69C47.3,62.39,39.86,73.61,37.59,88.25a34.56,34.56,0,0,0-.33,4.55c0,1-.35,1.47-1.42,1.43a46.15,46.15,0,0,0-4.85,0c-1.36.09-1.92-.38-1.72-1.74A6,6,0,0,0,29.28,91.55Z"
        fill={colorsTheme.white}
      />
      <circle cx="150" cy="27.2" r="21.38" fill={colorsTheme.white} />
      <circle cx="150" cy="273.83" r="21.38" fill={colorsTheme.white} />
      <circle cx="273.31" cy="150.51" r="21.38" fill={colorsTheme.white} />
      <circle cx="26.69" cy="150.51" r="21.38" fill={colorsTheme.white} />
    </svg>
  );
};
