import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';

type ResponseDeleteFolderById = {
  success: boolean;
  message?: string;
};
type UseDeleteFolderByIdValiables = {
  folderId: string;
};
type UseDeleteFolderByIdParams = {
  mutationParams?: UseMutationOptions<
    ResponseDeleteFolderById,
    unknown,
    UseDeleteFolderByIdValiables
  >;
};

export const useDeleteFolderById = ({
  mutationParams,
}: UseDeleteFolderByIdParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['delete-folder'],
    mutationFn: ({ folderId }: UseDeleteFolderByIdValiables) => {
      return axios({
        url: `/api/folders/@${folderId}`,
        method: 'DELETE',
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseDeleteFolderById;
        }
        return Promise.reject(
          response.data.msg ?? 'delete folder success false'
        );
      });
    },
  });
};
