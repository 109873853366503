import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { NodeModel } from '@minoru/react-dnd-treeview';

import { WorkflowItem } from '../../types';
import { workflowsAtom } from '../../state';
import { isFolderType } from '../../helpers';
import { colorsTheme } from '../../../../constants';

const stylesHover = {
  '&:hover': {
    cursor: 'pointer',
  },
  ml: '0.25rem',
};

type TreeNameProps = {
  node: NodeModel<WorkflowItem>;
  enabledEdit: boolean;
  onEnabledEdit: (id: NodeModel['id']) => void;
  onEdit: (id: NodeModel['id'], name: string) => void;
  onOpen: (e: React.MouseEvent) => void;
  onDelete: () => void;
};

export const TreeName = ({
  node,
  enabledEdit,
  onEnabledEdit,
  onEdit,
  onOpen,
  onDelete,
}: TreeNameProps) => {
  const [workflows] = useAtom(workflowsAtom);
  const { id, text = '', parent } = node;
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    setName(text);
  }, [text, enabledEdit]);
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleEdit = () => {
    setError('');
    onEnabledEdit(id);
    if (node.data?.localCreate === true) {
      onDelete();
    }
  };

  const validation = (name: string) => {
    const isExist = workflows.some(
      (workflow) =>
        isFolderType(workflow) &&
        workflow.name === name &&
        workflow.id !== id &&
        workflow.parentId === parent
    );

    setError('');
    if (isExist) {
      setError('Name already exists');
      return false;
    }
    return true;
  };

  const handleEditSave = () => {
    if (validation(name)) {
      onEdit(id, name);
    }
  };
  const handelKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleEditSave();
    }
  };

  return enabledEdit ? (
    <Stack direction="row" sx={{ ml: '1rem' }}>
      <TextField
        variant="standard"
        value={name}
        onChange={handleInput}
        onKeyDown={handelKeyPress}
        helperText={error}
        error={error.length > 0}
      />
      <IconButton size="small" onClick={handleEditSave}>
        <CheckIcon htmlColor={colorsTheme.harlequinGreen} />
      </IconButton>
      <IconButton size="small" onClick={handleEdit}>
        <CloseIcon htmlColor={colorsTheme.red} />
      </IconButton>
    </Stack>
  ) : (
    <Box sx={stylesHover} onClick={onOpen}>
      <Typography>
        {(name && name.length) > 40 ? name.slice(0, 40) + '...' : name}
      </Typography>
    </Box>
  );
};
