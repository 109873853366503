import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { AxiosError } from 'axios';

import { useEnvironment } from './useEnvironment';
import { useStorage } from './useStorage';
import { SignUpForm } from '../types/SignUp';
import { AxiosContext, AxiosEvent } from '../hoc/withAxios';
import {
  AuthProviderProps,
  LoginExecuteParams,
  RegisterExecuteParams,
  AuthContextValue,
  UserData,
} from './types';
import {
  messageAccountNotVerified,
  messageAccountVerified,
  messageIncorrectCode,
  messageIncorrectPassword,
  messageInvalidCode,
  messageLimitExceeded,
  messageLoginSuccessful,
  messagePasswordChanged,
} from '../constants';
import { LoginForm, ResetForm } from '../types/SignIn';
import {
  useLoginOnprem,
  useLogoutOnprem,
  useRegisterOnprem,
} from './api/mutations';
import { ChangePasswordForm } from '../components/form/changePassword/ChangePassword';
export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);
export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const { environment } = useEnvironment();
  const {
    eventEmitter: axiosEventtEmitter,
    setLoading,
    showToast,
  } = React.useContext(AxiosContext);
  const { mutateAsync: mutateLoginOnprem } = useLoginOnprem();
  const { mutateAsync: mutateLogoutOnprem } = useLogoutOnprem();
  const { mutateAsync: mutateRegisterOnprem } = useRegisterOnprem();

  const UserPool: CognitoUserPool | null = useMemo(() => {
    if (environment.onprem) {
      return null;
    }
    const poolData = {
      UserPoolId: environment.userPoolId ?? '',
      ClientId: environment.clientId ?? '',
    };
    return new CognitoUserPool(poolData);
  }, [environment]);

  const { value: user, setValue: setUser, clear } = useStorage('user');
  const { clear: clearformschema } = useStorage('formschema');
  const { clear: clearprompt } = useStorage('prompt');
  const [response, setResponse] = useState(false);
  const navigate = useNavigate();
  const [verificationBoxopen, setVerificationBoxopen] = useState(false);
  const [email, setemail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setsuccess] = useState(false);
  const [val, setVal] = useState('');
  const [mail, setMail] = useState('');
  const [passwordreveal, setpasswordreveal] = useState(false);
  const [newpasswordreveal, setnewpasswordreveal] = useState(false);
  const [confirmnewpasswordreveal, setconfirmnewpasswordreveal] =
    useState(false);
  const [open, setOpen] = useState(true);
  const [pop, setpop] = useState(false);
  const [res, setres] = useState(false);

  const [erroropen, seterroropen] = useState(false);
  const [showSession, setShowSession] = useState(false);
  const [remainingTime] = useState<number | null>(null);
  let userDetailRes = {};

  const [userAction, setUserAction] = useState<CognitoUser>();

  const loggedInUser: string | null = useMemo(() => {
    if (user) {
      if (environment && environment.onprem && user?.name) return user?.name;
      else if (user.UserAttributes) {
        const userName =
          user.UserAttributes.find((itm) => itm.Name === 'name')?.Value ||
          user?.idToken?.payload.name ||
          'Guest';
        return userName;
      } else return 'Guest';
    }
    return null;
  }, [environment, user]);

  const createUserAction = useCallback(
    (email: string) => {
      return new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
    },
    [UserPool]
  );

  // For signup
  const signUp = useCallback(
    async ({ email, password, displayName }: SignUpForm) => {
      setLoading(true);
      const attributeList = [
        new CognitoUserAttribute({
          Name: 'name',
          Value: displayName,
        }),
      ];
      UserPool.signUp(email, password, attributeList, null, (error, result) => {
        if (error) {
          setVerificationBoxopen(false);
          setLoading(false);
          showToast({
            show: true,
            message: error.message,
            severity: 'error',
          });
          return error;
        } else {
          setVerificationBoxopen(true);
          setLoading(false);
          showToast({
            show: true,
            message: 'Successfully created user',
            severity: 'success',
          });
          return result.user;
        }
      });
    },
    []
  );

  const verifyUserAccount = useCallback((email: string, pin: string) => {
    const p = new Promise((res, rej) => {
      const userData = { Username: email, Pool: UserPool };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmRegistration(pin, true, function (err, result) {
        if (err) {
          showToast({
            show: true,
            message: messageIncorrectCode,
            severity: 'error',
          });
          rej(err);
          return;
        }
        if (result == 'SUCCESS') {
          setVal('');
          navigate('');
          setsuccess(true);
          setnewpasswordreveal(false);
          setLoading(false);
          setVerificationBoxopen(false);
          showToast({
            show: true,
            message: messageAccountVerified,
            severity: 'success',
          });

          cognitoUser.signOut();
        } else {
          setLoading(false);
          showToast({
            show: true,
            message: messageAccountNotVerified,
            severity: 'error',
          });
          rej(messageAccountNotVerified);
        }
      });
    });
    return p;
  }, []);

  const onSigninSuccess = useCallback((res, userAction) => {
    setLoading(false);
    if (res) {
      userAction.setSignInUserSession(res);
      userAction.getUserData((err, userDetails) => {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        userDetailRes = { ...userDetails };
        setUser({ ...res, ...userDetailRes, loginTime: new Date() });
        navigate('/');
        setLoading(false);
        setconfirmnewpasswordreveal(false);
        setnewpasswordreveal(false);
        setpasswordreveal(false);
        showToast({
          show: true,
          message: messageLoginSuccessful,
          severity: 'success',
        });
      });
    } else {
      showToast({
        show: true,
        message: JSON.stringify(res),
        severity: 'error',
      });

      setLoading(false);
      return null;
    }
  }, []);

  // Aws Congnito Signin
  const signInFunc = useCallback(
    ({ email, password }: LoginForm) => {
      setErrMsg('');
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      setLoading(true);
      const userAction = createUserAction(email);
      setUserAction(userAction);
      userAction.authenticateUser(authDetails, {
        onSuccess: (res) => onSigninSuccess(res, userAction),
        newPasswordRequired: (userAttributes) => {
          // User was signed up by an admin.
          if (!userAttributes.name)
            userAttributes.name = userAttributes.email.split('@')[0];
          delete userAttributes.email_verified;
          delete userAttributes.email;

          userAction.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: (res) => onSigninSuccess(res, userAction),
            onFailure: (err) => {
              setLoading(false);
              showToast({
                show: true,
                message: err.message,
                severity: 'error',
              });
              return null;
            },
          });
        },
        onFailure: (err) => {
          setLoading(false);
          showToast({
            show: true,
            message: err.message,
            severity: 'error',
          });
          return null;
        },
      });
    },
    [createUserAction, user]
  );

  // Refresh Token
  const refreshToken = useCallback((): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        const userData = { Username: email, Pool: UserPool };
        const cognitoUser = new CognitoUser(userData);
        const refToken = new CognitoRefreshToken({
          RefreshToken: user?.refreshToken?.token,
        });
        cognitoUser.refreshSession(refToken, (err, session) => {
          if (session) {
            setUser({ ...user, ...session, referedOn: new Date() });
            resolve();
          }
        });
      } catch (err) {
        reject(err);
      }
    });
  }, [user]);

  const forgot = useCallback((email: string) => {
    const userAction = createUserAction(email);
    userAction.forgotPassword({
      onSuccess: () => {
        setLoading(false);
        setsuccess(true);
        setResponse(true);
        setUserAction(userAction);
      },
      onFailure: (err) => {
        setLoading(false);
        showToast({ show: true, message: err.message, severity: 'error' });
        setResponse(false);
      },
    });
  }, []);

  const resetPassword = useCallback(
    (data: ResetForm) => {
      userAction.confirmPassword(data.resetcode, data.confirmPassword, {
        onSuccess: () => {
          setLoading(false);
          setVerificationBoxopen(false);
          setResponse(false);
          setsuccess(true);
          showToast({
            show: true,
            message: messagePasswordChanged,
            severity: 'success',
          });
          setconfirmnewpasswordreveal(false);
          setnewpasswordreveal(false);
          setpasswordreveal(false);
        },
        onFailure: (err) => {
          setLoading(false);
          showToast({
            show: true,
            message: Object.values(err).includes('InvalidParameterException')
              ? messageInvalidCode
              : err.message,
            severity: 'error',
          });
          setResponse(true);
        },
      });
    },
    [userAction]
  );

  const headerChangePassword = useCallback((data: ChangePasswordForm) => {
    return getSession()
      .then((res) => {
        res['user'].changePassword(data.password, data.newpassword, (err) => {
          if (err) {
            showToast({
              show: true,
              message: Object.values(err).includes('LimitExceededException')
                ? messageLimitExceeded
                : messageIncorrectPassword,
              severity: 'error',
            });
          } else {
            setVerificationBoxopen(false);
            setsuccess(true);
            setconfirmnewpasswordreveal(false);
            setnewpasswordreveal(false);
            setpasswordreveal(false);
            showToast({
              show: true,
              message: messagePasswordChanged,
              severity: 'success',
            });
          }
        });
      })
      .catch((error) => console.error(error));
  }, []);

  const logout = useCallback(
    (message?: string) => {
      const clearEverything = () => {
        setErrMsg('');
        showToast({
          show: true,
          message: message ?? 'You have successfully logged out',
          severity: 'info',
        });
        setconfirmnewpasswordreveal(false);
        setnewpasswordreveal(false);
        setpasswordreveal(false);
        setOpen(true);
        clear();
        clearformschema();
        clearprompt();
        localStorage.clear();
        setUserAction(null);
        navigate('', { replace: true });
        window.location.reload();
      };

      if (environment.onprem) {
        mutateLogoutOnprem()
          .then(() => clearEverything())
          .catch((error) => console.error(error));
      } else {
        if (user && userAction?.getSignInUserSession()?.isValid()) {
          userAction.globalSignOut({
            onSuccess: clearEverything,
            onFailure: (err) => console.log('onFailure', err),
          });
        } else {
          clearEverything();
        }
      }
    },
    [user, userAction]
  );

  const getSession = useCallback(() => {
    return new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            let attributes;
            user.getUserAttributes((err, attr) => {
              if (!err) {
                const results = {};
                for (const attribute of attr) {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                }
                attributes = results;
              }
            });
            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        reject();
      }
    });
  }, [UserPool]);

  const loginExecutePrem = useCallback(
    ({ email, password }: LoginExecuteParams) => {
      return mutateLoginOnprem({
        data: {
          email,
          password,
        },
      })
        .then((response) => {
          if (response.success) {
            setUser({
              name: response.data.name,
              email: response.data.email,
            });
            setLoading(false);
            setconfirmnewpasswordreveal(false);
            setnewpasswordreveal(false);
            setpasswordreveal(false);

            showToast({
              show: true,
              message: 'You are successfully logged in',
              severity: 'success',
            });
          } else {
            setLoading(false);
            showToast({
              show: true,
              message: 'Login error',
              severity: 'error',
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          showToast({
            show: true,
            message: 'Login error',
            severity: 'error',
          });
          throw error;
        });
    },
    []
  );

  const registerExecutePrem = useCallback(
    ({ email, password, name }: RegisterExecuteParams) => {
      return mutateRegisterOnprem({
        data: {
          email,
          password,
          name,
        },
      })
        .then((res) => {
          setLoading(false);
          showToast({
            show: true,
            message: 'Successfully created user',
            severity: 'success',
          });
          return res;
        })
        .catch((error: AxiosError) => {
          setVerificationBoxopen(false);
          setLoading(false);
          showToast({
            show: true,
            message: error.message,
            severity: 'error',
          });
          return error;
        });
    },
    []
  );

  const value: AuthContextValue = useMemo(
    () => ({
      loggedInUser,
      user: user as UserData,
      loginExecutePrem: async (data) => await loginExecutePrem(data),
      registerExecutePrem: async (data) => await registerExecutePrem(data),
      login: async (data) => await signInFunc(data),
      logout,
      signUp: async (data) => await signUp(data),
      setemail,
      response,
      verificationBoxopen,
      setVerificationBoxopen,
      verifyUserAccount,
      setResponse,
      forgot,
      resetPassword,
      headerChangePassword,
      showToast,
      setErrMsg,
      errMsg,
      val,
      setVal,
      mail,
      setMail,
      success,
      setsuccess,
      passwordreveal,
      setpasswordreveal,
      newpasswordreveal,
      setnewpasswordreveal,
      confirmnewpasswordreveal,
      setconfirmnewpasswordreveal,
      open,
      setOpen,
      pop,
      setpop,
      res,
      setres,
      erroropen,
      seterroropen,
      refreshToken,
      showSession,
      setShowSession,
      remainingTime,
    }),
    [
      user,
      response,
      verificationBoxopen,
      mail,
      val,
      success,
      passwordreveal,
      newpasswordreveal,
      confirmnewpasswordreveal,
      open,
      res,
      erroropen,
      showSession,
      remainingTime,
      userAction,
    ]
  );

  useEffect(() => {
    if (user && UserPool && !userAction) {
      const userAction = createUserAction(user?.Username);
      userAction.getSession(() => setUserAction(userAction));
    }
  }, [user, UserPool, userAction]);

  useEffect(() => {
    if (axiosEventtEmitter) {
      axiosEventtEmitter.subscribe((x) => {
        if (x.type === AxiosEvent.LOGOUT) {
          logout('Logout successful. Please log in again to continue.');
        }
      });
    }
  }, [axiosEventtEmitter]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
