import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { colorsTheme } from '../../constants';
import { ThemedCard, ThemedContent } from '../../components/Card';
import { SubTitle, Title } from '../../components/Typography';
import { useGetLicense } from '../../hooks/api/queries';

export const AboutPage = () => {
  const { data } = useGetLicense();
  const license = data?.data ?? null;
  const expirationDate = new Date(license?.expirationDate);
  const formatExpirationDate =
    expirationDate.toString() !== 'Invalid Date' && license?.expirationDate
      ? expirationDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC',
        })
      : license?.expirationDate;
  return (
    <Box p={3} width="60%">
      <ThemedCard>
        <ThemedContent>
          <Title fontWeight="bold" color={colorsTheme.tiffanyBlue}>
            About Health Data Connector
          </Title>
          <SubTitle fontWeight="bold">
            {`You have ${
              license?.licenseName === 'Unlicensed'
                ? 'an unlicensed'
                : 'a licensed'
            } version of Health Data Connector`}
          </SubTitle>
          <Box p={1}>
            <SubTitle fontWeight="bold" color={colorsTheme.tiffanyBlue}>
              License information:
            </SubTitle>
            {license && (
              <Box p={1}>
                <Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight="bold">License Name</Typography>
                    <Typography>{license.licenseName}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography fontWeight="bold">Expires</Typography>
                    <Typography>{formatExpirationDate}</Typography>
                  </Stack>
                </Stack>
                <Box mt={1}>
                  <Stack direction="row" spacing={2}>
                    <Typography fontWeight="bold">Entitlements:</Typography>
                    <Box>
                      <Stack mt={3}>
                        {license.entitlements.map((entitlmen, index) => (
                          <Typography key={index}>{entitlmen}</Typography>
                        ))}
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            )}
          </Box>
          <Box p={1}>
            <SubTitle fontWeight="bold" color={colorsTheme.tiffanyBlue}>
              System Information:
            </SubTitle>
            {license && (
              <Box p={1}>
                <Stack direction="row" spacing={1}>
                  <Typography fontWeight="bold">Build ID</Typography>
                  <Typography>{license?.hdcVersion ?? 'Unknown'}</Typography>
                </Stack>
              </Box>
            )}
          </Box>
        </ThemedContent>
      </ThemedCard>
    </Box>
  );
};
