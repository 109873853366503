import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  loginForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '70%',
  },
  inline: {
    display: 'inline-flex',
  },
  clickable: { cursor: 'pointer', display: 'inline-flex' },
});

export default styles;
