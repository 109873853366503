import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const SecretsConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    fill: colorsTheme.policeBlue,
    stroke: colorsTheme.policeBlue,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  };

  return (
    <svg
      style={style}
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <path
        style={styleCls}
        d="M46.667,76.667C46.667,84.03,39.206,90,30,90s-16.667-5.97-16.667-13.333H10V70h3.333c9.206-8.894,24.128-8.894,33.334,0
            h6.666c9.206-8.894,24.128-8.894,33.334,0H90v6.667h-3.333C86.667,84.03,79.206,90,70,90s-16.667-5.97-16.667-13.333H46.667z"
      />
      <path
        style={styleCls}
        d="M79.929,51.24l-1.826-7.907H21.897l-1.832,7.946C16.65,52.347,13.288,53.581,10,55v5c24.72-6.406,53.356-6.921,80-0.01
            v-5.007C86.67,53.545,83.308,52.298,79.929,51.24z"
      />
      <path
        style={styleCls}
        d="M76.565,36.667L71.501,14.72c-0.827-3.574-4.346-5.547-7.826-4.388l-10.511,3.502c-1.741,0.583-4.587,0.583-6.328,0
            l-10.511-3.502c-3.48-1.159-6.999,0.814-7.822,4.388l-5.065,21.946H76.565z"
      />
    </svg>
  );
};
