import Typography from '@mui/material/Typography';
import ControlledTextField from '../components/form/widgets/ControlledTextField';
import useCustomForm from '../components/form';
import { confirmationCodeSchema } from './schema';
import Box from '@mui/material/Box';
import React, { memo } from 'react';
import { ConfirmationDialog } from '../components/ConfirmationDialog';
import { LockIcon } from '../components/Icons';

type OwnProps = {
  close?: () => void;
  isOpen: boolean;
  verifyUserAccount?: (mail: string, code: string) => void;
  mail: string;
};

const SignupConfirmationCode: React.FC<OwnProps> = memo(
  ({ close, isOpen, verifyUserAccount, mail }) => {
    const {
      handleSubmit: handleConfirmationCode,
      formState: { errors },
      control: confirmationCodeControl,
    } = useCustomForm(
      {
        code: '',
      },
      confirmationCodeSchema
    );
    const data = (data) => {
      verifyUserAccount(mail, data.code);
    };
    return (
      <ConfirmationDialog
        open={isOpen}
        title=" Verification code"
        onAction={() => close}
        actions={[
          {
            label: 'Submit',
            onClick: handleConfirmationCode(data),
          },
        ]}
        icon={<LockIcon style={{ width: '100px', height: '100px' }} />}
      >
        <Typography
          id="modal-modal-description"
          sx={{
            mt: 2,
            mb: 2,
            textAlign: 'center',
            mx: 'auto',
            fontWeight: 700,
            color: 'secondary',
            fontSize: '0.8rem',
          }}
        >
          Please check your email for <br />
          the verification code.
        </Typography>

        <form key={2}>
          <Box>
            <ControlledTextField
              type="code"
              name="code"
              label="Confirmation Code"
              fullWidth
              control={confirmationCodeControl}
              errors={errors}
            />
          </Box>
        </form>
      </ConfirmationDialog>
    );
  }
);

SignupConfirmationCode.displayName = 'SignupConfirmationCode';

export default SignupConfirmationCode;
