import { pdfjs, Document } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import { FC, memo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  LinearProgress,
  Typography,
  Button,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PdfPage from './PdfPage';
import { downloadBlobFile } from '../../lutils';

pdfjs.GlobalWorkerOptions.workerSrc = `/${pdfjs.version}.pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

type ViewHelpGuidePdfModalProps = {
  close?: () => void;
  isOpen: boolean;
  pdfFile: Blob;
  namePdfFile: string;
};

export const ViewHelpGuidePdfModal: FC<ViewHelpGuidePdfModalProps> = memo(
  ({ close, isOpen, pdfFile, namePdfFile }) => {
    const [numPages, setNumPages] = useState<number | null>(null);

    const onDocumentLoadSuccess = ({
      numPages: nextNumPages,
    }: PDFDocumentProxy) => {
      setNumPages(nextNumPages);
    };

    const [firstPageRenderSuccess, setFirstPageRenderSuccess] = useState(false);
    const onPageRenderSuccess = () => {
      setFirstPageRenderSuccess(true);
    };
    const handleDownloadFilePdf = () => {
      downloadBlobFile(pdfFile, namePdfFile);
    };
    return (
      <Dialog open={isOpen} onClose={close} fullWidth maxWidth="md">
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{namePdfFile}</Typography>
            {pdfFile && (
              <Button
                endIcon={<DownloadIcon />}
                onClick={handleDownloadFilePdf}
              >
                Download
              </Button>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
          {(!firstPageRenderSuccess || !pdfFile) && <LinearProgress />}
          <Box display={firstPageRenderSuccess ? 'block' : 'none'}>
            <Stack direction="row" justifyContent="center">
              <Box>
                {pdfFile && (
                  <Document
                    file={pdfFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={options}
                  >
                    {numPages &&
                      Array.from(new Array(numPages), (el, index) => (
                        <PdfPage
                          key={`page_${index + 1}`}
                          index={index + 1}
                          onPageRenderSuccess={
                            index === 0 ? onPageRenderSuccess : undefined
                          }
                        />
                      ))}
                  </Document>
                )}
              </Box>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
);

ViewHelpGuidePdfModal.displayName = 'ViewHelpGuidePdfModal';
