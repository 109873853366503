import { useEffect, useState, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useAuth } from '../hooks/useAuth';
import { ConfigDetailContext } from './form/configForm/ConfigForm';
import { useGetConfigList } from '../hooks/api/queries';
import { getPrimaryKeyFieldsFunc } from '../hooks/misc';
import { useStorage } from '../hooks/useStorage';
import { endpointToSchema } from '../models/workFlowTree/helpers';
import { SchemaProperties } from '../models/configuration/types';
import { FieldProps } from '@rjsf/utils';

type ComboBoxProps = {
  label: string;
  name: string;
  endpoint: string;
  val: unknown;
  required?: boolean;
  onChange?: FieldProps['onChange'];
  enableCustomValue?: boolean;
};

const ComboBox = ({
  label,
  endpoint,
  val,
  required,
  name,
  onChange,
  enableCustomValue = false,
}: ComboBoxProps) => {
  const { setErrMsg } = useAuth();
  const { updateSelected } = useContext(ConfigDetailContext);
  const { value: formschema } = useStorage('formschema');

  const [itemList, setItemList] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);

  const primaryKey = useMemo(() => {
    const schema = endpointToSchema(endpoint);
    const ConfigSchema = (
      formschema?.components?.schemas[schema] as SchemaProperties | undefined
    )?.properties;
    return getPrimaryKeyFieldsFunc(ConfigSchema);
  }, [itemList]);

  useEffect(() => {
    if (primaryKey) {
      const sorted = [...itemList].sort((a, b) =>
        a[primaryKey].toLowerCase() > b[primaryKey].toLowerCase() ? 1 : -1
      );
      setUpdatedItems([...sorted]);
    }
  }, [itemList, primaryKey]);

  const {
    data: dataConfigList,
    isLoading,
    isError,
    error,
  } = useGetConfigList({
    endpoint,
  });
  useEffect(() => {
    if (!isLoading && dataConfigList?.data) {
      setItemList(dataConfigList.data);
    }
  }, [dataConfigList, isLoading]);

  useEffect(() => {
    if (isError) {
      setErrMsg(`${error}`);
    } else {
      setErrMsg('');
    }
  }, [error, isError]);
  return (
    <Box sx={{ mb: 3 }}>
      <div>
        <Autocomplete
          disablePortal
          id="relativeTable"
          freeSolo={enableCustomValue}
          options={updatedItems.map((x) => x[primaryKey])}
          sx={{ width: 300, mt: 1 }}
          value={val || null}
          renderInput={(params) => (
            <TextField {...params} label={label} required={required} />
          )}
          onInputChange={(event, newInputValue) => {
            if (newInputValue !== '[object Object]' && enableCustomValue) {
              onChange(newInputValue);
            }
          }}
          onChange={(event, value) => {
            if (name === 'applications') {
              updatedItems.map((x) => {
                if (x.applicationId == value) {
                  updateSelected({ [x.applicationId]: x.appInfo });
                }
              });
            } else {
              onChange(value ?? '');
            }
          }}
          style={{ padding: 0 }}
        />
      </div>
    </Box>
  );
};

export default ComboBox;
