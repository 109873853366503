import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const MainIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    fill: colorsTheme.policeBlue,
  };
  const styleCls1: CSSProperties = {
    fill: colorsTheme.white,
  };
  return (
    <svg
      style={style}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 55.6 48.1"
      xmlSpace="preserve"
    >
      <title>Config Icon</title>
      <g>
        <g>
          <path
            style={styleCls}
            d="M49.9,31H45L43.4,27c-0.2-0.5-0.7-0.8-1.2-0.7c-0.5,0.1-0.9,0.4-1,0.9l-1.3,7.4l-1.2-2.9
			c-0.2-0.4-0.6-0.7-1.1-0.7h-2.8c-0.1,0-0.1,0-0.2,0c-1.6,0-3.3,0-4.9,0c-0.2,0-0.3-0.1-0.4-0.3c-0.3-0.7-0.9-1.3-1.6-1.6
			c-0.2-0.1-0.2-0.2-0.2-0.3c0-1,0-1.9,0-2.9c0-0.2,0.1-0.3,0.3-0.3c0.7,0,1.4,0,2.1,0c0.6,0,1.1,0,1.7,0c2.6-0.3,4.5-2.6,4.2-5.2
			c-0.2-2-1.3-3.3-3.1-4c-0.2-0.1-0.3-0.2-0.4-0.4c-1.3-2.5-4-3.9-6.8-3.5c-2.8,0.4-5,2.5-5.6,5.3c0,0.2-0.1,0.3-0.3,0.3
			c-1.5,0.5-2.7,2.1-2.5,4c0.2,2,1.8,3.5,3.8,3.5c1.4,0,2.7,0,4.1,0c0.2,0,0.3,0.1,0.3,0.3c0,1,0,1.9,0,2.9c0,0.2-0.1,0.3-0.2,0.4
			c-0.7,0.3-1.3,0.9-1.6,1.6c-0.1,0.2-0.2,0.3-0.4,0.3c-0.6,0-1.2,0-1.8,0c-0.1,0-0.1,0-0.2,0H16L14.3,27c-0.2-0.5-0.7-0.8-1.2-0.7
			c-0.5,0.1-0.9,0.4-1,0.9l-1.3,7.4l-1.2-2.9C9.5,31.3,9,31,8.6,31H5.7c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h2.1l2.4,5.8
			c0.2,0.4,0.6,0.7,1.1,0.7c0,0,0.1,0,0.1,0c0.5-0.1,0.9-0.4,1-0.9l1.3-7.4l0.5,1.2c0.2,0.4,0.6,0.7,1.1,0.7h1.2c0,0,0.1,0,0.1,0
			c1.1,0,2.1,0,3.2,0c1,0,2.1,0,3.1,0c0.2,0,0.3,0.1,0.4,0.3c0.6,1.2,1.6,1.9,3,1.9c1.3,0,2.4-0.6,3-1.9c0.1-0.2,0.2-0.3,0.4-0.3
			c2.1,0,4.1,0,6.2,0c0.1,0,0.2,0,0.3,0h0.8l2.4,5.8c0.2,0.4,0.6,0.7,1.1,0.7c0,0,0.1,0,0.1,0c0.5-0.1,0.9-0.4,1-0.9l1.3-7.4
			l0.5,1.2c0.2,0.4,0.6,0.7,1.1,0.7h5.6c0.6,0,1.1-0.5,1.1-1.1C51,31.5,50.5,31,49.9,31z M26.3,33.2c-0.6,0-1.1-0.5-1.1-1
			c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C27.3,32.8,26.9,33.2,26.3,33.2z"
          />
          <path
            style={styleCls}
            d="M48.4,45.3H7.2c-2.5,0-4.6-2.1-4.6-4.6V12.6C2.6,10.1,4.7,8,7.2,8h41.2c2.5,0,4.6,2.1,4.6,4.6v28.1
			C53,43.2,50.9,45.3,48.4,45.3z M7.2,10.9c-0.9,0-1.7,0.8-1.7,1.7v28.1c0,0.9,0.8,1.7,1.7,1.7h41.2c0.9,0,1.7-0.8,1.7-1.7V12.6
			c0-0.9-0.8-1.7-1.7-1.7H7.2z"
          />
          <path
            style={styleCls}
            d="M33.6,10.9H20.2c-1.2,0-2.1-1-2.1-2.1V4.9c0-1.2,1-2.1,2.1-2.1h13.4c1.2,0,2.1,1,2.1,2.1v3.9
			C35.7,10,34.7,10.9,33.6,10.9z M20.9,8h11.9V5.7H20.9V8z"
          />
        </g>
        <g>
          <path
            style={styleCls1}
            d="M23.5,20.5c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2
			H29c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2H23.5z M26.3,23.2
			c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6v-5.2c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.2,0.6-0.2c0.3,0,0.5,0.1,0.6,0.2
			c0.2,0.2,0.2,0.4,0.2,0.6v5.2c0,0.2-0.1,0.5-0.2,0.6C26.7,23.1,26.5,23.2,26.3,23.2z"
          />
        </g>
      </g>
    </svg>
  );
};
