/* eslint-disable */

import React, { useEffect, useRef, memo } from 'react';
import { JsonEditor as JsonEditorWrapper } from 'jsoneditor-react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';

import 'jsoneditor-react/es/editor.min.css';
import ThemedButton from './ThemedButton';
import { colorsTheme } from '../constants';

export type ControlledJsonEditorProps<T> = {
  value: T;
  mode?: string;
  onChange?: (value: T) => void;
  schema?: any;
  schemaRefs?: any;
  buttons?: boolean;
  submitFunc?: (value: T) => void;
  onCancel?: () => void;
  actionType?: 'edit' | 'create';
  copy?: boolean;
};

const RawJsonEditor: React.FC<ControlledJsonEditorProps<unknown>> = ({
  value,
  onChange,
  schema,
  buttons,
  submitFunc,
  onCancel,
  actionType,
  copy = false,
  ...props
}) => {
  const jsonEditorRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    const editor: any =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current['jsonEditor'];
    if (
      editor &&
      editor instanceof Object &&
      Object.keys(editor).length &&
      value
    ) {
      editor.update(value);
    }
  }, [jsonEditorRef, value]);

  const htmlElementProps = {
    className: pathname.split('/').includes('executeworkflows')
      ? 'my-json-editor-2'
      : 'my-json-editor-1',
  };

  return (
    <>
      <JsonEditorWrapper
        onChange={onChange}
        ref={jsonEditorRef}
        search={false}
        navigationBar={false}
        statusBar={false}
        sortObjectKeys={true}
        history={false}
        name={''}
        value={value}
        mode={'code'}
        allowedModes={['code', 'tree', 'text']}
        htmlElementProps={htmlElementProps}
      />

      {buttons && (
        <Grid container direction="row" justifyContent="center">
          <Box sx={{ mt: 2, position: 'fixed', bottom: 50 }}>
            <ThemedButton
              type="submit"
              size="large"
              onClick={() => submitFunc({ formData: value })}
              sx={{
                backgroundColor: 'primary',
                borderRadius: '20px 20px 20px 20px',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'primary',
                },
                color: colorsTheme.white,
                width: { lg: '300px', md: '200px', sm: '100px', xs: '50px' },
                fontSize: '1.1rem',
              }}
            >
              {actionType === 'edit'
                ? 'Update'
                : actionType === 'create' && copy
                ? 'Add to folder'
                : 'Create'}
            </ThemedButton>

            <ThemedButton
              onClick={onCancel}
              size="large"
              sx={{
                backgroundColor: colorsTheme.auroMetalSaurus,
                borderRadius: '20px 20px 20px 20px',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: colorsTheme.auroMetalSaurus,
                },
                color: colorsTheme.white,
                ml: 2,
                width: { lg: '300px', md: '200px', sm: '100px', xs: '50px' },
                fontSize: '1.1rem',
              }}
            >
              Cancel
            </ThemedButton>
          </Box>
        </Grid>
      )}
    </>
  );
};

type OwnProps = Partial<ControlledJsonEditorProps<object>> & {
  controlName?: string;
  control: any;
};

const ControlledJsonEditor: React.FC<OwnProps> = memo(
  ({ controlName = 'schema', control, value, ...props }: OwnProps) => (
    <Controller
      name={controlName}
      render={({ field: { onChange, value } }) => (
        <RawJsonEditor {...props} value={value} onChange={onChange} />
      )}
      control={control}
      defaultValue={value}
    />
  )
);

ControlledJsonEditor.displayName = 'ControlledJsonEditor';

export { ControlledJsonEditor, RawJsonEditor };
