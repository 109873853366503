import React from 'react';
import { Controller } from 'react-hook-form';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import { colorsTheme } from '../constants';

type OwnProps = {
  controlName: string;
  label?: string;
  control;
  value?: unknown;
  title?: string;
} & TextareaAutosizeProps;

const DivStyle = { backgroundColor: colorsTheme.bleuDeFrance, height: '30px' };
const BoldStyle = {
  color: colorsTheme.white,
  display: 'block',
  paddingTop: '1%',
  paddingLeft: '2%',
};

const ControlledTextArea = ({
  controlName,
  value,
  style,
  control,
  title,
  ...props
}: OwnProps) => (
  <Controller
    name={controlName}
    render={({ field: { onChange, value } }) => (
      <div className="textarea-container">
        {title && (
          <div style={DivStyle}>
            <b style={BoldStyle}>{title}</b>
          </div>
        )}
        <TextareaAutosize
          onInput={(item) => onChange(item)}
          value={value && value.length > 0 ? value : ''}
          style={style}
          minRows={35}
          placeholder=" Enter your payload.  "
          className="executorClass"
          {...props}
        />
      </div>
    )}
    control={control}
    defaultValue={value}
  />
);

export { ControlledTextArea };
