import Box from '@mui/material/Box';

export const ResizeLine = () => {
  return (
    <Box
      sx={{
        width: '0.5rem',
        height: '100%',
        '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.00)',
        backgroundColor: 'rgba(0,0,0,.05)',
        '&:hover': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey',
        },
      }}
    />
  );
};
