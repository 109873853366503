import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Caption } from './Typography';
import React, { ReactNode, useState } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { colorsTheme } from '../constants';

type OwnProps = {
  headers: {
    label: string;
    sortable: boolean;
    onSort?: (
      order: boolean,
      column: string,
      data: Record<string, unknown>[]
    ) => void;
  }[];
  actions: {
    icon?: unknown;
    label: string;
    onClick?: (record: Record<string, unknown>) => void;
  }[];
  data: Record<string, unknown>[];
  errorMessage?: string;
  renderRow: (record: Record<string, unknown>) => ReactNode;
};

const ControlledTable: React.FC<OwnProps> = ({
  headers,
  data,
  renderRow,
  actions,
  errorMessage,
}) => {
  const [order, setOrder] = useState(false);
  const [selected, setSelected] = useState('');

  return data ? (
    <TableContainer>
      <Table aria-label="table">
        <TableHead sx={{ '& th': { color: 'primary', p: 1 } }}>
          <TableRow>
            {headers &&
              headers.map((i, inx) => (
                <TableCell key={inx}>
                  <Button
                    onClick={() => {
                      i.onSort(order, i.label, data);
                      setSelected(i.label);
                      setOrder(!order);
                    }}
                    endIcon={
                      i.sortable &&
                      selected == i.label &&
                      (!order ? (
                        <KeyboardDoubleArrowUpIcon />
                      ) : (
                        <KeyboardDoubleArrowDownIcon />
                      ))
                    }
                    style={{
                      position: 'inherit',
                    }}
                  >
                    <Caption bold color="primary">
                      {i.label}
                    </Caption>
                  </Button>
                </TableCell>
              ))}
            {actions && actions.length > 0 && (
              <TableCell>
                <Caption bold textTransform="uppercase" color="primary">
                  Action
                </Caption>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody sx={{ '& td': { color: 'text.primary', padding: 1 } }}>
          {data &&
            data.map((i, indx) => (
              <TableRow
                key={indx}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'rgb(173, 170, 170,0.3)',
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: '',
                  },
                  height: '3rem',
                }}
              >
                {renderRow(i)}

                {actions && actions.length > 0 && (
                  <TableCell sx={{ minWidth: 200 }}>
                    {actions.map((btn, index) => (
                      <Button
                        key={index}
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        onClick={() => btn.onClick(i)}
                      >
                        {btn.icon as ReactNode}
                        <Caption bold>{btn.label}</Caption>
                      </Button>
                    ))}
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box
      style={{
        fontWeight: 'bold',
        textAlign: 'center',
        color: colorsTheme.grey,
      }}
    >
      {errorMessage || '- NO DATA -'}
    </Box>
  );
};

export default ControlledTable;
