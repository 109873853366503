import { useMemo } from 'react';

const useSearch = <T>(data: { data: T[] }, searchTerm: string) => {
  const results = useMemo(
    () =>
      data &&
      Object.keys(data).length &&
      data.data instanceof Array &&
      data.data.filter((val) => {
        if (searchTerm === '') {
          return val;
        } else if (
          String(Object.values(val))
            .toLowerCase()
            .replace(/\s/g, '')
            .replace(/-/g, '')
            .includes(
              (searchTerm as string)
                .toLowerCase()
                .replace(/\s/g, '')
                .replace(/-/g, '')
            )
        ) {
          return val;
        }
      }),
    [data]
  );

  return {
    results,
  };
};

export default useSearch;
