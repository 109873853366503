import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ThemedButton from '../../../components/ThemedButton';
import { Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth } from '../../../hooks/useAuth';
import signupSchema from './schema';
import ControlledTextField from '../../../components/form/widgets/ControlledTextField';
import useCustomForm from '../../../components/form';
import styles from '../SignIn/style';
import {
  SubTitle,
  BannerTitle,
  Para,
  Margin,
  styles as TypographyStyles,
} from '../../../components/Typography';
import SignupConfirmationCode from '../../../modals/SignupConfirmationCode';
import { SignUpForm } from '../../../types/SignUp';
import { Logo } from '../../../components/Logo';

const SignUp = () => {
  const {
    signUp,
    user,
    verificationBoxopen,
    setVerificationBoxopen,
    verifyUserAccount,
    mail,
    setMail,
    success,
    setsuccess,
  } = useAuth();

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useCustomForm(
    {
      displayName: '',
      email: '',
      password: '',
    },
    signupSchema
  );

  const handleData = (data: SignUpForm) => {
    setMail(data.email);
    signUp(data);
  };
  const close = () => {
    setVerificationBoxopen(false);
  };

  useEffect(() => {
    if (!user) {
      navigate('/sign-up');
    }
    if (success) {
      setsuccess(false);
    }
  }, [user, success]);
  const classes = styles();

  const typographyStyles = TypographyStyles();

  return (
    <Grid item xs={12} md={5} lg={5} className={classes.loginForm}>
      <form className={classes.form} onSubmit={handleSubmit(handleData)}>
        <BannerTitle>Welcome</BannerTitle>
        <Margin />
        <SubTitle bold={true}>Create new account</SubTitle>

        <Margin />
        <Margin />

        <ControlledTextField
          placeholder="Enter your fullname"
          type="displayName"
          name="displayName"
          label="Fullname"
          fullWidth
          control={control}
          errors={errors}
        />

        <Margin />

        <ControlledTextField
          placeholder="e.g. username@domain.com"
          type="email"
          name="email"
          label="Email address"
          fullWidth
          control={control}
          errors={errors}
        />

        <Margin />

        <ControlledTextField
          placeholder="Password"
          type={'password'}
          fullWidth
          label="Password"
          name="password"
          control={control}
          errors={errors}
          ActiveIcon={Visibility}
          InActiveIcon={VisibilityOff}
        />

        <Margin />

        <Box textAlign={'center'} className={typographyStyles.mt5}>
          <ThemedButton>Sign Up</ThemedButton>
        </Box>

        <Box textAlign={'center'} className={typographyStyles.mt5}>
          <Para className={classes.inline} bold={true}>
            I already have an account?&nbsp;
            <Link to="/">
              <Para bold={true} color="primary">
                Sign-in Here
              </Para>
            </Link>
          </Para>
        </Box>
        <Logo
          style={{
            width: '100%',
          }}
        />
      </form>

      <SignupConfirmationCode
        close={close}
        isOpen={verificationBoxopen}
        verifyUserAccount={verifyUserAccount}
        mail={mail}
      />
    </Grid>
  );
};
export default SignUp;
