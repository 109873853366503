import { useAtom } from 'jotai';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IChangeEvent } from '@rjsf/core';
import { FormContextType, RJSFSchema } from '@rjsf/utils';

import {
  WorkflowConfig,
  FormConfigs,
} from '../../../models/workFlowTree/types';
import { useAuth } from '../../../hooks/useAuth';
import {
  configFormNormalization,
  initializationFormConfig,
  endpointToSchema,
} from '../../../models/workFlowTree/helpers';
import {
  currentConfigIsEditAtom,
  currentConfigWorkflowAtom,
} from '../../../models/workFlowTree/state';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { ConfigForm } from '../../../components/form/configForm/ConfigForm';
import { useStorage } from '../../../hooks/useStorage';
import { getPrimaryKeyFieldsFunc } from '../../../hooks/misc';
import { SchemaProperties } from '../../../models/configuration/types';
import { useGetConfigById } from '../../../hooks/api/queries';

type FormData = IChangeEvent<FormConfigs, RJSFSchema, FormContextType>;

type ConfigurationShowProps = {
  workflow: WorkflowConfig;
  onCancel: () => void;
  onChangeForm: (formData: FormConfigs) => void;
  onSubmitForm: (formData: FormConfigs) => void;
};

export const ConfigurationShow = ({
  workflow,
  onCancel,
  onChangeForm,
  onSubmitForm,
}: ConfigurationShowProps) => {
  const navigate = useNavigate();
  const { confirmation } = useConfirmationDialog();

  const endpoint = workflow.typeConfig;
  const schema = endpointToSchema(endpoint);

  const isCreate = workflow.localCreate === true;
  const setCurrentConfig = useAtom(currentConfigWorkflowAtom)[1];
  const setCurrentConfigIsEdit = useAtom(currentConfigIsEditAtom)[1];

  const { res, setres } = useAuth();
  const [formDataRes, setFormDataRes] = useState<FormConfigs | null>(null);

  const [enabledGetConfigById, setEnabledGetConfigById] = useState(false);
  const { data: dataConfig, isLoading } = useGetConfigById({
    endpoint,
    configId: workflow.configId,
    queryParams: {
      enabled: enabledGetConfigById,
      refetchOnWindowFocus: false,
    },
  });

  const { value: formschema } = useStorage('formschema');
  useEffect(() => {
    if (enabledGetConfigById && !isLoading && dataConfig) {
      setFormDataRes(
        configFormNormalization(endpoint, formschema, dataConfig.data)
      );
    }
  }, [enabledGetConfigById, dataConfig, isLoading, formschema]);

  useLayoutEffect(() => {
    setCurrentConfigIsEdit(['', false]);
    setres(false);
    if (isCreate && workflow.copy !== true) {
      const data = initializationFormConfig(
        endpoint,
        primaryKeyField,
        schema,
        workflow.configId
      );
      setFormDataRes(data);
    } else {
      setEnabledGetConfigById(true);
    }
  }, [
    isCreate,
    endpoint,
    workflow,
    setCurrentConfigIsEdit,
    setres,
    setFormDataRes,
  ]);

  useEffect(() => {
    return () => {
      setCurrentConfig(false);
      setCurrentConfigIsEdit(['', false]);
    };
  }, []);

  const handelChange = useCallback(
    ({ formData, edit }: FormData) => {
      setres(!!edit);
      setCurrentConfigIsEdit([workflow.configId, !!edit]);
      if (formData) {
        onChangeForm(formData);
      }
    },
    [workflow.configId, setres, setCurrentConfigIsEdit]
  );

  const handleSuccess = useCallback(
    ({ formData }: FormData) => {
      setCurrentConfigIsEdit(['', false]);
      onSubmitForm(formData);
    },
    [onSubmitForm, setCurrentConfigIsEdit]
  );

  const handelCancel = useCallback(async () => {
    if (res) {
      const confirmationBack = await confirmation();
      if (confirmationBack) {
        setres(false);
        onCancel();
        navigate('/collections');
      }
    } else {
      setres(false);
      onCancel();
      navigate('/collections');
    }
  }, [res, setres, onCancel]);

  const ConfigSchema = (
    formschema?.components?.schemas[schema] as SchemaProperties | undefined
  )?.properties;
  const primaryKeyField: string = getPrimaryKeyFieldsFunc(ConfigSchema);

  return (
    <ConfigForm
      formData={formDataRes}
      onSuccess={handleSuccess}
      configId={workflow?.configId}
      onClose={handelCancel}
      onChange={handelChange}
      actionType={isCreate ? 'create' : 'edit'}
      endpoint={endpoint}
      copy={!!workflow.copy}
    />
  );
};
