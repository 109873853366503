import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';

type License = {
  licenseName: string;
  expirationDate: string;
  entitlements: string[];
  allEntitlments: string[];
  hdcVersion: string | null;
};

type ResponseLicense = {
  data: License;
  success: boolean;
  msg?: string;
};
type UseGetLicenseParams = {
  queryParams?: UseQueryOptions<ResponseLicense>;
};

export const useGetLicense = ({ queryParams }: UseGetLicenseParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useQuery<ResponseLicense>({
    ...queryParams,
    queryKey: ['get-license'],
    queryFn: () => {
      return axios({
        url: '/api/hdcinformation/@license',
        method: 'GET',
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseLicense;
        }
        return Promise.reject(
          response.data.msg ?? 'License load success false'
        );
      });
    },
  });
};
