import React, { useEffect, useMemo, useState, memo, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// pages
import Home from './pages/home';
import ConfigType from './pages/configuration/types';
import ConfigList from './pages/configuration/list';
import ConfigDetail from './pages/configuration/detail';
import NotFound from './pages/Error404Page';
import { WorkflowList } from './pages/workflows';
import SignInAmazon from './pages/auth/SignIn';
import SignInOnPrem from './pages/auth/SignInOnPrem/index';
import SignUpAmazon from './pages/auth/SignUp';
import SignUpOnPrem from './pages/auth/SignUpOnPrem/index';
import { useEnvironment } from './hooks/useEnvironment';
import { AxiosContext } from './hoc/withAxios';
import { useStorage } from './hooks/useStorage';
import useIdleDetection from './hooks/useIdleDetection';
import SessionAlert from './components/SessionAlert';
import AuthLayout from './container/AuthLayout';
import FullLayout from './container/FullLayout';
import ExecuteWorkFlowWrapper from './pages/executeworkflows';
import HelpGuidePdfPage from './pages/helpGuidePdf';
import { AboutPage } from './pages/about';
import { useGetStatusCheck } from './hooks/api/queries';

enum HDCStatusType {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
}

const AuthenticatedRouteContext = React.createContext({
  schema: {},
  status: HDCStatusType.PENDING,
});

const AuthenticatedRouteContextProvider: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const [schema, setSchema] = useState<unknown>();
  const [status, setStatus] = useState<HDCStatusType>(HDCStatusType.PENDING);
  const { showToast } = useContext(AxiosContext);
  const { setValue } = useStorage('formschema');
  const { environment } = useEnvironment();
  const [enabledeStatusCheck, setEnabledeStatusCheck] = useState(false);
  const { data: dataStatusCheck, isLoading } = useGetStatusCheck({
    queryParams: {
      enabled: enabledeStatusCheck,
    },
  });

  useEffect(() => {
    setEnabledeStatusCheck(true);
  }, []);
  useEffect(() => {
    if (!isLoading && dataStatusCheck) {
      if (dataStatusCheck.success) {
        setStatus(HDCStatusType.SUCCESS);
      } else {
        showToast({
          show: true,
          message: dataStatusCheck.msg,
          severity: 'error',
        });
        setStatus(HDCStatusType.FAILED);
      }
      setValue(dataStatusCheck.schema);
      setSchema(dataStatusCheck.schema);
    }
  }, [isLoading, dataStatusCheck]);

  if (!environment.onprem) {
    const { idle, getRemainingTime, setShowPrompt, reset, expiringTime } =
      useIdleDetection();

    return (
      <AuthenticatedRouteContext.Provider value={{ schema, status }}>
        {idle && (
          <SessionAlert
            getRemainingTime={getRemainingTime}
            expiringTime={expiringTime}
            reset={reset}
            onClose={() => setShowPrompt(false)}
          />
        )}
        {children}
      </AuthenticatedRouteContext.Provider>
    );
  }

  return (
    <AuthenticatedRouteContext.Provider value={{ schema, status }}>
      {children}
    </AuthenticatedRouteContext.Provider>
  );
};

const NoAuth = memo(() => {
  const { environment } = useEnvironment();

  const signIn = environment.onprem ? <SignInOnPrem /> : <SignInAmazon />;
  const signUp = environment.onprem ? <SignUpOnPrem /> : <SignUpAmazon />;
  return (
    <AuthLayout>
      <Routes>
        <Route index={true} element={signIn} />
        <Route path="/sign-up" element={signUp} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </AuthLayout>
  );
});

NoAuth.displayName = 'NoAuth';

const Authenticated = memo(() => (
  <FullLayout>
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/configuration" element={<ConfigType />} />
      <Route path="/configuration/:endpoint" element={<ConfigList />} />
      <Route
        path="/configuration/:endpoint/create"
        element={<ConfigDetail />}
      />
      <Route
        path="/configuration/:endpoint/edit/*"
        element={<ConfigDetail />}
      />
      <Route path="/collections" element={<WorkflowList />} />
      <Route path="/collections/:configId" element={<WorkflowList />} />
      <Route path="/executeworkflows" element={<ExecuteWorkFlowWrapper />} />
      <Route path="/helpGuidePdf" element={<HelpGuidePdfPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  </FullLayout>
));

Authenticated.displayName = 'Authenticated';

const Navigation: React.FC<unknown> = () => {
  const { value: user } = useStorage('user');

  const memorizedOutlet = useMemo(
    () =>
      !user ? (
        <NoAuth />
      ) : (
        <AuthenticatedRouteContextProvider>
          <Authenticated />
        </AuthenticatedRouteContextProvider>
      ),
    [user]
  );

  return memorizedOutlet;
};

export default Navigation;
export { AuthenticatedRouteContext, HDCStatusType };
