import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { FormConfigs } from '../../../models/workFlowTree/types';

type ResponseCreateConfig = {
  data: FormConfigs;
  success: boolean;
  message?: string;
};
type UseCreateConfigValiables = {
  endpoint: string;
  formData: FormConfigs;
};
type UseCreateConfigParams = {
  mutationParams?: UseMutationOptions<
    ResponseCreateConfig,
    unknown,
    UseCreateConfigValiables
  >;
};

export const useCreateConfig = ({
  mutationParams,
}: UseCreateConfigParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['config-create'],
    mutationFn: ({ endpoint, formData }: UseCreateConfigValiables) => {
      return axios({
        url: `/api/${endpoint}`,
        method: 'POST',
        data: formData,
      })
        .then((response) => {
          if (response.data.success) {
            return response.data as ResponseCreateConfig;
          }
          return Promise.reject(
            response.data.msg ?? 'create config success false'
          );
        })
        .catch((response) => {
          return Promise.reject(response.response.data);
        });
    },
  });
};
