import Button, { ButtonProps } from '@mui/material/Button';
import { memo, useMemo } from 'react';
import { SxProps, useTheme } from '@mui/material/styles';
import { colorsTheme } from '../constants';

type OwnProps = Partial<ButtonProps> & {
  negative?: boolean;
};

const ThemedButton: React.FC<OwnProps> = memo(
  ({ children, sx, color = 'primary', negative, ...props }) => {
    const theme = useTheme();

    const style: SxProps = useMemo(
      () => ({
        borderRadius: '20px 20px 20px 20px',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        minWidth: '150px',
        '&.MuiButton-contained:not(.Mui-disabled)': {
          backgroundColor: negative
            ? colorsTheme.grey
            : theme.palette[color].dark,
          color: colorsTheme.white,
        },
        '&.MuiButton-contained:hover:not(.Mui-disabled)': {
          bgcolor: colorsTheme.white,
          color: negative ? colorsTheme.grey : theme.palette[color].dark,
          border: `1px solid ${
            negative ? colorsTheme.grey : theme.palette[color].dark
          }`,
        },
        '&.MuiButton-outlined:not(.Mui-disabled)': {
          backgroundColor: colorsTheme.white,
          color: negative ? colorsTheme.grey : theme.palette[color].dark,
          border: `1px solid ${
            negative ? colorsTheme.grey : theme.palette[color].dark
          }`,
        },
        '&.MuiButton-outlined:hover:not(.Mui-disabled)': {
          bgcolor: negative ? colorsTheme.grey : theme.palette[color].dark,
          color: colorsTheme.white,
        },
      }),
      [theme, negative]
    );

    const btnStyle = useMemo(
      () => ({
        ...sx,
        ...style,
      }),
      [style]
    );

    return (
      <Button
        type="submit"
        size="large"
        variant="contained"
        sx={btnStyle}
        {...props}
      >
        {children}
      </Button>
    );
  }
);

ThemedButton.displayName = 'ThemedButton';

const ThemedOutlinedButton: React.FC<OwnProps> = memo(
  ({ children, ...props }) => {
    return (
      <ThemedButton {...props} variant="outlined">
        {children}
      </ThemedButton>
    );
  }
);

ThemedOutlinedButton.displayName = 'ThemedOutlinedButton';

export default ThemedButton;
export { ThemedOutlinedButton };
