const sortData = (
  col: string,
  order: boolean,
  ItemData: { data: unknown[]; extra: { column: unknown } },
  callback: (ItemData: { data: unknown[] }, order: boolean) => void
) => {
  if (order) {
    const sorted = [...ItemData.data].sort((a, b) => {
      if (!a[col]) {
        return 1;
      }

      if (!b[col]) {
        return -1;
      }

      if (a[col].toLowerCase() === b[col].toLowerCase()) {
        return 0;
      }

      return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1;
    });
    return callback({ ...ItemData, data: sorted }, false);
  }
  if (!order) {
    const sorted = [...ItemData.data].sort((a, b) => {
      if (!a[col]) {
        return 1;
      }

      if (!b[col]) {
        return -1;
      }

      if (a[col].toLowerCase() === b[col].toLowerCase()) {
        return 0;
      }

      return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1;
    });
    return callback({ ...ItemData, data: sorted }, true);
  }
};

export { sortData };
