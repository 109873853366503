import React, { useState, ChangeEvent } from 'react';
import { TextField, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CreateConfigAction } from '../../types';
import { useGetConfigByIdLazy } from '../../../../hooks/api/queries';

type TemplateConfigurateProps = {
  configType: string;
  onChange: (idConfig: string, typeAction: CreateConfigAction) => void;
};

export const TemplateConfigurate = ({
  configType,
  onChange,
}: TemplateConfigurateProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [templateId, setTemplateId] = useState('');
  const getConfigByIdLazy = useGetConfigByIdLazy();

  const handelChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setTemplateId(event.target.value);
  };
  const handelClickAdd = () => {
    setError(null);
    if (templateId.length > 0) {
      setIsLoading(true);
      getConfigByIdLazy({
        configId: templateId,
        endpoint: configType,
      })
        .then(() => {
          onChange(templateId, 'create-template');
        })
        .catch(() => {
          setError('Not found');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError('required');
    }
  };
  const handelClickCreate = () => {
    onChange(templateId, 'create');
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{ width: '100%', px: 1 }}
    >
      <TextField
        name="templateId"
        value={templateId}
        onChange={handelChangeInput}
        required
        size="small"
        error={!!error}
        helperText={error}
      />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={handelClickAdd}
      >
        Add
      </LoadingButton>
      {error === 'Not found' && (
        <Button onClick={handelClickCreate}>Create</Button>
      )}
    </Stack>
  );
};
