import React from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type ControlledAutoCompleteOption = { id: string; label: string };

type AutoCompleteOption<T> = T & ControlledAutoCompleteOption;

type OwnProps = {
  controlName: string;
  options: ControlledAutoCompleteOption[];
  label?: string;
  control;
  value?: unknown;
};

const ControlledAutoComplete = ({
  controlName,
  options,
  value,
  control,
  ...props
}: OwnProps) => (
  <Controller
    name={controlName}
    render={({ field: { onChange, value } }) => (
      <Autocomplete
        options={options}
        onChange={(e, item) => onChange(item)}
        value={value}
        renderInput={(params) => <TextField {...params} label={props.label} />}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        {...props}
      />
    )}
    control={control}
    defaultValue={value}
  />
);

export { ControlledAutoComplete };
export type { AutoCompleteOption };
