import { CSSProperties, ReactNode } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import FolderIcon from '@mui/icons-material/Folder';
import Help2Icon from '@mui/icons-material/Help';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import {
  ConfigurationIcon,
  DocIcon,
  HelpIcon,
  LeftBarIcon,
  WorkflowsIcon,
} from '../components/Icons';

export type MenuItem = {
  name: string;
  route: string;
  icon: ((style?: CSSProperties) => ReactNode) | null;
  optIcon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
    muiName: string;
  };
  targetBlank?: boolean;
};

const menus: MenuItem[] = [
  {
    name: 'Home',
    route: '/',
    icon: (style) => <LeftBarIcon style={style} />,
  },
  {
    name: 'Collections',
    route: '/collections',
    optIcon: FolderIcon,
    icon: null,
  },
  {
    name: 'Configuration',
    route: '/configuration',
    icon: (style) => <ConfigurationIcon style={style} />,
  },
  {
    name: 'Execute Workflows',
    route: '/executeworkflows',
    icon: (style) => <WorkflowsIcon style={style} />,
  },
];

const bottomMenus: MenuItem[] = [
  {
    name: 'About',
    route: '/about',
    icon: (style) => <HelpIcon style={style} />,
    targetBlank: false,
  },
  {
    name: 'Help & Guide',
    route: '/helpGuidePdf',
    icon: (style) => (
      <Help2Icon
        sx={{
          ...style,
          color: 'white',
        }}
        fontSize="large"
      />
    ),
    targetBlank: false,
  },
  {
    name: 'Swagger Doc',
    route:
      'https://nordicwi.atlassian.net/wiki/external/573440003/YmM1ODE5ZWExM2FiNGYwODk3OTExZTJlMjMxYWJjMDg?atlOrigin=eyJpIjoiMzdkMTliODQ3NzYzNGUxYTkwY2NkMmVjZTljMWE3ZDciLCJwIjoiYyJ9',
    icon: (style) => <DocIcon style={style} />,
    targetBlank: true,
  },
];

export { menus, bottomMenus };
