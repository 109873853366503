import { CSSProperties } from 'react';

import icon1 from '../assets/icons/Config-01.png';
import icon2 from '../assets/icons/Config-02.png';
export { ApisConfigIcon } from '../assets/icons/Config-03';
export { KeymanagementConfigIcon } from '../assets/icons/Config-11';
export { TemplatesConfigIcon } from '../assets/icons/Config-07';
export { Hl7messagetemplateConfigIcon } from '../assets/icons/Config-09';
export { KmslocalkeyConfigIcon } from '../assets/icons/Config-10';
export { SecretsConfigIcon } from '../assets/icons/Config-12';
export { DataOperationConfigIcon } from '../assets/icons/Config-13';
export { StoredProceduresConfigIcon } from '../assets/icons/Config-14';
export { GenericConfigurationConfigIcon } from '../assets/icons/Config-15';
export { LeftBarIcon } from '../assets/icons/icon-3';
export { ConfigurationIcon } from '../assets/icons/icon-4';
export { HelpIcon } from '../assets/icons/icon-5';
export { DocIcon } from '../assets/icons/icon-6';
export { WorkflowsIcon } from '../assets/icons/icon-7';
export { LockIcon } from '../assets/icons/lock';
export { CautionIcon } from '../assets/icons/caution';
export { MainIcon } from '../assets/icons/main';
export { SearchIcon } from '../assets/icons/search';
export { DefaultConfigIcon } from '../assets/icons/Default-Config-Icon';
import icon4 from '../assets/icons/Config-04.png';
import icon5 from '../assets/icons/Config-05.png';
import icon6 from '../assets/icons/Config-06.png';
import icon8 from '../assets/icons/Config-08.png';

type IconsProps = {
  style?: CSSProperties;
};

export const ApplicationsConfigIcon = ({ style }: IconsProps) => {
  return <img src={icon1} style={style} />;
};

export const ConnectionsConfigIcon = ({ style }: IconsProps) => {
  return <img src={icon2} style={style} />;
};

export const WorkflowStepsConfigIcon = ({ style }: IconsProps) => {
  return <img src={icon4} style={style} />;
};

export const TransformsConfigIcon = ({ style }: IconsProps) => {
  return <img src={icon5} style={style} />;
};

export const WorkflowsConfigIcon = ({ style }: IconsProps) => {
  return <img src={icon6} style={style} />;
};

export const RulesConfigIcon = ({ style }: IconsProps) => {
  return <img src={icon8} style={style} />;
};
