import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { ServerDataFolder } from '../../../models/workFlowTree/types';

type ResponseEditFolderById = {
  data: ServerDataFolder;
  success: boolean;
  message?: string;
};
type UseEditFolderByIdValiables = {
  folderId: string;
  data: ServerDataFolder;
};
type UseEditFolderByIdParams = {
  mutationParams?: UseMutationOptions<
    ResponseEditFolderById,
    unknown,
    UseEditFolderByIdValiables
  >;
};

export const useEditFolderById = ({
  mutationParams,
}: UseEditFolderByIdParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['config-folder'],
    mutationFn: ({ data, folderId }: UseEditFolderByIdValiables) => {
      return axios({
        url: `/api/folders/@${folderId}`,
        method: 'PUT',
        data: data,
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseEditFolderById;
        }
        return Promise.reject(
          response.data.msg ?? 'update folder success false'
        );
      });
    },
  });
};
