import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const DocIcon = ({ style }: IconsProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <title>Left bar icons</title>
      <path
        d="M5.24,16c0-3.51,0-7,0-10.51,0-2,.94-2.94,2.9-2.94q4.69,0,9.41,0a2.29,2.29,0,0,1,1.83.75Q22.67,6.62,26,9.91a2.21,2.21,0,0,1,.73,1.72c0,5.09,0,10.18,0,15.27a2.46,2.46,0,0,1-2.65,2.58q-8,0-16,0c-1.95,0-2.81-.89-2.83-2.86,0-3.55,0-7.09,0-10.63ZM16,16.3v0H10.19c-.82,0-1.5.28-1.47,1.23s.73,1.17,1.54,1.17l11.46,0c.84,0,1.54-.28,1.54-1.17s-.72-1.25-1.61-1.24C19.78,16.32,17.91,16.3,16,16.3Zm8.67-4.79-7-6.82c0,1.77-.09,3.54,0,5.29a1.82,1.82,0,0,0,1.81,1.55A27.31,27.31,0,0,0,24.71,11.51ZM13.07,21.2v0c-1,0-2,0-3.06,0-.73,0-1.28.36-1.29,1.16A1.13,1.13,0,0,0,10,23.6c2,0,4.07,0,6.11,0a1.14,1.14,0,0,0,1.29-1.16c0-.83-.53-1.23-1.36-1.23Z"
        fill={colorsTheme.antiFlashWhite}
      />
    </svg>
  );
};
