import { useContext } from 'react';
import { ConfirmationDialogContext } from '../context/ConfirmationDialog';
import { ConfirmMessage } from '../components/ConfirmationDialog';

type ConfirmationDialogParams = {
  open: boolean;
  title: string;
  message: string;
  onAction: (message?: ConfirmMessage) => void;
};

export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);
  const confirmation = (params?: Partial<ConfirmationDialogParams>) =>
    new Promise((res) => {
      openDialog({
        onAction: res,
        ...params,
      });
    });

  return {
    confirmation,
  };
};
