import React, { useMemo, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { useAtom } from 'jotai';
import { CssBaseline } from '@mui/material';
import {
  Tree,
  NodeModel,
  MultiBackend,
  getBackendOptions,
  TreeMethods,
  DragLayerMonitorProps,
} from '@minoru/react-dnd-treeview';
import Box from '@mui/material/Box';

import { TreeItem } from './components/treeItem/TreeItem';
import { Placeholder } from './components/placeholder/Placeholder';
import styles from './WorksFlowTree.module.css';
import { TreeItemDragPreview } from './components/treeItemDragPreview/TreeItemDragPreview';
import { useWorkflows } from './hooks/useWorkflows';
import { TreeItemMultipleDragPreview } from './components/treeItemMultipleDragPreview/TreeItemMultipleDragPreview';
import { CreatedRootElement } from './components/createRootElement/CreateRootElement';
import {
  TypeConfigs,
  WorkItemType,
  WorkflowItem,
  CreateConfigAction,
} from './types';
import { currentConfigIsEditAtom, workflowsTreeAtom } from './state';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';

type WorksFlowTreeProps = {
  loading: boolean;
};

export function WorksFlowTree({ loading }: WorksFlowTreeProps) {
  const [workflowsTree] = useAtom(workflowsTreeAtom);
  const ref = useRef<TreeMethods>(null);
  const {
    updateTreeName,
    deleteTree,
    cloneTree,
    createFolderTreeLocal,
    createConfigTree,
    enabledEdit,
    toggleOpen,
    editConfig,

    handleDrop,
    handleDragStart,
    handleDragEnd,
    canDrop,
    isDragging,
    handleClick,
    selectedNodes,
    refTreeList,
  } = useWorkflows();

  const [currentConfigIsEdit] = useAtom(currentConfigIsEditAtom);
  const { confirmation } = useConfirmationDialog();

  const handleClickConfig = async (
    e: React.MouseEvent,
    node: NodeModel<WorkflowItem>
  ) => {
    if (currentConfigIsEdit[0] !== node.text && currentConfigIsEdit[1]) {
      const confirmationBack = await confirmation();
      if (confirmationBack) {
        handleClick(e, node);
        editConfig(node.id);
      }
    } else {
      handleClick(e, node);
      editConfig(node.id);
    }
  };

  const handleToggleOpen = (id: NodeModel['id']) => toggleOpen(id);
  const handleEnabledEdit = (id: NodeModel['id']) => enabledEdit(id);

  const handleEdit = (id: NodeModel['id'], text: NodeModel['text']) => {
    updateTreeName(id, text);
  };
  const handleDelete = (
    id: NodeModel['id'],
    type?: WorkItemType,
    softDelete?: boolean
  ) => {
    deleteTree(id, type, softDelete);
  };
  const handleClone = (id: NodeModel['id']) => {
    cloneTree(id);
  };
  const handelCreateFolder = (id: NodeModel['id'], level = 0) => {
    if (ref.current) {
      ref.current.open(id ?? 0);
    }
    createFolderTreeLocal(id, level);
  };
  const handelCreateConfig = (
    id: NodeModel['id'],
    configType: TypeConfigs,
    configId: string,
    typeAction: CreateConfigAction
  ) => {
    if (ref.current) {
      ref.current.open(id);
    }
    createConfigTree(id, configType, configId, typeAction);
  };

  const idsOpen = useMemo(() => {
    return workflowsTree
      .filter(
        (workflow) =>
          workflow.data.type === WorkItemType.folder && workflow.data.open
      )
      .map((workflow) => workflow.id);
  }, [workflowsTree]);

  return (
    <>
      <CssBaseline />
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <CreatedRootElement onCreateFolder={handelCreateFolder} />
        <br />
        <Box
          sx={{
            height: '85vh',
            overflowY: 'auto',
          }}
          ref={refTreeList}
        >
          {loading ? (
            'Loading...'
          ) : (
            <>
              {workflowsTree.length ? (
                <Tree
                  ref={ref}
                  tree={workflowsTree}
                  rootId={0}
                  render={(node, { depth, hasChild }) => {
                    const selected = selectedNodes.some(
                      (selectedNode) => selectedNode.id === node.id
                    );

                    return (
                      <TreeItem
                        node={node}
                        depth={depth}
                        onToggle={(id) => {
                          handleToggleOpen(id);
                        }}
                        isOpen={node.data.open}
                        hasChild={hasChild}
                        onEnabledEdit={handleEnabledEdit}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onClone={handleClone}
                        onCreateConfig={handelCreateConfig}
                        onCreateFolder={handelCreateFolder}
                        isSelected={selected}
                        isDragging={selected && isDragging}
                        onClick={handleClickConfig}
                      />
                    );
                  }}
                  onDrop={handleDrop}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  canDrop={canDrop}
                  sort={false}
                  insertDroppableFirst={false}
                  classes={{
                    placeholder: styles.placeholderContainer,
                    dropTarget: styles.dropTarget,
                  }}
                  dragPreviewRender={(
                    monitorProps: DragLayerMonitorProps<WorkflowItem>
                  ) => {
                    if (selectedNodes.length > 1) {
                      return (
                        <TreeItemMultipleDragPreview
                          dragSources={selectedNodes}
                        />
                      );
                    }
                    return <TreeItemDragPreview monitor={monitorProps} />;
                  }}
                  placeholderRender={(node, { depth }) => (
                    <Placeholder node={node} depth={depth} />
                  )}
                  initialOpen={idsOpen}
                />
              ) : (
                <div>No found</div>
              )}
            </>
          )}
        </Box>
      </DndProvider>
    </>
  );
}
