import React, { useContext, useEffect, useState } from 'react';
import {
  ArrayFieldTemplateProps,
  FormContextType,
  RJSFSchema,
} from '@rjsf/utils';

import Grid from '@mui/material/Grid';
import Button, { ButtonProps } from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { FormConfigs } from '../../../models/workFlowTree/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ConfigDetailContext } from './ConfigForm';
import { colorsTheme } from '../../../constants';

function FormGrid(
  props: ArrayFieldTemplateProps<FormConfigs, RJSFSchema, FormContextType>
) {
  const { items, canAdd, onAddClick, title, disabled, readonly } = props;
  return (
    <Grid container={true} spacing={2} sx={{ mt: '10px' }}>
      {items.map((element, index) => (
        <Grid item key={index} style={{ display: 'flex', width: '100%' }}>
          <Grid item={true} xs={10} style={{ marginBottom: '10px' }}>
            {element.children}
          </Grid>
          <Grid item={true} xs={2} key={index} style={{ marginBottom: '10px' }}>
            {element.hasRemove && (
              <RemoveButton
                onClick={element.onDropIndexClick(element.index)}
                style={{ marginLeft: '1rem' }}
              />
            )}
          </Grid>
        </Grid>
      ))}

      {canAdd && (
        <Grid container justifyContent="flex-end">
          <Grid item={true}>
            <Button
              onClick={onAddClick}
              disabled={disabled || readonly}
              color="secondary"
              variant="outlined"
            >
              <AddIcon /> Add {title}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const RemoveButton = (props: ButtonProps) => (
  <Button
    {...props}
    size="small"
    className="button-danger"
    sx={{ color: colorsTheme.red, borderColor: colorsTheme.red }}
  >
    <Grid item xs={8}>
      <ClearIcon />
    </Grid>
  </Button>
);

const ArrayFieldTemplate = (
  props: ArrayFieldTemplateProps<FormConfigs, RJSFSchema, FormContextType>
) => {
  const { title, canAdd } = props;

  const { collapse } = useContext(ConfigDetailContext);

  const [expanded, setExpanded] = useState(collapse);

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => !oldExpanded);
  };

  useEffect(() => {
    setExpanded(collapse);
  }, [collapse]);

  return (
    <Box
      sx={
        canAdd && {
          backgroundColor: colorsTheme.antiFlashWhite,
          px: 2,
          py: 2,
          borderRadius: 5,
        }
      }
    >
      {title && (
        <Typography
          sx={{
            fontWeight: 'bold',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleExpandClick}>
            {expanded ? (
              <ExpandMoreIcon htmlColor={colorsTheme.black} />
            ) : (
              <NavigateNextIcon />
            )}
          </IconButton>
          {title}
        </Typography>
      )}

      {title ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FormGrid {...props} />
        </Collapse>
      ) : (
        <FormGrid {...props} />
      )}
    </Box>
  );
};

export default ArrayFieldTemplate;
