import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const ApisConfigIcon = ({ style }: IconsProps) => {
  const styleClsBlue: CSSProperties = {
    fill: colorsTheme.policeBlue,
  };
  const styleClsWhite: CSSProperties = {
    fill: colorsTheme.white,
  };
  const styleClsWhiteBlue: CSSProperties = {
    fill: colorsTheme.white,
    stroke: colorsTheme.policeBlue,
    strokeWidth: '0.6309',
    strokeMiterlimit: '10',
  };
  const styleClsFillNone: CSSProperties = {
    fill: 'none',
    stroke: colorsTheme.policeBlue,
    strokeWidth: '0.8411',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
  };

  return (
    <svg
      style={style}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 55.6 48.1"
      xmlSpace="preserve"
    >
      <g>
        <polygon
          style={styleClsBlue}
          points="41.2,16.1 16.1,16.1 16.1,14.4 31.9,14.4 34.4,12.5 41.2,12.5 	"
        />
        <rect
          x="16.1"
          y="16.9"
          style={styleClsBlue}
          width="25.1"
          height="2.8"
        />
        <rect
          x="16.1"
          y="21.6"
          style={styleClsBlue}
          width="25.1"
          height="2.8"
        />
        <rect
          x="16.1"
          y="26.4"
          style={styleClsBlue}
          width="25.1"
          height="2.8"
        />
        <rect
          x="16.1"
          y="31.1"
          style={styleClsBlue}
          width="25.1"
          height="2.8"
        />
        <circle style={styleClsWhite} cx="39.6" cy="13.8" r="0.7" />
        <circle style={styleClsWhite} cx="38" cy="13.8" r="0.7" />
        <circle style={styleClsWhite} cx="36.5" cy="13.8" r="0.7" />
        <circle style={styleClsWhite} cx="28.4" cy="24.4" r="6" />
        <path
          style={styleClsWhiteBlue}
          d="M26.7,21.5c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0.1,0.1,0.1,0c0.1,0,0.1-0.1,0.1-0.1
		c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.4,0.4-0.4c0.4,0,0.7,0,1.1,0c0.3,0,0.4,0.1,0.4,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0.1,0.1
		c0.1,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.2,0.5,0c0.3,0.3,0.6,0.6,0.9,0.9c0.2,0.2,0.2,0.3,0,0.5
		c-0.1,0.1-0.2,0.2-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.4,0.3c0,0.4,0,0.8,0,1.3
		c0,0.2-0.1,0.3-0.4,0.3c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0.1-0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.2,0.2,0.4,0,0.5
		c-0.3,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.2-0.5,0c-0.1-0.1-0.2-0.2-0.2-0.2c0,0-0.1-0.1-0.1,0c-0.1,0-0.1,0.1-0.1,0.1
		c0,0.1,0,0.2,0,0.4c0,0.2-0.1,0.3-0.3,0.3c-0.4,0-0.8,0-1.3,0c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1-0.1-0.1
		c-0.1,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.2-0.2,0.2c-0.2,0.1-0.3,0.1-0.5,0c-0.3-0.3-0.6-0.6-0.9-0.9c-0.1-0.2-0.1-0.3,0-0.5
		c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0.1-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4-0.1-0.4-0.4c0-0.4,0-0.8,0-1.2
		c0-0.3,0.1-0.4,0.4-0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
		c-0.2-0.2-0.2-0.4,0-0.5c0.3-0.3,0.6-0.6,0.9-0.9C26.5,21.6,26.6,21.5,26.7,21.5z M28.4,25.8c0.8,0,1.4-0.6,1.4-1.4
		c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C27,25.2,27.6,25.8,28.4,25.8z"
        />
        <g>
          <path
            style={styleClsFillNone}
            d="M23.4,20.7c-0.3,0-13,0-13,0v-3.8H6"
          />
          <circle style={styleClsFillNone} cx="3.9" cy="16.9" r="2.1" />
        </g>
        <g>
          <path
            style={styleClsFillNone}
            d="M26.3,30.1c-0.3,0-15.8,0-15.8,0v3.8H6"
          />
          <circle style={styleClsFillNone} cx="3.9" cy="33.9" r="2.1" />
        </g>
        <g>
          <line
            style={styleClsFillNone}
            x1="22.2"
            y1="25.4"
            x2="11.8"
            y2="25.4"
          />
          <circle style={styleClsFillNone} cx="9.7" cy="25.4" r="2.1" />
        </g>
        <g>
          <path
            style={styleClsFillNone}
            d="M33.3,20.7c0.3,0,13,0,13,0v-3.8h4.4"
          />
          <circle style={styleClsFillNone} cx="52.7" cy="16.9" r="2.1" />
        </g>
        <g>
          <path
            style={styleClsFillNone}
            d="M30.4,30.1c0.3,0,15.8,0,15.8,0v3.8h4.4"
          />
          <circle style={styleClsFillNone} cx="52.7" cy="33.9" r="2.1" />
        </g>
        <g>
          <line
            style={styleClsFillNone}
            x1="34.5"
            y1="25.4"
            x2="44.8"
            y2="25.4"
          />
          <circle style={styleClsFillNone} cx="47" cy="25.4" r="2.1" />
        </g>
      </g>
    </svg>
  );
};
