import * as yup from 'yup';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
  password: yup.string().required('Please enter your password.'),
});

export default loginSchema;
