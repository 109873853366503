import React, { MouseEvent, CSSProperties } from 'react';
import { Box } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { SxProps } from '@mui/system';

import {
  ApisConfigIcon,
  ApplicationsConfigIcon,
  ConnectionsConfigIcon,
  DataOperationConfigIcon,
  Hl7messagetemplateConfigIcon,
  KeymanagementConfigIcon,
  KmslocalkeyConfigIcon,
  RulesConfigIcon,
  SecretsConfigIcon,
  StoredProceduresConfigIcon,
  TemplatesConfigIcon,
  TransformsConfigIcon,
  WorkflowsConfigIcon,
  WorkflowStepsConfigIcon,
  GenericConfigurationConfigIcon,
} from '../../../../components/Icons';

const styleIcon: CSSProperties = {
  height: '20px',
  width: '20px',
};

type TreeIconTypeProps = {
  type: string;
  typeConfig: string;
  onOpen?: (e: MouseEvent) => void;
  sx?: SxProps<object>;
};

export function TreeIconType({
  onOpen,
  type,
  typeConfig,
  sx,
}: TreeIconTypeProps) {
  return (
    <Box onClick={onOpen && onOpen} sx={sx}>
      {type === 'folder' && <FolderIcon />}
      {type === 'config' && typeConfig === 'applications' && (
        <ApplicationsConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'connections' && (
        <ConnectionsConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'APIs' && (
        <ApisConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'workflow-steps' && (
        <WorkflowStepsConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'transforms' && (
        <TransformsConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'workflows' && (
        <WorkflowsConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'templates' && (
        <TemplatesConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'rules' && (
        <RulesConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'hl7messagetemplate' && (
        <Hl7messagetemplateConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'kmslocalkey' && (
        <KmslocalkeyConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'keymanagement' && (
        <KeymanagementConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'secrets' && (
        <SecretsConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'datavalues' && (
        <DataOperationConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'storedprocedures' && (
        <StoredProceduresConfigIcon style={styleIcon} />
      )}
      {type === 'config' && typeConfig === 'genericconfigurations' && (
        <GenericConfigurationConfigIcon style={styleIcon} />
      )}
    </Box>
  );
}
