import { AutoCompleteOption } from '../../components/ControlledAutoComplete';
import { Endpoint } from './types/endpoint';

const endPointOptionData: AutoCompleteOption<Endpoint>[] = [
  {
    id: 'hl7',
    label: 'HL7',
    url: 'hl7/connections/{connectionId}/workflows/{workflowId}/execute?appid={applicationId}&debug=true&formatData=hl7',
    type: 'text/plain',
  },
  {
    id: 'execute',
    label: 'Execute',
    url: 'execute/connections/{connectionId}/workflows/{workflowId}/execute?appid={applicationId}&debug=true&formatData=json',
    type: 'application/json',
  },
  {
    id: 'executegeneric',
    label: 'Execute Generic',
    url: 'executegeneric/connections/{connectionId}/workflows/{workflowId}/execute?appid={applicationId}&debug=true&formatData={formatData}',
    type: 'text/plain',
  },
];

export { endPointOptionData };
