import ControlledTextField, {
  ControlledTextFieldProps,
} from './form/widgets/ControlledTextField';
import SearchIcon from '@mui/icons-material/Search';
import React, { memo } from 'react';

const SearchBox: React.FC<ControlledTextFieldProps> = memo((props) => {
  return (
    <ControlledTextField
      placeholder="Search"
      type="text"
      ActiveIcon={SearchIcon}
      endorsementPosition="start"
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-root': { borderRadius: 30, margin: '0 10px' },
        '& .MuiOutlinedInput-input': { padding: 1.5 },
      }}
      {...props}
    />
  );
});

SearchBox.displayName = 'SearchBox';

export default SearchBox;
