import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const DataOperationConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    shapeRendering: 'geometricPrecision',
    textRendering: 'geometricPrecision',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    ...style,
  };
  const styleClsFil0: CSSProperties = {
    fill: 'none',
  };
  const styleClsFil1: CSSProperties = {
    fill: colorsTheme.policeBlue,
    fillRule: 'nonzero',
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="100px"
      height="100px"
      style={styleCls}
      viewBox="0 0 6.827 6.827"
    >
      <g id="Layer_x0020_1">
        <g id="_503548728">
          <path id="_503548128" style={styleClsFil0} d="M0 0h6.827v6.827H0z" />
          <path
            id="_503548272"
            style={styleClsFil0}
            d="M.853.853h5.12v5.12H.853z"
          />
        </g>
        <path
          style={styleClsFil1}
          d="M4.773 3.505 2.187 5l-.16.092V1.735l.16.093L4.773 3.32l.16.092-.16.092zM2.24 4.722l2.267-1.309L2.24 2.104v2.618z"
        />
        <path
          style={styleClsFil1}
          d="M4.007.853V2.94h-.214V.853h.214zm0 3.034v2.086h-.214V3.887h.214z"
        />
        <path
          style={styleClsFil1}
          d="M4.961 3.307h1.012v.213H4.961l.185-.107z"
        />
        <g id="_503548056">
          <path
            id="_503547456"
            style={styleClsFil1}
            d="M.96 2.4h.96v.213H.96z"
          />
          <path
            id="_503547816"
            style={styleClsFil1}
            d="M.96 4.213h.96v.214H.96z"
          />
        </g>
        <path
          style={styleClsFil1}
          d="M2.453 2.88h.427v.213h-.427zM2.347 3.733h.64v.214h-.64z"
        />
        <path style={styleClsFil1} d="M2.56 4.16v-.64h.213v.64z" />
      </g>
    </svg>
  );
};
