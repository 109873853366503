import { useState, useContext, useEffect } from 'react';

import { Button, Grid, IconButton, Stack } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import { ViewHelpGuidePdfModal } from '../../modals/viewHelpGuidePdfModal';
import { useGetPdf } from '../../hooks/api/queries';
import { AxiosContext } from '../../hoc/withAxios';
import { downloadBlobFile } from '../../lutils';

const pdfFiles = [
  {
    title: 'HDC Configurator Implementation Guide',
    fileName: 'HDC_Configurator_Implementation_Guide.pdf',
  },
  { title: 'Nordic HDC User Guide', fileName: 'Nordic_HDC_User_Guide_1_0.pdf' },
  { title: 'Table of Contents HDC', fileName: 'Table_of_Contents_HDC.pdf' },
];

const HelpGuidePdfPage = () => {
  const [namePdfFile, setNamePdfFile] = useState<string | null>(null);
  const [eventTypePdfFile, setEventTypePdfFile] = useState<
    'open' | 'download' | null
  >(null);
  const handleOpenFilePdf = (nameFile: string) => {
    setNamePdfFile(nameFile);
    setEventTypePdfFile('open');
  };
  const handleCloseFilePdf = () => {
    setNamePdfFile(null);
    setEventTypePdfFile(null);
  };
  const { showToast } = useContext(AxiosContext);

  const { data, isLoading, isError } = useGetPdf({
    namePdfFile,
    queryParams: {
      enabled: !!namePdfFile,
    },
  });
  useEffect(() => {
    if (isError) {
      showToast({
        show: true,
        message: 'Error load pdf',
        severity: 'error',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (!isLoading && eventTypePdfFile === 'download') {
      downloadBlobFile(data, namePdfFile);
      setEventTypePdfFile(null);
    }
  }, [data, isLoading, eventTypePdfFile]);

  const handleDownloadFilePdf = (nameFile: string) => {
    setNamePdfFile(nameFile);
    setEventTypePdfFile('download');
  };
  return (
    <Box sx={{ display: 'flex' }}>
      {!isLoading && data && eventTypePdfFile === 'open' && (
        <ViewHelpGuidePdfModal
          isOpen
          pdfFile={data}
          namePdfFile={namePdfFile}
          close={handleCloseFilePdf}
        />
      )}
      <Grid container item xs={12} sm={12} md={8} lg={6}>
        <Box sx={{ p: 2 }}>
          <Stack alignItems="flex-start">
            {pdfFiles.map(({ fileName, title }) => (
              <Stack direction="row" alignItems="center" key={fileName}>
                <Button
                  onClick={() => handleOpenFilePdf(fileName)}
                  variant="text"
                >
                  {title}
                </Button>
                <IconButton onClick={() => handleDownloadFilePdf(fileName)}>
                  <DownloadIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Grid>
    </Box>
  );
};
export default HelpGuidePdfPage;
