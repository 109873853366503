import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { ServerDataFolder } from '../../../models/workFlowTree/types';

type ResponseCreateFolder = {
  data: ServerDataFolder;
  success: boolean;
  message?: string;
};
type UseCreateFolderValiables = {
  data: ServerDataFolder;
};
type UseCreateFolderParams = {
  mutationParams?: UseMutationOptions<
    ResponseCreateFolder,
    unknown,
    UseCreateFolderValiables
  >;
};

export const useCreateFolder = ({
  mutationParams,
}: UseCreateFolderParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['config-folder'],
    mutationFn: ({ data }: UseCreateFolderValiables) => {
      return axios({
        url: '/api/folders',
        method: 'POST',
        data: data,
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseCreateFolder;
        }
        return Promise.reject(
          response.data.msg ?? 'create folder success false'
        );
      });
    },
  });
};
