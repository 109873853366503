import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { FormConfigs } from '../../../models/workFlowTree/types';

type ResponseConfigById = {
  data: FormConfigs;
  extra: {
    column: string[];
  };
  success: boolean;
  msg?: string;
};
type UseGetConfigByIdParams = {
  endpoint: string;
  configId: string;
  queryParams?: UseQueryOptions<ResponseConfigById>;
};

export const useGetConfigById = ({
  queryParams,
  endpoint,
  configId,
}: UseGetConfigByIdParams) => {
  const { axios } = useContext(AxiosContext);
  return useQuery<ResponseConfigById>({
    ...queryParams,
    queryKey: ['get-config-by-id', endpoint, configId],
    queryFn: () => {
      return axios({
        url: `/api/${endpoint}/@${configId}`,
        method: 'GET',
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseConfigById;
        }
        return Promise.reject(response.data.msg ?? 'config load success false');
      });
    },
  });
};
