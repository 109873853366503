import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const GenericConfigurationConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    fill: colorsTheme.policeBlue,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="100"
      height="100"
      style={style}
    >
      <g id="Direction-3" data-name="Direction">
        <path
          d="M19,29H11.5a8.5,8.5,0,0,1,0-17h9a4.5,4.5,0,0,0,0-9H10a1,1,0,0,0,0,2H20.5a2.5,2.5,0,0,1,0,5h-9a10.5,10.5,0,0,0,0,21H19a1,1,0,0,0,0-2Z"
          style={styleCls}
        />
        <path
          d="M1.293,6.707a.9995.9995,0,0,0,1.414,0L4,5.4141,5.293,6.707A1,1,0,0,0,6.707,5.293L5.4141,4,6.707,2.707A1,1,0,0,0,5.293,1.293L4,2.5859,2.707,1.293A1,1,0,0,0,1.293,2.707L2.5859,4,1.293,5.293A.9994.9994,0,0,0,1.293,6.707Z"
          style={styleCls}
        />
        <path
          d="M24,14a7.0078,7.0078,0,0,0-7,7c0,3.6387,5.666,9.1133,6.312,9.7256a1,1,0,0,0,1.376,0C25.334,30.1133,31,24.6387,31,21A7.0078,7.0078,0,0,0,24,14Zm0,10a3,3,0,1,1,3-3A3.0033,3.0033,0,0,1,24,24Z"
          style={styleCls}
        />
      </g>
    </svg>
  );
};
