import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const KeymanagementConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    display: 'none',
  };
  const styleCls1: CSSProperties = {
    display: 'inline',
    fill: colorsTheme.policeBlue,
  };
  const styleCls2: CSSProperties = {
    fill: colorsTheme.policeBlue,
  };
  const styleCls3: CSSProperties = {
    display: 'inline',
  };

  return (
    <svg
      version="1.1"
      style={style}
      id="camping"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      xmlSpace="preserve"
    >
      <g id="row3">
        <g id="direction" style={styleCls}>
          <path
            style={styleCls1}
            d="M116.1,44L128,31.4l-11.9-12.5H69.3V0h-7.9v18.9H45.6V44h15.7v13.7H11.9L0,70.2l11.9,12.5h49.5V128h7.9V82.8
						h13.1V57.7H69.3V44H116.1z M83.2,38.5h-7.8v-3.9h7.8V38.5z M93.6,38.5h-7.8v-3.9h7.8V38.5z M103.5,38.5h-7.8v-3.9h7.8V38.5z
						 M59.5,24.9h44v3.9h-44V24.9z M35.6,77.2H21.8v-3.9h13.7V77.2z M45.5,77.2h-7.8v-3.9h7.8V77.2z M55.9,77.2h-7.8v-3.9h7.8V77.2z
						 M65.8,77.2H58v-3.9h7.8V77.2z M65.8,67.5h-44v-3.9h44V67.5z M59.5,38.5v-3.9h13.7v3.9H59.5z"
          />
        </g>
        <g id="Carabiner">
          <path
            style={styleCls2}
            d="M88.1,44v57.4h25.4V44H88.1z M101.3,93.8c-3,0-5.5-2.4-5.5-5.3c0-3,2.4-5.4,5.5-5.4c3,0,5.5,2.4,5.5,5.4
						C106.7,91.4,104.3,93.8,101.3,93.8z M101.3,78c-3,0-5.5-2.4-5.5-5.3c0-3,2.4-5.4,5.5-5.4c3,0,5.5,2.4,5.5,5.4
						C106.7,75.6,104.3,78,101.3,78z M101.3,62.2c-3,0-5.5-2.4-5.5-5.3c0-3,2.4-5.4,5.5-5.4c3,0,5.5,2.4,5.5,5.4
						C106.7,59.8,104.3,62.2,101.3,62.2z"
          />
          <path
            id="Shape_20_"
            style={styleCls2}
            d="M106.5,34.7C100.7,15.1,82.2,1.5,61.4,1.5C35.6,1.5,14.5,22,14.5,47.3v33.5
						c0,25.3,21,45.8,46.9,45.8c17.6,0,33.9-9.8,41.9-25.2H92.5c-6.9,10-18.6,16.2-31.1,16.2c-20.8,0-37.6-16.5-37.6-36.8V47.3
						c0-20.3,16.9-36.8,37.6-36.8c16.7,0,31.5,11,36.2,26.7c0.7,2.4,3.3,3.8,5.7,3.1C105.7,39.6,107.2,37.1,106.5,34.7z"
          />
        </g>
        <g id="roller" style={styleCls}>
          <rect
            id="Shape_19_"
            x="40.2"
            y="16.5"
            style={styleCls1}
            width="87.8"
            height="93.3"
          />
          <path
            id="Shape_17_"
            style={styleCls1}
            d="M59.5,109.8H20.9c-9.1,0-16.5-7.3-16.5-16.1V28.4c0-8.9,28.3-12,37.4-12c0,0,34.2,3.1,34.2,12
						v65.2C76,102.5,68.6,109.8,59.5,109.8z"
          />
          <path
            id="Shape_15_"
            style={styleCls1}
            d="M80.4,25c-2.2-8.9-27-11.3-40.4-11.3c-19.3,0-40,4-40,12.6c0,0.7,0.1,1.3,0.4,2L0.3,98.4
						c0,10.4,20.7,15.9,40.1,15.9s40.1-5.6,40.1-16V26.3C80.6,25.9,80.5,25.5,80.4,25z M75.1,98.3c0,4.3-13.5,10.6-34.6,10.6
						c-21.1,0-34.6-6.3-34.6-10.6l0.1-65C13.4,37.2,27.1,39,40,39c10.1,0,26.5-1.4,35-5.8V98.3z M40,33.6c-20.1,0-32-4.2-34.2-6.5
						c-0.2-0.2-0.3-0.4-0.4-0.6c0-0.1,0-0.1,0-0.1c0-2.1,12.1-7.3,34.5-7.3c25.5,0,35,6,35,7.3C75.1,27.6,65.5,33.6,40,33.6z
						 M40.9,31.3c-11.9,0-13.1-3.4-13.1-4.9s1.3-4.9,13.1-4.9c11.9,0,13.1,3.4,13.1,4.9S52.7,31.3,40.9,31.3z M116.4,107.2h-5.5v-5.9
						h5.5V107.2z M116.4,95.5h-5.5v-5.9h5.5V95.5z M116.4,83.7h-5.5v-5.9h5.5V83.7z M116.4,72h-5.5v-5.9h5.5V72z M116.4,60.2h-5.5v-5.9
						h5.5V60.2z M116.4,48.5h-5.5v-5.9h5.5V48.5z M116.4,36.7h-5.5v-5.9h5.5V36.7z M116.4,25h-5.5v-5.9h5.5V25z"
          />
        </g>
        <g id="boat" style={styleCls}>
          <path
            id="Shape_4_"
            style={styleCls1}
            d="M51.1,3.5l-1.9-2l-1.9,2C46.3,4.6,22,30.9,22,64c0,33.1,24.3,59.4,25.3,60.5l1.9,2l1.9-2
						c1-1.1,25.4-27.4,25.4-60.5C76.5,30.9,52.1,4.6,51.1,3.5z M49.2,9c2.9,3.5,8.8,11.2,13.7,21.4H35.5C40.5,20.2,46.3,12.5,49.2,9z
						 M33.3,35.4h32c1.7,4.1,3.2,8.5,4.3,13.1H29C30,44,31.5,39.5,33.3,35.4z M49.2,119c-3-3.6-9.1-11.6-14.1-22.2h28.2
						C58.3,107.4,52.2,115.4,49.2,119z M32.9,91.7C29.6,83.5,27.1,74,27.1,64c0-3.5,0.3-7,0.8-10.3h42.6c0.5,3.3,0.8,6.8,0.8,10.3
						c0,10-2.5,19.5-5.8,27.7H32.9z M99.1,36.3V92c4,1.1,6.9,4.7,6.9,8.9v13.6H87.1v-13.6c0-4.2,2.9-7.8,6.9-8.9V36.3
						c-4-1.1-6.9-4.7-6.9-8.9V13.7H106v13.6C106,31.6,103.1,35.2,99.1,36.3z"
          />
        </g>
      </g>
      <g id="row2">
        <g id="mountain" style={styleCls}>
          <polygon
            id="Shape_25_"
            style={styleCls1}
            points="128,108 0,108 64,20 		"
          />
          <path
            id="Shape_23_"
            style={styleCls1}
            d="M90.1,60.9L64,24.9L40.6,57.1l14-9.8v20.6L64,50l16.5,20.4l-4-20.4L90.1,60.9z"
          />
        </g>
        <g id="torch" style={styleCls}>
          <path
            id="Shape_29_"
            style={styleCls1}
            d="M77.7,1.5l-3.9,23.1l3.9,0.6l3.9-23.1L77.7,1.5z M127.3,46.8l-23.7,3.8l0.7,3.8l23.7-3.8
						L127.3,46.8z M106,20.2L88.5,37.3l2.8,2.8l17.5-17.1L106,20.2z M55.1,56.1l-9.6,9.3L6.1,104.1l-0.5,0.5l16.9,16.5l0.5-0.5L72,72.7
						L55.1,56.1z M43.9,93.8c-4.2,4.1-10,5.1-13,2.2c-3-2.9-1.9-8.6,2.3-12.7c4.2-4.1,10-5.1,13-2.2C49.2,84,48.2,89.7,43.9,93.8z
						 M68,27L57.8,37l2.1,2l31.7,31l10.2-10L68,27z"
          />
          <path
            id="Shape_30_"
            style={styleCls1}
            d="M4.3,102.3L0,106.4l20.6,20.1l4.2-4.2L4.3,102.3z M59.9,39L47.5,51.1c-4,3.9-4.6,9.8-2,14.4
						c0.5,0.9,1.2,1.8,2,2.6l12.3,12c0.8,0.8,1.7,1.4,2.6,1.9c4.7,2.6,10.7,2,14.7-1.9L89.5,68L59.9,39z"
          />
        </g>
        <g id="bench" style={styleCls}>
          <path
            id="Shape_11_"
            style={styleCls1}
            d="M10.1,99.9c-0.7,0-1.3-0.1-2-0.5c-2.2-1.1-3.1-3.7-2-5.8l31.3-61.4c1.1-2.1,3.8-3,6-2
						c2.2,1.1,3.1,3.7,2,5.8L14,97.5C13.3,99,11.7,99.9,10.1,99.9z M120.4,99.4c2.2-1.1,3.1-3.7,2-5.8L91.1,32.3c-1.1-2.1-3.8-3-6-2
						c-2.2,1.1-3.1,3.7-2,5.8l31.3,61.4c0.8,1.5,2.3,2.4,4,2.4C119,99.9,119.7,99.7,120.4,99.4z"
          />
          <path
            id="Shape_10_"
            style={styleCls1}
            d="M110.2,40.3H17.8c-3.4,0-6.2-2.7-6.2-6.1s2.8-6.1,6.2-6.1h92.4c3.4,0,6.2,2.7,6.2,6.1
						S113.7,40.3,110.2,40.3z M128,64.3c0-3.4-2.8-6.1-6.2-6.1H6.2c-3.4,0-6.2,2.7-6.2,6.1c0,3.4,2.8,6.1,6.2,6.1h115.6
						C125.2,70.4,128,67.7,128,64.3z"
          />
        </g>
        <g id="lamp" style={styleCls}>
          <path
            id="Shape_26_"
            style={styleCls1}
            d="M67.9,117.8h-7.9c-17.3,0-31.3-13.7-31.3-30.6V51.5c0-16.9,14.1-30.6,31.3-30.6h7.9
						c17.3,0,31.3,13.7,31.3,30.6v35.7C99.3,104,85.2,117.8,67.9,117.8z M60.1,26.4c-14.1,0-25.7,11.2-25.7,25.1v35.7
						c0,13.8,11.5,25.1,25.7,25.1h7.9c14.1,0,25.7-11.2,25.7-25.1V51.5c0-13.8-11.5-25.1-25.7-25.1H60.1z"
          />
          <path
            id="Shape_24_"
            style={styleCls1}
            d="M69.7,111.9c10.4-4.6,17.9-21.5,17.9-42.3c0-17.9-5.6-32.9-13.7-39.6H85
						c2.1,0,3.8-1.7,3.8-3.7V15.5c0-2-1.7-3.7-3.8-3.7h-3V8.3h-4V1.5H50v6.8h-4v3.5h-3c-2.1,0-3.8,1.7-3.8,3.7v10.9
						c0,2,1.7,3.7,3.8,3.7h11.1c-8.2,6.7-13.7,21.8-13.7,39.6c0,20.7,7.5,37.7,17.9,42.3H32.8v14.6h62.4v-14.6H69.7z M61,68.3
						c-0.2,0.8-0.9,3.1-2,4.9c-2,3.3-4.8,6.8-6.1,8.6c-2.1,2.8-3.7,6.3-3.7,11.7c0,0.9,0.1,1.8,0.2,2.7c-3.3-7.1-5.3-16.5-5.3-26.7
						c0-20.3,8.1-37.7,18-39.6h3.7c9.9,2,18,19.3,18,39.6c0,16-5,30.2-12,36.4c3.1-3,5.1-7.5,5.1-12.5c0-11.1-4.9-16.7-4.9-16.7
						c-0.2,2.9-4.4,8.3-4.4,8.3C67.6,73.6,61,68.3,61,68.3z"
          />
        </g>
      </g>
      <g id="row1" style={styleCls}>
        <g id="bag" style={styleCls3}>
          <path
            id="Shape_1_"
            style={styleCls2}
            d="M99.8,15.8H28.2c-2,0-3.7,1.6-3.7,3.6v30.5c0,2,1.7,3.6,3.7,3.6h8.3V42H53v11.5h22V42h16.5
						v11.5h8.3c2,0,3.7-1.6,3.7-3.6V19.4C103.5,17.4,101.8,15.8,99.8,15.8z M40.1,45.3v17.4h9.2V45.3H40.1z M78.7,45.3v17.4h9.2V45.3
						H78.7z"
          />
          <path
            id="Shape_31_"
            style={styleCls2}
            d="M80.5,1.5v14.4h9.3V1.5H80.5z M38.2,1.5v14.4h9.3V1.5H38.2z"
          />
          <path
            style={styleCls2}
            d="M109.3,82.4V61.6c0-2.8-2.4-5.1-5.2-5.1H91.6V66H75v-9.4H53V66H36.4v-9.4H23.9c-2.9,0-5.2,2.3-5.2,5.1v20.8
						C13,83.2,8.6,88.1,8.6,93.9v21c0,5.8,4.4,10.7,10.1,11.5v0.2h90.6v-0.2c5.7-0.8,10.1-5.7,10.1-11.5v-21
						C119.4,88.1,115,83.2,109.3,82.4z M91.6,121.2H36.4V87.5c0-3,2.5-5.4,5.5-5.4h44.2c3,0,5.5,2.4,5.5,5.4V121.2z"
          />
        </g>
        <g id="knife" style={styleCls3}>
          <path
            style={styleCls2}
            d="M108.6,86.6H29.7v-54C29.7,8.5,0,3.5,0,3.5v102c0,0.6,0,1.2,0.1,1.8c0.9,9.6,9.3,17.1,19.3,17.1h89.2
						c5.3,0,10.2-2.1,13.7-5.6c3.5-3.5,5.7-8.2,5.7-13.4C128,95.1,119.3,86.6,108.6,86.6z M3.9,28.5h4.2v49.2H3.9V28.5z M26.2,107.3
						c-0.8,3.6-4.2,6.3-8.1,6.3s-7.3-2.7-8.1-6.3c-0.2-0.6-0.2-1.2-0.2-1.8c0-4.5,3.7-8.1,8.3-8.1s8.3,3.6,8.3,8.1
						C26.4,106.1,26.3,106.8,26.2,107.3z"
          />
          <path
            id="Shape_8_"
            style={styleCls2}
            d="M18.1,100.7c-2.7,0-4.9,2.2-4.9,4.8c0,2.7,2.2,4.8,4.9,4.8c2.7,0,4.9-2.2,4.9-4.8
						C23,102.9,20.8,100.7,18.1,100.7z"
          />
        </g>
        <g id="binocular_1_" style={styleCls3}>
          <path
            style={styleCls2}
            d="M128,40.5v62.9H77.6V40.5h15.8V29.1H86c-1.1,0-2.1-0.9-2.1-2V15.4c0-1.1,0.9-2,2.1-2h33.5
						c1.1,0,2.1,0.9,2.1,2v11.6c0,1.1-0.9,2-2.1,2h-7.4v11.4H128z"
          />
          <path
            style={styleCls2}
            d="M50.4,40.6v62.9H0V40.6h15.8V29.2H8.4c-1.1,0-2.1-0.9-2.1-2V15.6c0-1.1,0.9-2,2.1-2H42c1.1,0,2.1,0.9,2.1,2
						v11.6c0,1.1-0.9,2-2.1,2h-7.4v11.4H50.4z"
          />
          <path
            id="Shape"
            style={styleCls2}
            d="M55.7,41.8v6.7h16.7v-6.7H55.7z M55.7,92.3V99h16.7v-6.7H55.7z M77.6,106.9v7.6H128v-7.6H77.6z
						 M55.7,51.7v37.2h16.7V51.7H55.7z M0,107v7.6h50.4V107H0z"
          />
        </g>
        <g id="axe" style={styleCls3}>
          <path
            id="Shape_6_"
            style={styleCls2}
            d="M123.7,119.3l-12.4,7.3L39,8.7l12.4-7.3L123.7,119.3z M4.3,22.7c2.3,8.4,5.8,16.8,10.8,24.8
						c4.1,6.7,8.8,12.6,14.1,17.8c1.8-2.3,3.8-4.5,6-6.8L12.6,21.7C9.7,22.2,6.9,22.5,4.3,22.7z"
          />
          <path
            id="Shape_5_"
            style={styleCls2}
            d="M76,26.8c-16.6,9.7-30.6,20.8-40.9,31.7L12.6,21.7c14.3-2.4,31.5-8.9,49.2-18.1
						C67.9,13.2,76,26.8,76,26.8z"
          />
        </g>
      </g>
    </svg>
  );
};
