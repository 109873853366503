import React, { MouseEvent } from 'react';
import { Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type TreeIconOpenProps = {
  hasChild: boolean;
  isOpen: boolean;
  onOpen: (e: MouseEvent) => void;
};

export function TreeIconOpen({ hasChild, isOpen, onOpen }: TreeIconOpenProps) {
  return (
    <Box width={35}>
      {hasChild && (
        <IconButton size="small" onClick={onOpen}>
          {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>
      )}
    </Box>
  );
}
