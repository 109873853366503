import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const Hl7messagetemplateConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    fill: 'none',
    stroke: colorsTheme.policeBlue,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  };
  return (
    <svg
      id="Layer_1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      <polyline style={styleCls} points="28 28 10 28 10 12 38 12 38 28 35 28" />
      <line style={styleCls} x1="37" y1="20" x2="26" y2="20" />
      <line style={styleCls} x1="21" y1="13" x2="21" y2="20" />
      <line style={styleCls} x1="21" y1="24" x2="21" y2="28" />
      <line style={styleCls} x1="27.99" y1="20" x2="27.99" y2="24" />
      <line style={styleCls} x1="11" y1="19" x2="17" y2="19" />
      <rect
        style={styleCls}
        x="4"
        y="7"
        width="40"
        height="26"
        rx="2.84"
        ry="2.84"
      />
      <rect style={styleCls} x="22" y="33" width="4" height="4" />
      <rect style={styleCls} x="19" y="37" width="10" height="1" />
      <polygon points="31 28 31 30 33 29 31 28" />
      <rect style={styleCls} x="-554" y="-486" width="680" height="680" />
    </svg>
  );
};
