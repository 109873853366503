import React from 'react';
import { ButtonGroup } from '@mui/material';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { ThemedOutlinedButton } from '../../../../components/ThemedButton';

type CreatedRootElementProps = {
  onCreateConfigCopy?: (configType: string, configId: string) => void;
  onCreateConfig?: (configId: string, id: NodeModel['id']) => void;
  onCreateFolder: (id: NodeModel['id']) => void;
};

export const CreatedRootElement = ({
  onCreateFolder,
}: CreatedRootElementProps) => {
  const handleCreateFolder = () => {
    onCreateFolder(0);
  };

  return (
    <ButtonGroup size="small" aria-label="split button">
      <ThemedOutlinedButton
        sx={{
          borderRight: '1 solid',
        }}
        onClick={handleCreateFolder}
      >
        Create folder
      </ThemedOutlinedButton>
    </ButtonGroup>
  );
};
