import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const StoredProceduresConfigIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    shapeRendering: 'geometricPrecision',
    textRendering: 'geometricPrecision',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    ...style,
  };
  const styleClsFil0: CSSProperties = {
    fill: colorsTheme.policeBlue,
    fillRule: 'nonzero',
  };
  const styleClsFil1: CSSProperties = {
    fill: colorsTheme.policeBlue,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="100"
      height="100"
      style={styleCls}
      viewBox="0 0 2048 2048"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"></metadata>
        <g id="_490151744">
          <g>
            <polygon
              id="_490144904"
              style={styleClsFil0}
              points="1381.87,531.602 1381.87,790.26 1315.08,790.26 1315.08,531.602 "
            ></polygon>
            <polygon
              id="_490144736"
              style={styleClsFil0}
              points="1257.74,955.242 723.479,955.242 723.479,888.458 1257.74,888.458 "
            ></polygon>
            <polygon
              id="_490144856"
              style={styleClsFil0}
              points="961.237,1438.04 961.239,1301.88 625.783,1301.88 592.521,1301.88 592.521,1268.49 592.522,1024 659.045,1024 659.044,1235.1 994.5,1235.1 1027.76,1235.1 1027.76,1268.49 1027.76,1438.04 "
            ></polygon>
          </g>
          <ellipse
            id="_490144616"
            style={styleClsFil1}
            cx="994.498"
            cy="1615.01"
            rx="176.985"
            ry="176.985"
          ></ellipse>
          <ellipse
            id="_490145072"
            style={styleClsFil1}
            cx="1348.47"
            cy="921.85"
            rx="176.985"
            ry="176.985"
          ></ellipse>
          <rect
            id="_490145216"
            style={styleClsFil1}
            x="448.798"
            y="255.999"
            width="1150.4"
            height="320.16"
            rx="160.08"
            ry="160.08"
          ></rect>
          <rect
            id="_490152368"
            style={styleClsFil1}
            transform="matrix(0.32534 0.32534 -0.32534 0.32534 625.783 744.865)"
            width="544.001"
            height="544.001"
          ></rect>
          <path
            id="_490152296"
            style={styleClsFil1}
            d="M1439.12 255.999l-830.245 0c-88.017,0 -160.036,71.9906 -160.08,159.999l1150.4 0c-0.0437008,-88.0087 -72.0627,-159.999 -160.08,-159.999z"
          ></path>
          <polygon
            id="_490152200"
            style={styleClsFil1}
            points="769.377,888.458 625.783,744.865 448.798,921.85 723.479,921.85 802.769,921.85 "
          ></polygon>
          <path
            id="_490152560"
            style={styleClsFil1}
            d="M1525.46 921.85c0,-86.3304 -61.8119,-158.224 -143.593,-173.84 -10.8142,-2.06457 -21.9768,-3.14528 -33.3922,-3.14528 -11.4154,0 -22.578,1.08071 -33.3922,3.14528 -70.9678,13.5508 -126.898,69.4796 -140.449,140.447 -2.06457,10.8142 -3.14528,21.9768 -3.14528,33.3922l86.2524 0 267.718 0z"
          ></path>
          <path
            id="_490151816"
            style={styleClsFil1}
            d="M1171.49 1615.01c0,-86.3304 -61.8119,-158.224 -143.593,-173.84 -10.8142,-2.06457 -21.9768,-3.14528 -33.3922,-3.14528 -11.4154,0 -22.578,1.08071 -33.3922,3.14528 -70.9678,13.5508 -126.898,69.4796 -140.449,140.447 -2.06457,10.8142 -3.14528,21.9768 -3.14528,33.3922l86.2524 0 267.718 0z"
          ></path>
        </g>
      </g>
    </svg>
  );
};
