import React, { useCallback, useState, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import useCustomForm from '../../../components/form';
import { sortData } from './util';
import useSearch from '../../../hooks/useSearch';
import useConfigList from './useConfigList';
import ControlledTable from '../../../components/Table';
import { Caption } from '../../../components/Typography';
import TopBar, { DeleteConfirmation } from './topbar';
import { colorsTheme } from '../../../constants';

const ConfigurationList = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useCustomForm({ search: '' });
  const searchTerm = watch('search');
  const { data, deleteListItem, setData, columns, errorMessage, keys } =
    useConfigList();
  const { results: searchResult } = useSearch({ data }, searchTerm as string);
  const navigate = useNavigate();

  const [actionRecord, setActionRecord] = useState<unknown>();

  const deleteItem = useCallback((id) => {
    deleteListItem(id);
    setActionRecord(null);
  }, []);

  const sort = useCallback(
    (order: boolean, col: string, data: Record<string, unknown>[]) =>
      sortData(col, order, { data, extra: { column: columns } }, (data) => {
        setData(data);
      }),
    [columns]
  );

  const headers = useMemo(
    () =>
      keys
        .filter((i) => columns.includes(i))
        .map(
          (key) =>
            columns.includes(key) && {
              label: key,
              sortable: true,
              onSort: sort,
            }
        ),
    [columns, keys]
  );

  const renderRow = useCallback(
    (record) =>
      Object.entries(record).map(
        (item, inx) =>
          columns.includes(item[0]) && (
            <TableCell
              onClick={() =>
                navigate(encodeURI(`edit/${Object.entries(record)[0][1]}`), {
                  state: item,
                })
              }
              key={`col-${inx}`}
            >
              {item[1] && (
                <Caption color={colorsTheme.black} fontSize="0.875rem">
                  {`${
                    item[1] instanceof Object
                      ? JSON.stringify(item[1])
                      : (item[1] as string).substring(0, 50)
                  }`}
                </Caption>
              )}
            </TableCell>
          )
      ),
    [columns]
  );

  return (
    <Grid container>
      <TopBar control={control} errors={errors} />
      <Grid item xs={12}>
        <DeleteConfirmation
          visible={!!actionRecord}
          deleteListItem={deleteItem}
          i={actionRecord}
          close={() => setActionRecord(null)}
        />
        <Box sx={{ mt: 4 }}>
          {keys && (
            <ControlledTable
              headers={headers}
              errorMessage={errorMessage}
              actions={[
                {
                  label: 'Edit',
                  icon: <AppRegistrationIcon />,
                  onClick: (record) =>
                    navigate(
                      encodeURI(`edit/${Object.entries(record)[0][1]}`),
                      {
                        state: record,
                      }
                    ),
                },
                {
                  label: 'Delete',
                  icon: <DeleteIcon />,
                  onClick: (record) => setActionRecord(record),
                },
              ]}
              data={searchResult as Record<string, unknown>[]}
              renderRow={renderRow}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConfigurationList;
