import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';
import { LoginForm } from '../../../types/SignIn';

type ResponseLoginOnprem = {
  data: {
    name: string;
    email: string;
  };
  success: boolean;
  message?: string;
};
type UseLoginOnpremValiables = {
  data: LoginForm;
};
type UseLoginOnpremParams = {
  mutationParams?: UseMutationOptions<
    ResponseLoginOnprem,
    unknown,
    UseLoginOnpremValiables
  >;
};

export const useLoginOnprem = ({
  mutationParams,
}: UseLoginOnpremParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['login-onprem'],
    mutationFn: ({ data }: UseLoginOnpremValiables) => {
      return axios({
        withCredentials: true,
        url: 'api/auth-onprem/login',
        method: 'POST',
        data: data,
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseLoginOnprem;
        }
        return Promise.reject(response.data.msg ?? 'login success false');
      });
    },
  });
};
