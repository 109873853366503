import { useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from './useAuth';
import { useStorage } from './useStorage';

const useIdleDetection = () => {
  const { user, logout, refreshToken } = useAuth();
  const [showPrompt, setShowPrompt] = useState(false);

  const { value: prompt } = useStorage('prompt');

  const totalTime = useMemo(
    () =>
      (user?.accessToken?.payload?.exp - user?.accessToken?.payload?.iat) *
      1000,
    [user]
  );

  const { getRemainingTime, reset } = useIdleTimer({
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    timeout: totalTime,
    eventsThrottle: 1000,
    promptBeforeIdle: totalTime * 0.1,
    onIdle: () => {
      logout('logout triggered from onIdle');
    },
    stopOnIdle: true,
    onPrompt: () => setShowPrompt(true),
    onAction: () => {
      const totalTimeNow =
        (user?.accessToken?.payload?.exp * 1000 - Date.now()) / 1000;
      if (totalTimeNow <= 10 && !showPrompt) {
        return refreshToken();
      }
      if (!showPrompt) reset();
    },
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  });

  useEffect(() => {
    if (prompt && !showPrompt) {
      logout();
    }
  }, []);

  return {
    getRemainingTime: getRemainingTime(),
    expiringTime: totalTime,
    idle: showPrompt,
    setShowPrompt,
    reset,
    user,
  };
};

export default useIdleDetection;
