import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const CautionIcon = ({ style }: IconsProps) => {
  const styleCls: CSSProperties = {
    fill: colorsTheme.antiFlashWhite,
  };
  const styleCls1: CSSProperties = {
    fill: colorsTheme.policeBlue,
  };

  return (
    <svg
      version="1.1"
      id="Layer_1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 150.2 137.1"
      xmlSpace="preserve"
    >
      <g>
        <rect x="53.4" y="31.1" style={styleCls} width="41.2" height="95.1" />
        <g>
          <g>
            <path
              style={styleCls1}
              d="M150.2,108.8c0,3.3,0,6.7,0,10c-3.9,12.6-13.4,18.4-26.8,18.3c-32.6-0.4-65.1-0.1-97.7-0.1
					c-14,0-21-4.9-25.7-18.1c0-3.3,0-6.7,0-10c2.2-6.1,5.8-11.5,9-17.1c15.4-26.7,30.8-53.4,46.2-80C60.5,2.6,69.7-1.5,79.9,0.5
					c7.2,1.5,12.2,6,15.8,12.3c13.4,23.3,26.9,46.6,40.3,70C140.9,91.3,146.5,99.5,150.2,108.8z M83,50.4c0.3-5.7-2.8-9.4-7.7-9.5
					c-5.1-0.1-8.4,3.6-8.3,9.5c0,0.8,0,1.6,0.1,2.3c0.7,11.4,1.3,22.8,2,34.2c0.2,4,2.4,6.3,5.8,6.4c3.6,0.1,6-2.2,6.3-6.3
					C81.8,74.7,82.4,62.6,83,50.4z M82.7,108.2c0-4.7-3.3-8.3-7.7-8.3c-4.1,0-7.9,3.7-8,7.8c-0.1,4.3,3.6,8.2,7.9,8.2
					C79.4,115.9,82.7,112.6,82.7,108.2z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
