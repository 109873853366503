import { Page } from 'react-pdf';
import { FC, memo, useState, useEffect } from 'react';
import { Divider } from '@mui/material';

type PdfPageProps = {
  index: number;
  onPageRenderSuccess?: () => void;
};

const PdfPage: FC<PdfPageProps> = memo(({ index, onPageRenderSuccess }) => {
  const [loadSuccess, setLoadSuccess] = useState(false);
  const [reanderSuccess, setRenderSuccess] = useState(false);
  useEffect(() => {
    if (onPageRenderSuccess && loadSuccess && reanderSuccess) {
      onPageRenderSuccess();
    }
  }, [onPageRenderSuccess, loadSuccess, reanderSuccess]);
  return (
    <>
      <Divider>{index}</Divider>
      <Page
        key={`page_${index}`}
        pageNumber={index}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        renderForms={false}
        onLoadSuccess={() => setLoadSuccess(true)}
        onRenderSuccess={() => setRenderSuccess(true)}
      />
    </>
  );
});

PdfPage.displayName = 'PdfPage';

export default PdfPage;
