import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { colorsTheme } from '../constants';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Grid container item direction="row" xs={12}>
      <Box
        sx={{
          mb: -5,
          width: '100%',
          position: 'fixed',
          right: 0,
          bottom: '40px',
        }}
      >
        <Grid item xs={12}>
          <Box sx={{ p: 1, backgroundColor: colorsTheme.yankeesBlue, mt: 1 }}>
            <Box sx={{ textAlign: 'center' }}>
              <p
                style={{
                  color: colorsTheme.white,
                  margin: '0px',
                  fontSize: '0.85rem',
                  fontWeight: 'bold',
                }}
              >
                Copyright: &copy;{year} Nordic. All Rights Reserved
              </p>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Footer;
