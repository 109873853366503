import Typography from '@mui/material/Typography';
import ControlledTextField from '../components/form/widgets/ControlledTextField';
import useCustomForm from '../components/form';
import { forgotEmailSchema } from './schema';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useCallback, memo, useState } from 'react';
import { ResetForm } from '../types/SignIn';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import { ConfirmationDialog } from '../components/ConfirmationDialog';
import { LockIcon } from '../components/Icons';

type OwnProps = {
  close?: () => void;
  isOpen: boolean;
  response: boolean;
  forgot: (value: string) => void;
  resetPassword: (value: ResetForm) => void;
};

const styles = {
  message: {
    mt: 2,
    mb: 2,
    textAlign: 'center',
    mx: 'auto',
    fontWeight: 'bold',
    color: 'secondary',
    fontSize: '0.8rem',
  },
};

const ForgotPassword: React.FC<OwnProps> = memo(
  ({ close, isOpen, response, forgot, resetPassword }) => {
    const {
      handleSubmit: handleForgotSubmit,
      formState: { errors },
      control: forgotPasswordControl,
    } = useCustomForm(
      {
        forgotEmail: '',
      },
      forgotEmailSchema
    );

    const forgotSubmit = useCallback((data) => forgot(data.forgotEmail), []);

    const [resetValues, setResetValues] = useState<{
      data?: Record<string, unknown>;
      isValid: boolean;
    }>({
      isValid: false,
    });

    const reset = useCallback(
      () => resetPassword(resetValues?.data as ResetForm),
      [resetValues]
    );

    return (
      <ConfirmationDialog
        open={isOpen}
        isPrompt={false}
        icon={<LockIcon style={{ width: '100px', height: '100px' }} />}
        actions={[
          {
            ...(!response && {
              label: 'Send code',
              onClick: handleForgotSubmit(forgotSubmit),
            }),
            ...(response && {
              label: 'Change password',
              onClick: reset,
            }),
          },
        ]}
        onAction={close}
      >
        <Grid>
          <Box sx={{ mt: 5 }}>
            <Box textAlign={'center'}>
              <Typography variant="h5" component="h2">
                Forgot Your Password?
              </Typography>
            </Box>

            <Typography sx={styles.message}>
              {!response
                ? 'You will receive one time passcode to reset your password.'
                : 'Please check your email for the Password Reset Code.'}
            </Typography>
          </Box>

          {!response && (
            <ControlledTextField
              type="email"
              placeholder="e.g. username@domain.com"
              required
              fullWidth
              label="Confirm Email"
              name="forgotEmail"
              control={forgotPasswordControl}
              errors={errors}
              curvedBottom
            />
          )}

          {response && (
            <ConfirmPasswordForm
              onChange={(data, valid) =>
                setResetValues({
                  data,
                  isValid: valid,
                })
              }
            />
          )}
        </Grid>
      </ConfirmationDialog>
    );
  }
);

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
