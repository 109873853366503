import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { AxiosContext } from '../hoc/withAxios';

export type Environment = {
  userPoolId: string;
  clientId: string;
  onprem: string;
};

type EnvironmentProviderProps = {
  children: JSX.Element;
};

const defaultEnvironment: Environment = {
  userPoolId: '',
  clientId: '',
  onprem: '',
};

const EnvironmentContext = createContext<{
  environment: Environment;
}>({
  environment: defaultEnvironment,
});

export const EnvironmentProvider = ({ children }: EnvironmentProviderProps) => {
  const [environment, setEnvironment] = useState<Environment | null>(null);
  const { axios } = React.useContext(AxiosContext);

  useLayoutEffect(() => {
    axios({
      url: '/api/environment',
      method: 'GET',
      headers: {
        accept: '*/*',
        'Access-Control-Allow-Origin': 'no-cors',
      },
    })
      .then((result) => {
        if (result.data.success) {
          setEnvironment({
            userPoolId: result.data.data.userPoolId,
            clientId: result.data.data.clientId,
            onprem: result.data.data.onprem,
          });
        } else {
          console.error('Error load environment');
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const value = useMemo(
    () => ({
      environment,
    }),
    [environment]
  );
  if (environment === null) return null;

  return (
    <EnvironmentContext.Provider value={value}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => {
  return useContext(EnvironmentContext);
};
