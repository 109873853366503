import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const HelpIcon = ({ style }: IconsProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <title>Left bar icons</title>
      <path
        d="M29.5,8.07A5.38,5.38,0,0,0,23.91,2.5H8.09a5.37,5.37,0,0,0-5.6,5.61c0,3.24,0,6.47,0,9.71s0,6.66,0,10a1.47,1.47,0,0,0,.87,1.58,1.55,1.55,0,0,0,1.79-.5c1.2-1.22,2.45-2.4,3.62-3.65a3.46,3.46,0,0,1,2.7-1.11c4.15,0,8.3-.07,12.44,0a5.59,5.59,0,0,0,5.61-5.6C29.46,15.07,29.51,11.57,29.5,8.07ZM16,7.87a1.49,1.49,0,0,1,1.43,1.41,1.46,1.46,0,1,1-2.91,0A1.47,1.47,0,0,1,16,7.87Zm1.45,9.27A1.59,1.59,0,0,1,16,18.8a1.58,1.58,0,0,1-1.48-1.64c0-1.2,0-2.4,0-3.59a1.87,1.87,0,0,1,0-.34,1.51,1.51,0,0,1,1.52-1.34,1.55,1.55,0,0,1,1.39,1.49c0,.64,0,1.29,0,1.93h0C17.47,15.92,17.48,16.53,17.47,17.14Z"
        fill={colorsTheme.antiFlashWhite}
      />
    </svg>
  );
};
