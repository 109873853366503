import React from 'react';
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Chip } from '@mui/material';

import { WorkflowItem } from './../../types';

type TreeItemDragPreviewProps = {
  monitor: DragLayerMonitorProps<WorkflowItem>;
};

export const TreeItemDragPreview = ({ monitor }: TreeItemDragPreviewProps) => {
  const item = monitor.item;

  return (
    <Chip
      sx={{
        borderRadius: 2,
      }}
      label={item.text.length > 40 ? item.text.slice(0, 40) + '...' : item.text}
      icon={
        item?.data?.type === 'folder' ? <FolderIcon /> : <InsertDriveFileIcon />
      }
    />
  );
};
