import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useContext } from 'react';
import { AxiosContext } from '../hoc/withAxios';

const Loading = () => {
  const { loading } = useContext(AxiosContext);

  return (
    loading && (
      <Box
        sx={{
          position: 'fixed',
          height: '100vh',
          width: '100vw',
          top: 0,
          left: 0,
          backgroundColor: 'text.primary',
          opacity: 0.5,
          zIndex: '99',
        }}
      >
        <Grid>
          <CircularProgress
            sx={{
              color: 'primary',
              position: 'absolute',
              left: { md: '50%', xs: '40%' },
              top: { md: '50%', xs: '40%' },
            }}
            size="4rem"
          />
        </Grid>
      </Box>
    )
  );
};

export default Loading;
