import React, { useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Collapse,
  Grow,
  List,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NodeModel } from '@minoru/react-dnd-treeview';

import { WorkflowItem, WorkItemType } from '../../types';
import { ConfigItem } from './ConfigItem';
import { Stack } from '@mui/system';
import {
  ConfirmationDialog,
  ConfirmMessage,
} from '../../../../components/ConfirmationDialog';
import { colorsTheme } from '../../../../constants';

export const listConfig = [
  {
    name: 'Application',
    value: 'applications',
  },
  {
    name: 'Connection',
    value: 'connections',
  },
  {
    name: 'API',
    value: 'APIs',
  },
  {
    name: 'Data Transform',
    value: 'transforms',
  },
  {
    name: 'Workflow',
    value: 'workflows',
  },
  {
    name: 'Template',
    value: 'templates',
  },
  {
    name: 'Rule',
    value: 'rules',
  },
  {
    name: 'HL7 Message Templates',
    value: 'hl7messagetemplate',
  },
  {
    name: 'Key Management Service',
    value: 'kmslocalkey',
  },
  {
    name: 'Key Containers',
    value: 'keymanagement',
  },
  {
    name: 'Secrets Manager',
    value: 'secrets',
  },
  {
    name: 'Data Operation',
    value: 'datavalues',
  },
  {
    name: 'Stored Procedures',
    value: 'storedprocedures',
  },
  {
    name: 'Generic Config',
    value: 'genericconfigurations',
  },
];

const styleMenuItem = {
  height: '2.25rem',
  paddingTop: '0.3rem',
  paddingLeft: 4,
  '&:hover': {
    backgroundColor: 'powderblue',
  },
  minWidth: '12rem',
};

type TreeMenuProps = {
  node: NodeModel<WorkflowItem>;
  id: string;
  enabledEdit: boolean;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onCreateFolder: () => void;
  onCreateConfig: (
    configType: string,
    configId: string,
    typeAction: 'create-template' | 'create'
  ) => void;
  onEdit: () => void;
  onDelete: () => void;
  onRemove: () => void;
  onClone: () => void;
  hideEdit?: boolean;
  hideCreateFolder?: boolean;
  hideCreateConfig?: boolean;
};

export function TreeMenu({
  node,
  enabledEdit,
  open,
  anchorEl,
  onClose,
  onCreateFolder,
  onCreateConfig,
  onEdit,
  onDelete,
  onRemove,
  onClone,
  hideEdit = false,
  hideCreateFolder = false,
  hideCreateConfig = false,
}: TreeMenuProps) {
  const [openMenuConfig, setOpenMenuConfig] = useState(false);

  const handleOpenMenuConfig = () => {
    setOpenMenuConfig(!openMenuConfig);
  };

  const handleCreateFolder = () => {
    onCreateFolder();
    onClose();
  };
  const handleCreateConfig = (
    configType: string,
    configId: string,
    typeAction: 'create-template' | 'create'
  ) => {
    onCreateConfig(configType, configId, typeAction);
    onClose();
  };
  const handleEdit = () => {
    onEdit();
    onClose();
  };
  const handleClone = () => {
    onClone();
    onClose();
  };
  const [itemDelete, setItemDelete] = useState<'delete' | 'remove' | null>(
    null
  );
  const handelCloseModal = () => {
    setItemDelete(null);
    onClose();
  };
  const handelDeleteModal = () => {
    if (itemDelete === 'delete') {
      onDelete();
    } else if (itemDelete === 'remove') {
      onRemove();
    }
    handelCloseModal();
  };
  const handleDelete = () => {
    setItemDelete('delete');
  };
  const handleRemove = () => {
    setItemDelete('remove');
  };
  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => onClose()}>
                <List>
                  {!hideCreateFolder &&
                    node?.data?.localCreate !== true &&
                    node?.data?.level < 3 && (
                      <Box sx={styleMenuItem} onClick={handleCreateFolder}>
                        <Typography variant="subtitle1">
                          Create folder
                        </Typography>
                      </Box>
                    )}
                  {!hideCreateConfig && node?.data?.localCreate !== true && (
                    <List component="nav">
                      <Box
                        sx={{
                          ...styleMenuItem,
                          paddingRight: 2,
                          paddingLeft: 1,
                        }}
                        onClick={handleOpenMenuConfig}
                      >
                        <Stack direction="row" justifyContent="flex-start">
                          {openMenuConfig ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ChevronRightIcon />
                          )}
                          <Typography variant="subtitle1">
                            Create config
                          </Typography>
                        </Stack>
                      </Box>
                      <Collapse
                        in={openMenuConfig}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          sx={{ maxHeight: 250, overflow: 'auto' }}
                        >
                          {listConfig.map((config) => (
                            <ConfigItem
                              key={config.value}
                              onCreateConfig={handleCreateConfig}
                              option={config}
                            />
                          ))}
                        </List>
                      </Collapse>
                    </List>
                  )}
                  {!hideEdit &&
                    node?.data?.type === WorkItemType.folder &&
                    handleEdit && (
                      <Box sx={styleMenuItem} onClick={handleEdit}>
                        <Typography variant="subtitle1">Edit</Typography>
                      </Box>
                    )}
                  {node?.data?.localCreate !== true &&
                    enabledEdit === false && (
                      <Box sx={styleMenuItem} onClick={handleClone}>
                        <Typography variant="subtitle1">Clone</Typography>
                      </Box>
                    )}
                  {node?.data?.type === WorkItemType.config &&
                    enabledEdit === false && (
                      <Box
                        sx={{
                          ...styleMenuItem,
                          color: colorsTheme.carnelian,
                        }}
                        onClick={handleRemove}
                      >
                        <Typography variant="subtitle1">
                          Remove from Folder
                        </Typography>
                      </Box>
                    )}
                  {node?.data?.localCreate !== true && (
                    <Box
                      sx={{
                        ...styleMenuItem,
                        color: colorsTheme.carnelian,
                      }}
                      onClick={handleDelete}
                    >
                      <Typography variant="subtitle1">
                        Delete Permanently
                      </Typography>
                    </Box>
                  )}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ConfirmationDialog
        id={node?.id ?? ''}
        onAction={(message: ConfirmMessage) =>
          message === ConfirmMessage.CANCEL
            ? handelCloseModal()
            : handelDeleteModal()
        }
        open={itemDelete !== null}
        title=" Are you sure you want to delete this?"
      >
        {itemDelete === 'delete' && (
          <>
            <span> Completing this action will permanently delete </span>
            <span style={{ color: colorsTheme.carnelian }}> {node.text} </span>
            <span>
              {' '}
              This will Delete all other references to this same item.{' '}
            </span>
            <span style={{ color: colorsTheme.carnelian }}>
              {' '}
              ({node.text}){' '}
            </span>
            <span> This action cannot be undone. </span>
          </>
        )}
      </ConfirmationDialog>
    </>
  );
}
