import {
  useMemo,
  useState,
  useLayoutEffect,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import {
  FormConfigs,
  FormConnections,
  TypeConfigs,
} from '../../../models/workFlowTree/types';
import { useEnvironment } from '../../../hooks/useEnvironment';
import {
  endpointToSchema,
  getFormConfigId,
  initializationFormConfig,
} from '../../../models/workFlowTree/helpers';
import { AxiosContext } from '../../../hoc/withAxios';
import { useAuth } from '../../../hooks/useAuth';
import {
  FormData,
  SchemaProperties,
} from '../../../models/configuration/types';
import { getPrimaryKeyFieldsFunc } from '../../../hooks/misc';
import { useStorage } from '../../../hooks/useStorage';
import {
  messageCreateSuccessful,
  messageNoChanges,
  messageUpdateSuccessful,
} from '../../../constants';
import {
  useCreateConfig,
  useEditConfigById,
} from '../../../hooks/api/mutations';
import { useGetConfigByIdLazy } from '../../../hooks/api/queries';

export type ConfigDetailProps = {
  actionType: 'create' | 'edit';
  formData?: FormConfigs;
  endpoint: TypeConfigs;
  configId?: string;
  onClose: () => void;
  onSuccess: (formData: FormData) => void;
  onChange?: (formData: FormData) => void;
  copy: boolean;
};

const useConfigDetail = ({
  actionType,
  formData,
  endpoint,
  configId,
  onChange,
  onClose,
  onSuccess,
  copy,
}: ConfigDetailProps) => {
  const { environment } = useEnvironment();
  const [selectedItem, setSelectedItem] = useState<object>();
  const [eventCaptured, setEventCaptured] = useState(false);
  const { value: formschema } = useStorage('formschema');
  const [formDataRes, setFormDataRes] = useState<FormConfigs | null>(null);
  const { mutateAsync: mutateConfiguration } = useCreateConfig({
    mutationParams: {
      onSuccess: () => {
        showToast({
          show: true,
          message: messageCreateSuccessful,
          severity: 'success',
        });
      },
      onError: (error: { errors: object; message: string }) => {
        const detailError = Object.entries(error?.errors)
          ?.map(([keyError, messageError]) => `${keyError}: ${messageError}`)
          ?.join();
        const messageError = detailError ?? 'Error inserting record';
        showToast({
          show: true,
          message: messageError,
          severity: 'error',
        });
      },
    },
  });
  const { mutateAsync: mutateEditConfigById } = useEditConfigById({
    mutationParams: {
      onSuccess: () => {
        showToast({
          show: true,
          message: messageUpdateSuccessful,
          severity: 'success',
        });
      },
      onError: (error: { errors: object; message: string }) => {
        const detailError = Object.keys(error?.errors)
          ?.map((item) => item)
          ?.join();
        let messageError = error?.message ?? 'Error inserting record';
        if (detailError.includes('jsonPathTokenDelimiter')) {
          messageError =
            'Error: jsonPathTokenDelimiter must be a single character';
        }
        showToast({
          show: true,
          message: messageError,
          severity: 'error',
        });
      },
    },
  });
  const getConfigById = useGetConfigByIdLazy();

  useLayoutEffect(() => {
    setres(false);
    if (formData) {
      setFormDataRes(formData);
    }
  }, [formData]);

  useLayoutEffect(() => setFormDataRes(initialization), []);

  const { axios, showToast } = useContext(AxiosContext);
  const { res, setres } = useAuth();

  const schema = endpointToSchema(endpoint);

  const primaryKeyField = useMemo(() => {
    const ConfigSchema = (
      formschema?.components?.schemas[schema] as SchemaProperties | undefined
    )?.properties;

    return getPrimaryKeyFieldsFunc(ConfigSchema);
  }, [formschema, schema]);

  const initialization = initializationFormConfig(
    endpoint,
    primaryKeyField,
    schema,
    configId
  );

  const handleChange = useCallback(
    (data) => {
      setEventCaptured(true);
      setFormDataRes(data.formData);
      setres(true);
      if (onChange) {
        onChange(data);
      }
    },
    [setEventCaptured, setFormDataRes, setres, onChange]
  );

  const onError = useCallback(() => {
    showToast({
      show: true,
      message: 'All required fields must be filled',
      severity: 'error',
    });
  }, []);

  useEffect(() => {
    if (
      selectedItem === undefined &&
      endpoint !== 'connections' &&
      endpoint !== 'APIs'
    ) {
      return;
    }

    setFormDataRes((prev) => {
      const updatedElement =
        endpoint === 'connections'
          ? {
              ...prev,
              applications: {
                ...(prev && (prev as FormConnections).applications),
                ...selectedItem,
              },
            }
          : {
              ...prev,
              ...selectedItem,
            };
      return {
        ...formData,
        ...updatedElement,
      };
    });
  }, [selectedItem, endpoint]);

  const isDuplicated = useCallback(
    async (entryId): Promise<string> => {
      if (entryId) {
        return getConfigById({
          endpoint,
          configId: entryId,
        })
          .then((result) => {
            if (result.success) return Promise.resolve('duplicated');
          })
          .catch((errors) => {
            if (errors.message && errors.message.includes(404))
              return Promise.resolve('no duplicate');
            else return Promise.resolve('error');
          });
      } else {
        return Promise.resolve('error');
      }
    },
    [endpoint, environment]
  );

  const handelCancel = useCallback(async () => {
    onClose();
  }, [onClose]);

  const validSubmit = useCallback(
    (data) => {
      return (
        (res && actionType === 'create') ||
        (actionType === 'edit' &&
          JSON.stringify(initialization) !== JSON.stringify(data))
      );
    },
    [actionType, initialization, res]
  );

  const checkEditValidation = useCallback(
    (data) => {
      if (
        actionType === 'edit' &&
        initialization[primaryKeyField] != data[primaryKeyField]
      ) {
        const message = `You are not allowed to edit or use the ${primaryKeyField}`;
        showToast({
          show: true,
          message: message,
          severity: 'error',
        });
        return false;
      }
      return true;
    },
    [initialization, primaryKeyField, endpoint]
  );

  const checkStatusValidation = useCallback(
    async (entityId) => {
      const status = await isDuplicated(entityId);
      if (status === 'error') {
        showToast({
          show: true,
          message: 'Oops, something went wrong. Please try again.',
          severity: 'error',
        });
        return false;
      }
      if (status === 'duplicated' && actionType === 'create' && !copy) {
        const message = `Entered ${primaryKeyField} already exists`;
        showToast({
          show: true,
          message: message,
          severity: 'error',
        });
        return false;
      }
      return true;
    },
    [actionType, primaryKeyField, copy]
  );

  const onSubmit = useCallback(
    async (data) => {
      const schema = endpointToSchema(endpoint);
      const ConfigSchema = (
        formschema?.components?.schemas[schema] as SchemaProperties | undefined
      )?.properties;
      const primaryKeyField: string = getPrimaryKeyFieldsFunc(ConfigSchema);
      const entityId: string = getFormConfigId(
        endpoint,
        primaryKeyField,
        data.formData
      );

      if (validSubmit(data.formData) && !copy) {
        if (
          !checkEditValidation(data.formData) ||
          !(await checkStatusValidation(entityId))
        )
          return;

        if (actionType === 'create') {
          mutateConfiguration({
            endpoint,
            formData: data.formData,
          })
            .then(() => {
              onSuccess(data);
            })
            .catch((error) => console.error(error));
        }

        if (actionType === 'edit') {
          mutateEditConfigById({
            configId: entityId,
            endpoint,
            data: data.formData,
          })
            .then(() => {
              onSuccess(data);
            })
            .catch((error) => console.error(error));
        }
      } else if (copy) {
        if (
          !checkEditValidation(data.formData) ||
          !(await checkStatusValidation(entityId))
        )
          return;

        onSuccess(data);
      } else {
        showToast({
          show: true,
          message: messageNoChanges,
          severity: 'info',
        });
      }
    },
    [
      primaryKeyField,
      axios,
      endpoint,
      environment,
      actionType,
      eventCaptured,
      res,
      onSuccess,
    ]
  );

  return {
    formDataRes,
    handelCancel,
    schema,
    onSubmit,
    formschema,
    handleChange,
    setSelectedItem,
    endpoint,
    actionType,
    onError,
  };
};

export default useConfigDetail;
