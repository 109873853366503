import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const LockIcon = ({ style }: IconsProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
    >
      <title>locak</title>
      <rect
        x="37"
        y="79.45"
        width="113.38"
        height="98.67"
        fill={colorsTheme.antiFlashWhite}
      />
      <path
        d="M100.5,198.79c-17.38,0-34.76,0-52.15,0-15.62,0-23.14-7.52-23.15-23q0-43.19,0-86.36c0-14.88,6.62-22.33,21.38-23,5.57-.25,7-2.25,6.77-7.39-.28-6.7-.35-13.41.95-20.1,4.19-21.52,23.78-37.83,46-38C122.64.7,142.63,17,147,38.67c1.46,7.24,1.12,14.52,1,21.79,0,3.94,1.14,5.57,5.37,5.84,16.81,1.08,22.74,7.65,22.74,24.57q0,42.06,0,84.11c0,16.6-7.27,23.79-24.06,23.81Q126.31,198.82,100.5,198.79Zm0-132.61c7.47,0,15-.14,22.42.06,4.37.11,6.09-1.68,5.88-6s0-8.59-.11-12.89c-.56-15.68-12.62-27.14-28.39-27C85,20.4,73.16,32.05,72.66,47.56c-.13,4.1.16,8.23-.12,12.32-.36,5,1.94,6.54,6.65,6.38C86.28,66,93.39,66.19,100.49,66.18Zm17.17,63.23.52,1.55a44.69,44.69,0,0,0,5.43,0c5.46-.67,6.48,2,5.37,6.66-2.5,10.43-8.77,17.47-18.79,21.26a25.68,25.68,0,0,1-19.65-.29c-3.84-1.6-6,1.12-7.18,3.56-1.55,3.24,2.21,3.22,3.91,3.89a36.71,36.71,0,0,0,49.24-25.21c2.39-9.31,2.39-9.3,12.26-10,.28,0,.54-.42,1.61-1.3-4.86-4.69-9.68-9.29-14.43-13.94-1.6-1.57-2.87-1.17-4.29.23C127,120.35,122.33,124.87,117.66,129.41Zm-33.84,3.67c-3-.3-4.37-.53-5.78-.58-6.79-.26-7.36-.85-5.82-7.17,2.82-11.6,10.15-19,21.65-21.87a27.27,27.27,0,0,1,16.54,1.09c3.7,1.41,6.14-.91,7.06-3.68,1-2.92-2.47-3.22-4.34-3.93-20.33-7.85-43.59,4.45-48.75,25.68-2.26,9.31-2.26,9.3-11.67,10-.28,0-.53.43-2.11,1.79,4.76,4.32,9.44,8.53,14.07,12.79,1.45,1.34,2.85,1.77,4.39.27C73.68,143,78.29,138.5,83.82,133.08Z"
        fill={colorsTheme.policeBlue}
      />
    </svg>
  );
};
