import { CSSProperties } from 'react';
import { colorsTheme } from '../../constants';

type IconsProps = {
  style?: CSSProperties;
};

export const ConfigurationIcon = ({ style }: IconsProps) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <title>Left bar icons</title>
      <path
        d="M24.89,30.7h-.27c-1-.26-1.55-1.16-2.28-1.79a1.38,1.38,0,0,1-.52-1.2c0-.86,0-1.73,0-2.59a1.69,1.69,0,0,1,.57-1.38,9,9,0,0,0,1.91-9.86,8.87,8.87,0,0,0-8.49-5.46,8.5,8.5,0,0,0-7.9,5.06,8.54,8.54,0,0,0,.92,9.34,3.19,3.19,0,0,1,.78,2.48,1.29,1.29,0,0,0,0,.34,4,4,0,0,1-1.72,3.89,1.7,1.7,0,0,1-2.49,0c-.71-.68-1.4-1.37-2.08-2.08a1.62,1.62,0,0,1,0-2.48,5.39,5.39,0,0,1,.54-.53,1.77,1.77,0,0,0,.58-2.62c-.77-1.51-.65-1.6-2.41-1.56a2.05,2.05,0,0,1-2-1V14.83a1.92,1.92,0,0,1,2-1.11h1c1.44,0,1.2,0,1.61-1.07.23-.64.94-1.24.75-1.84s-.91-1-1.39-1.55a1.63,1.63,0,0,1,0-2.43c.48-.52,1-1,1.49-1.51C7,3.69,7.49,3.65,9.07,5.35a1.74,1.74,0,0,0,2.41.52c1.58-.78,1.62-.68,1.66-2.5A1.83,1.83,0,0,1,14.63,1.3h3.56a1.85,1.85,0,0,1,1.49,2.08c0,.52,0,1,0,1.57a.74.74,0,0,0,.54.77c.63.26,1.25.54,1.85.86a.6.6,0,0,0,.84-.11c.39-.42.8-.82,1.21-1.21a1.61,1.61,0,0,1,2.43,0c.54.5,1,1,1.56,1.54,1.55,1.55,1.59,2.15,0,3.64a1.78,1.78,0,0,0-.56,2.47c.76,1.57.65,1.63,2.44,1.63a2,2,0,0,1,2,1.13V20a2,2,0,0,1-2,1c-.5,0-1,0-1.5,0a.69.69,0,0,0-.78.51,10.12,10.12,0,0,1-.88,1.92.63.63,0,0,0,.14.9c.41.39.82.79,1.2,1.22a1.61,1.61,0,0,1,0,2.38c-.61.65-1.26,1.25-1.88,1.9A2.83,2.83,0,0,1,24.89,30.7Z"
        fill={colorsTheme.antiFlashWhite}
      />
      <path
        d="M17.06,27.53c0-.8,0-1.6,0-2.39,0-.47-.19-.82-.71-.81s-.63.4-.63.82c0,1.48,0,3,0,4.44a1.08,1.08,0,0,1-1.66.95c-.29-.17-.24-.47-.25-.74-.06-1.43,0-2.86-.1-4.3a6.17,6.17,0,0,0-1.16-3.16c-.54-.79-1.11-1.55-1.65-2.35-2.15-3.22-.2-6.86,2.82-8.26a.42.42,0,0,1,.32-.05c.14.08.07.24,0,.37a5.53,5.53,0,0,0-.35,2.61,2.89,2.89,0,0,0,2,2.53,2.72,2.72,0,0,0,3.06-1.13,5.17,5.17,0,0,0,.93-2.74c0-.18,0-.41.17-.5s.36.14.5.27a5.63,5.63,0,0,1,1.1,6.49,13.05,13.05,0,0,1-1.17,1.93,6.3,6.3,0,0,0-1.26,4c.06,1.43,0,2.87,0,4.31,0,.77-.4.77-.95.76s-1.08.08-1.05-.75,0-1.55,0-2.32Z"
        fill={colorsTheme.antiFlashWhite}
      />
    </svg>
  );
};
