import * as yup from 'yup';

const forgotEmailSchema = yup.object().shape({
  forgotEmail: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
});

const confirmPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter your password.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Password must be at least 8 characters and it should contain Upper case, Lower case, Number and Special character'
    ),
  confirmPassword: yup
    .string()
    .required()
    .oneOf(
      [yup.ref('password'), null],
      'Please make sure your passwords match'
    ),
});

const confirmationCodeSchema = yup.object().shape({
  code: yup.string().required('You must enter the confirmation code'),
});

export { forgotEmailSchema, confirmPasswordSchema, confirmationCodeSchema };
