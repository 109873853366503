import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosContext } from '../../../hoc/withAxios';

type ResponseDeleteConfigById = {
  success: boolean;
  message?: string;
};
type UseDeleteConfigByIdValiables = {
  endpoint: string;
  configId: string;
};
type UseDeleteConfigByIdParams = {
  mutationParams?: UseMutationOptions<
    ResponseDeleteConfigById,
    unknown,
    UseDeleteConfigByIdValiables
  >;
};

export const useDeleteConfigById = ({
  mutationParams,
}: UseDeleteConfigByIdParams = {}) => {
  const { axios } = useContext(AxiosContext);
  return useMutation({
    ...mutationParams,
    mutationKey: ['delete-config'],
    mutationFn: ({ endpoint, configId }: UseDeleteConfigByIdValiables) => {
      return axios({
        url: `/api/${endpoint}/@${configId}`,
        method: 'DELETE',
      }).then((response) => {
        if (response.data.success) {
          return response.data as ResponseDeleteConfigById;
        }
        return Promise.reject(
          response.data.msg ?? 'delete config success false'
        );
      });
    },
  });
};
