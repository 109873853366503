import React, { useCallback, useMemo, useState, useEffect } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { Subject } from 'rxjs';
import { AxiosType, AxiosEvents } from './types';
import { Toast } from '../hooks/types';
import { useStorage } from '../hooks/useStorage';

export enum AxiosEvent {
  LOGOUT,
}

const AxiosContext = React.createContext<AxiosType>({
  axios: null,
  showToast: () => null,
  toast: null,
  loading: false,
  setLoading: () => null,
});

const WithAxios = ({ children }: { children: JSX.Element }) => {
  const { value: user } = useStorage('user');
  const [loading, setLoading] = useState(false);

  const eventEmitter = useMemo(() => {
    return new Subject<AxiosEvents>();
  }, []);

  const [toast, setToast] = useState<Toast>({
    show: false,
    message: '',
    severity: 'info',
  });
  const onRequest = useCallback(
    (config: AxiosRequestConfig): AxiosRequestConfig => {
      const headers = {
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...(user?.accessToken?.jwtToken && {
          Authorization: `Bearer ${user.accessToken.jwtToken}`,
        }),
      };
      config.headers = {
        ...config.headers,
        ...headers,
      };

      setLoading(true);
      return config;
    },
    [user?.accessToken?.jwtToken]
  );

  const onRequestError = useCallback(
    (error: AxiosError): Promise<AxiosError> => {
      setLoading(false);
      return Promise.reject(error);
    },
    []
  );

  const onResponse = useCallback((response: AxiosResponse): AxiosResponse => {
    setLoading(false);
    return response;
  }, []);

  const onResponseError = useCallback((error: AxiosError): unknown => {
    if (error.response.status === 401) {
      eventEmitter.next({
        type: AxiosEvent.LOGOUT,
        value: error,
      });
    }
    setLoading(false);
    return Promise.reject(error);
  }, []);

  useEffect(() => {
    const requestid = axios.interceptors.request.use(onRequest, onRequestError);
    return () => {
      axios.interceptors.request.eject(requestid);
    };
  }, [onRequest, onRequestError]);

  useEffect(() => {
    const responseid = axios.interceptors.response.use(
      onResponse,
      onResponseError
    );
    return () => {
      axios.interceptors.request.eject(responseid);
    };
  }, [onResponse, onResponseError]);

  return (
    <AxiosContext.Provider
      value={{
        axios: axios,
        eventEmitter,
        toast,
        showToast: setToast,
        loading,
        setLoading,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export default WithAxios;
export { AxiosContext };
