import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { WorksFlowTree } from '../../../models/workFlowTree/WorksFlowTree';
import { ConfigurationShow } from './ConfigurationShow';
import { useWorkflows } from '../../../models/workFlowTree/hooks/useWorkflows';
import { useGetWorksFlows } from '../../../models/workFlowTree/hooks/useGetWorksFlows';
import { useSearchWorksFlows } from '../../../models/workFlowTree/hooks/useSearchWorksFlows';
import { currentConfigWorkflowAtom } from '../../../models/workFlowTree/state';
import { FormConfigs } from '../../../models/workFlowTree/types';
import {
  getFormConfigId,
  endpointToSchema,
  isConfigType,
} from '../../../models/workFlowTree/helpers';
import { ResizeLine } from '../../../components/ResizeLine';
import { Stack } from '@mui/material';
import useCustomForm from '../../../components/form';
import SearchBox from '../../../components/SearchBox';
import { useStorage } from '../../../hooks/useStorage';
import { SchemaProperties } from '../../../models/configuration/types';
import { getPrimaryKeyFieldsFunc } from '../../../hooks/misc';

export const WorkflowList = () => {
  const { value: formschema } = useStorage('formschema');
  const { configId } = useParams();
  const navigate = useNavigate();
  const { saveWorkflows, initialOpenConfig } = useWorkflows();
  const { getWorkFlows, loadingWorksFlows, workflows, setWorkflows } =
    useGetWorksFlows();

  useEffect(() => {
    getWorkFlows();
  }, []);

  const {
    control,
    formState: { errors },
    watch,
  } = useCustomForm({ search: '' });
  const searchTerm = watch('search');

  const { setSearchTerm } = useSearchWorksFlows();

  const [workflowConfilg, setWorkflowConfig] = useAtom(
    currentConfigWorkflowAtom
  );
  const handelConfigCancel = () => {
    if (workflowConfilg && workflowConfilg.localCreate === true) {
      setWorkflows(workflows.filter((item) => item.id !== workflowConfilg.id));
    }
    setWorkflowConfig(false);
  };
  const handelChangeFormConfig = (formData: FormConfigs) => {
    const endpoint = workflowConfilg && workflowConfilg.typeConfig;
    const schema = endpointToSchema(endpoint);
    const ConfigSchema = (
      formschema?.components?.schemas[schema] as SchemaProperties | undefined
    )?.properties;
    const primaryKeyField: string = getPrimaryKeyFieldsFunc(ConfigSchema);
    const formConfigId =
      workflowConfilg &&
      getFormConfigId(workflowConfilg.typeConfig, primaryKeyField, formData);

    if (
      workflowConfilg &&
      workflowConfilg.localCreate === true &&
      formConfigId
    ) {
      setWorkflows(
        workflows.map((item) => {
          if (item.id === workflowConfilg.id) {
            return {
              ...workflowConfilg,
              configId: formConfigId,
              name: formConfigId,
            };
          }
          return item;
        })
      );
    }
  };

  const handelSubmitForm = (formData: FormConfigs) => {
    const endpoint = workflowConfilg && workflowConfilg.typeConfig;
    const schema = endpointToSchema(endpoint);
    const ConfigSchema = (
      formschema?.components?.schemas[schema] as SchemaProperties | undefined
    )?.properties;
    const primaryKeyField: string = getPrimaryKeyFieldsFunc(ConfigSchema);
    const formConfigId =
      workflowConfilg &&
      getFormConfigId(workflowConfilg.typeConfig, primaryKeyField, formData);
    if (workflowConfilg && workflowConfilg.localCreate === true) {
      const updateWorkflows = workflows.map((item) => {
        if (item.id === workflowConfilg.id) {
          return {
            ...workflowConfilg,
            configId: formConfigId,
            name: formConfigId,
            localCreate: false,
            edit: false,
          };
        }
        return item;
      });
      setWorkflows(updateWorkflows);
      saveWorkflows(updateWorkflows, [workflowConfilg.id]);
      setWorkflowConfig({
        ...workflowConfilg,
        edit: false,
        localCreate: false,
      });

      navigate(`/collections/${workflowConfilg.itemId}`);
    }
  };

  useEffect(() => {
    if (configId && !workflowConfilg && workflows.length > 0) {
      const findConfig = workflows.find(
        (item) => isConfigType(item) && item.itemId === configId
      );
      if (findConfig && isConfigType(findConfig)) {
        setWorkflowConfig(findConfig);
        initialOpenConfig(findConfig);
      }
    }
  }, [workflows]);

  useEffect(() => {
    setSearchTerm(searchTerm as string);
  }, [searchTerm]);

  return (
    <Box sx={{ height: '94vh' }}>
      <Stack direction="row" justifyContent="flex-end">
        <SearchBox
          name="search"
          control={control}
          errors={errors}
          endorsementPosition="start"
        />
      </Stack>
      <PanelGroup direction="horizontal">
        <Panel minSize={1} defaultSize={25}>
          <Grid item>
            <WorksFlowTree loading={loadingWorksFlows} />
          </Grid>
        </Panel>
        <PanelResizeHandle>
          <ResizeLine />
        </PanelResizeHandle>
        <Panel minSize={1}>
          <Box>
            {workflowConfilg && (
              <ConfigurationShow
                workflow={workflowConfilg}
                onCancel={handelConfigCancel}
                onChangeForm={handelChangeFormConfig}
                onSubmitForm={handelSubmitForm}
              />
            )}
          </Box>
        </Panel>
      </PanelGroup>
    </Box>
  );
};
