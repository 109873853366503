import { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';

import {
  currentConfigWorkflowAtom,
  workflowsAtom,
  workflowsSearchAtom,
} from '../state';
import { Workflow } from '../types';
import {
  hashFolderInConfigNames,
  isConfigType,
  isFolderType,
} from '../helpers';

export const useSearchWorksFlows = () => {
  const currentConfigWorkflow = useAtom(currentConfigWorkflowAtom)[0];
  const workflows = useAtom(workflowsAtom)[0];
  const setWorkflowsSearch = useAtom(workflowsSearchAtom)[1];
  const [workflowsStatusScreen, setWorkflowsStatusScreen] = useState<
    Workflow[]
  >([]);

  const hashFolder = useMemo(
    () => hashFolderInConfigNames(workflows),
    [workflows]
  );
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if (searchTerm.length > 0) {
      const workflowsSearch = workflows
        .filter((workflow) => {
          const folderIncludSearchType = (
            type: 'configsId' | 'folders',
            workflowId: number
          ) =>
            hashFolder[workflowId] &&
            hashFolder[workflowId][type].some((item) =>
              item.includes(searchTerm.toLowerCase())
            );
          const isSearchConfig =
            isConfigType(workflow) &&
            workflow.configId.toLowerCase().includes(searchTerm.toLowerCase());
          const isSearchFolder = (workflowId: number) =>
            hashFolder[workflowId] &&
            hashFolder[workflowId].folderName.includes(
              searchTerm.toLowerCase()
            );
          const folderIncludSearchConfig = (workflowId: number) =>
            folderIncludSearchType('configsId', workflowId);
          const folderIncludSearchFolder = (workflowId: number) =>
            folderIncludSearchType('folders', workflowId);

          if (
            isFolderType(workflow) &&
            (folderIncludSearchConfig(workflow.id) ||
              folderIncludSearchFolder(workflow.id) ||
              isSearchFolder(workflow.id))
          ) {
            return true;
          } else if (
            isSearchConfig ||
            isSearchFolder(workflow.parentId) ||
            folderIncludSearchFolder(workflow.parentId)
          ) {
            return true;
          }
          return false;
        })
        .map((workflow) => ({
          ...workflow,
          open: isFolderType(workflow),
        }));
      const includeConfig = workflowsSearch.some((workflow) =>
        isConfigType(workflow)
      );
      setWorkflowsSearch(includeConfig ? workflowsSearch : []);
      if (workflowsStatusScreen.length === 0) {
        setWorkflowsStatusScreen(workflows);
      }
    } else if (
      workflowsStatusScreen.length > 0 &&
      JSON.stringify(workflowsStatusScreen) === JSON.stringify(workflows)
    ) {
      if (currentConfigWorkflow) {
        setWorkflowsSearch(
          workflowsStatusScreen.map((workflow) => ({
            ...workflow,
            open:
              isFolderType(workflow) &&
              hashFolder[workflow.id] &&
              hashFolder[workflow.id].configsItemId.some((item) =>
                item.includes(currentConfigWorkflow.name.toLowerCase())
              )
                ? true
                : workflow.open,
          }))
        );
      } else {
        setWorkflowsSearch(workflowsStatusScreen);
      }
    } else {
      if (currentConfigWorkflow) {
        setWorkflowsSearch(
          workflows.map((workflow) => ({
            ...workflow,
            open:
              isFolderType(workflow) &&
              hashFolder[workflow.id] &&
              hashFolder[workflow.id].configsItemId.some((item) =>
                item.includes(currentConfigWorkflow.name.toLowerCase())
              )
                ? true
                : workflow.open,
          }))
        );
      } else {
        setWorkflowsSearch(workflows);
      }
    }
  }, [searchTerm, workflows, hashFolder, currentConfigWorkflow]);

  return {
    searchTerm,
    setSearchTerm,
  };
};
