import { Controller, ControllerProps } from 'react-hook-form';
import {
  TextField,
  TextFieldProps,
  Button,
  SvgIconTypeMap,
} from '@mui/material';
import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { colorsTheme } from '../../../constants';

type OwnProps = {
  ActiveIcon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
    muiName: string;
  };
  InActiveIcon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
    muiName: string;
  };
  endorsementPosition?: 'start' | 'end';
  curvedBottom?: boolean;
  errors: Partial<{ [x: string]: { message?: string } }>;
} & Partial<TextFieldProps> &
  Partial<ControllerProps>;

const ControlledTextField: React.FC<OwnProps> = ({
  ActiveIcon,
  InActiveIcon,
  errors,
  endorsementPosition = 'end',
  curvedBottom,
  type,
  ...props
}) => {
  const [active, setActive] = useState(type !== 'password');
  const theme = useTheme();

  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value } }) => (
          <TextField
            error={errors && !!errors[props.name]}
            onChange={onChange}
            value={value}
            type={active ? 'text' : type}
            variant={props.variant || 'standard'}
            helperText={
              errors && errors[props.name] && errors[props.name].message
            }
            sx={{
              '& .MuiInputLabel-root': { fontSize: 16 },
              '& .MuiInputLabel-root:not(.Mui-error)': {
                color: theme.palette.primary.dark,
              },
              '& .MuiInput-root:before': { borderBottom: `none!important` },
              '& .MuiInput-root:after': { borderBottom: `none!important` },
              '& .MuiInput-underline.Mui-error': {
                color: theme.palette.error.dark,
                borderBottom: `1px solid ${theme.palette.error.dark}!important`,
              },
              '& .MuiInput-underline:not(.Mui-error)': {
                color: colorsTheme.black,
                borderBottom: `1px solid ${theme.palette.primary.dark}!important`,
              },
              '& .MuiInput-underline:before:not(.Mui-error)': {
                borderBottom: `1px solid ${theme.palette.primary.dark}!important`,
              },
              '& .MuiInput-underline:after:not(.Mui-error)': {
                borderBottom: `1px solid ${theme.palette.primary.dark}!important`,
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before':
                {
                  borderBottom: `1px solid ${theme.palette.primary.dark}!important`,
                  ...(curvedBottom && {
                    borderBottomLeftRadius: `20px`,
                    borderBottomRightRadius: '20px',
                  }),
                },
              ...(curvedBottom && {
                '& .MuiInput-underline.Mui-error': {
                  color: theme.palette.error.dark,
                  padding: '0 10px',
                  borderBottom: `1px solid ${theme.palette.error.dark}`,
                  borderBottomLeftRadius: `20px`,
                  borderBottomRightRadius: '20px',
                },
                '& .MuiInput-underline': {
                  color: colorsTheme.black,
                  padding: '0 10px',
                  borderBottom: `1px solid ${theme.palette.primary.dark}`,
                  borderBottomLeftRadius: `20px`,
                  borderBottomRightRadius: '20px',
                },
              }),
            }}
            InputProps={{
              [endorsementPosition === 'start'
                ? 'startAdornment'
                : 'endAdornment']:
                ActiveIcon && InActiveIcon && value ? (
                  <InputAdornment position={endorsementPosition}>
                    <Button
                      sx={{
                        width: 'auto',
                        padding: 0,
                        minWidth: 0,
                        ...(errors &&
                          errors[props.name] && {
                            color: theme.palette.error.dark,
                          }),
                      }}
                      onClick={() => setActive(!active)}
                    >
                      {active ? <ActiveIcon /> : <InActiveIcon />}
                    </Button>
                  </InputAdornment>
                ) : (
                  ActiveIcon && (
                    <InputAdornment position={endorsementPosition}>
                      <Button
                        sx={{
                          width: 'auto',
                          minWidth: 0,
                          padding: 0,
                          cursor: 'default',
                          ...(errors &&
                            errors[props.name] && {
                              color: theme.palette.error.dark,
                            }),
                        }}
                      >
                        <ActiveIcon />
                      </Button>
                    </InputAdornment>
                  )
                ),
            }}
            {...props}
          />
        )}
        {...props}
      />
    </>
  );
};

export default ControlledTextField;
export type { OwnProps as ControlledTextFieldProps };
