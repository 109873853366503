import { useContext } from 'react';

import { WorkflowConfig, WorkflowFolder } from '../types';
import { AxiosContext } from '../../../hoc/withAxios';
import {
  useDeleteConfigById,
  useDeleteFolderById,
} from '../../../hooks/api/mutations';

export const useDeleteTree = () => {
  const { showToast } = useContext(AxiosContext);
  const { mutateAsync: mutateDeleteFolder } = useDeleteFolderById({
    mutationParams: {
      onError: (error: string) => {
        showToast({
          show: true,
          message: error ?? 'Error delete folder',
          severity: 'error',
        });
      },
    },
  });
  const { mutateAsync: mutateDeleteConfig } = useDeleteConfigById({
    mutationParams: {
      onError: (error: string) => {
        showToast({
          show: true,
          message: error ?? 'Error delete config',
          severity: 'error',
        });
      },
    },
  });

  const deleteFolder = (folder: WorkflowFolder, onSuccess?: () => void) => {
    mutateDeleteFolder({
      folderId: folder.folderId,
    })
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => console.error(error));
  };

  const deleteConfig = (workflow: WorkflowConfig, onSuccess: () => void) => {
    mutateDeleteConfig({
      configId: workflow.configId,
      endpoint: workflow.typeConfig,
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => console.error(error));
  };

  return {
    deleteFolder,
    deleteConfig,
  };
};
