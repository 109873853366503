import { NodeModel } from '@minoru/react-dnd-treeview';
import { atom } from 'jotai';

import { isConfigType } from './helpers';
import {
  Workflow,
  WorkflowConfig,
  WorkItemType,
  WorkflowItem,
  WorkflowsTreeHash,
} from './types';

export const workflowsAtom = atom<Workflow[]>([]);
export const workflowsSearchAtom = atom<Workflow[]>([]);
export const workflowsTreeHashAtom = atom<WorkflowsTreeHash>((get) => {
  const hashWorkflow: WorkflowsTreeHash = {};

  get(workflowsAtom)
    .filter((item) => item.type === WorkItemType.folder)
    .forEach((item) => {
      const itemId = `${item.id}`;
      const itemParentId = `${item.parentId}`;
      if (item.parentId === 0) {
        hashWorkflow[itemId] = [];
      } else {
        if (hashWorkflow[itemParentId]) {
          hashWorkflow[itemParentId].push(itemId);
        } else {
          hashWorkflow[itemParentId] = [itemId];
        }
      }
    });

  Object.keys(hashWorkflow).forEach((keyHash) => {
    Object.values(hashWorkflow).forEach((valueHash) => {
      if (valueHash.includes(keyHash)) {
        hashWorkflow[keyHash].push(...valueHash);
      }
    });
  });
  return hashWorkflow;
});
export const workflowsTreeAtom = atom<NodeModel<WorkflowItem>[]>((get) => {
  const workflowsTree = get(workflowsSearchAtom)
    .sort((a, b) => {
      if (a.sort < b.sort) return -1;
      if (a.sort > b.sort) return 1;
      return 0;
    })
    .map(workflowToNodeModel);
  return workflowsTree;
});
export const workflowToNodeModel: (
  workflow: Workflow
) => NodeModel<WorkflowItem> = function (workflow): NodeModel<WorkflowItem> {
  return {
    id: workflow.id,
    parent: workflow.parentId,
    droppable: workflow.type === WorkItemType.folder,
    text: workflow.name,
    data: {
      edit: workflow.edit,
      open: workflow.open,
      type: workflow.type,
      level:
        workflow.type === WorkItemType.folder ? workflow?.level : undefined,
      folderId:
        workflow.type === WorkItemType.folder ? workflow?.folderId : undefined,
      isDuplicate:
        workflow.type === WorkItemType.config && !!workflow?.duplicateId,
      duplicateId:
        workflow.type === WorkItemType.config
          ? workflow?.duplicateId
          : undefined,
      typeConfig: isConfigType(workflow) ? workflow.typeConfig : undefined,
      localCreate: workflow.localCreate,
    },
  };
};
export const currentConfigWorkflowAtom = atom<WorkflowConfig | false>(false);
export const currentConfigIsEditAtom = atom<[string, boolean]>(['', false]);
