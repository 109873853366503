import * as yup from 'yup';

const signupSchema = yup.object().shape({
  displayName: yup.string().required('You must enter your name'),
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must be at least 8 characters and it should contains Upper case, Lower case, Number and Special character'
    ),
});
export default signupSchema;
