import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Application } from '../types/application';
import { Connection } from '../types/connection';
import { Workflow } from '../types/workflow';
import { DropDownType } from '../api';
import { endPointOptionData } from '../data';
import { Endpoint } from '../types/endpoint';
import { AutoCompleteOption } from '../../../components/ControlledAutoComplete';
import { useGetConfigList } from '../../../hooks/api/queries';

export type ExecuteWorkFlowContextProps = {
  connections: AutoCompleteOption<Connection>[];
  applications: AutoCompleteOption<Application>[];
  workflows: AutoCompleteOption<Workflow>[];
  endpoints: AutoCompleteOption<Endpoint>[];
};

export const ExecuteWorkFlowContext =
  React.createContext<ExecuteWorkFlowContextProps>({
    applications: [],
    connections: [],
    workflows: [],
    endpoints: [],
  });

const ExecuteWorkFlowContextProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const [endpoints, setEndpoints] = useState<AutoCompleteOption<Endpoint>[]>(
    []
  );

  const normalize = useCallback((data: object[], key: string) => {
    return data.map((i) => {
      return { id: i[key], label: i[key], ...i };
    });
  }, []);

  const { data: dataApplication } = useGetConfigList({
    endpoint: DropDownType.APPLICATIONS,
  });
  const applications = useMemo(() => {
    return normalize(
      dataApplication?.data ?? [],
      'applicationId'
    ) as AutoCompleteOption<Application>[];
  }, [dataApplication]);
  const { data: dataConnection } = useGetConfigList({
    endpoint: DropDownType.CONNECTIONS,
  });
  const connections = useMemo(() => {
    return normalize(
      dataConnection?.data ?? [],
      'connectionId'
    ) as AutoCompleteOption<Connection>[];
  }, [dataConnection]);
  const { data: dataWorkflow } = useGetConfigList({
    endpoint: DropDownType.WORKFLOW,
  });
  const workflows = useMemo(() => {
    return normalize(
      dataWorkflow?.data ?? [],
      'workflowId'
    ) as AutoCompleteOption<Workflow>[];
  }, [dataWorkflow]);

  useEffect(() => {
    setEndpoints(endPointOptionData);
  }, []);

  const value = useMemo(() => {
    return {
      connections: connections,
      applications: applications,
      workflows: workflows,
      endpoints: endpoints,
    };
  }, [connections, applications, workflows, endpoints]);

  return (
    <ExecuteWorkFlowContext.Provider value={value}>
      {children}
    </ExecuteWorkFlowContext.Provider>
  );
};

export default ExecuteWorkFlowContextProvider;
