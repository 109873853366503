import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import '../custom.scss';
import ThemedButton from './ThemedButton';
import { colorsTheme } from '../constants';
import { CautionIcon } from './Icons';

const styleContent = {
  width: { md: 450, xs: 250 },
  p: 4,
};

const styleContentTitle = {
  mt: 2,
  textAlign: 'center',
  color: 'text.primary',
  fontWeight: 'bold',
  fontsize: 30,
};

const styleContentMessage = {
  mt: 2,
  mb: 2,
  color: colorsTheme.spanishGray,
  fontSize: '0.9rem',
  textAlign: 'center',
  mx: 'auto',
  fontWeight: 'bold',
};

const styleButtonConfirm = {
  backgroundColor: 'primary',
  borderRadius: '20px 20px 20px 20px',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&.MuiButtonBase-root:hover': {
    bgcolor: 'primary',
  },
  color: colorsTheme.white,
};

const styleButtonDismiss = {
  backgroundColor: 'primary',
  borderRadius: '20px 20px 20px 20px',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  '&.MuiButtonBase-root:hover': {
    bgcolor: 'primary',
  },
  color: colorsTheme.white,
  ml: 2,
  width: '100px',
};

enum ConfirmMessage {
  CONFIRM = 'Confirm',
  CANCEL = 'Cancel',
}

type ConfirmationDialogAction = {
  label: ConfirmMessage | string;
  onClick?: (value?: unknown) => void;
  type?: 'negative' | 'positive' | 'neutral';
};

const buttonStyles = {
  positive: styleButtonConfirm,
  negative: styleButtonDismiss,
  neutral: styleButtonDismiss,
};

type ConfirmationDialogProps = {
  id?: string | number;
  open?: boolean;
  title?: string;
  message?: string;
  children?: React.ReactNode;
  actions?: ConfirmationDialogAction[];
  onAction?: (value: ConfirmMessage | string, payload?: unknown) => void;
  icon?: ReactNode;
  isPrompt?: boolean;
};

const defaultButtons: ConfirmationDialogAction[] = [
  {
    label: 'Confirm',
    type: 'positive',
  },
  {
    label: 'Cancel',
    type: 'negative',
  },
];

export const ConfirmationDialog = ({
  children,
  open,
  title,
  onAction,
  isPrompt,
  actions = !isPrompt && defaultButtons,
  icon,
}: ConfirmationDialogProps) => {
  return (
    <Modal
      open={open}
      keepMounted
      sx={{ overflowY: { xs: 'scroll', md: 'unset' } }}
    >
      <Box sx={styleContent} className="ModalPopUpContainer">
        <Box sx={{ textAlign: 'center', mt: -10 }}>
          {icon ?? <CautionIcon style={{ width: '100px', height: '100px' }} />}
        </Box>
        {icon !== undefined && (
          <Box sx={{ textAlign: 'right' }}>
            <Box sx={{ marginTop: -5 }}>
              <i
                onClick={() => onAction(ConfirmMessage.CANCEL)}
                style={{
                  fontSize: 30,
                  color: colorsTheme.tiffanyBlue,
                  cursor: 'pointer',
                }}
              >
                &times;
              </i>
            </Box>
          </Box>
        )}
        <Box sx={styleContentTitle}>
          <Typography
            sx={{ fontWeight: 'bold', color: colorsTheme.tiffanyBlue }}
          >
            {title}
          </Typography>
        </Box>

        <Box sx={styleContentMessage}>{children}</Box>
        {actions && (
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            {actions.map((i, index) => (
              <ThemedButton
                key={`confirmation-btn-${index}`}
                onClick={() => (i.onClick ? i.onClick() : onAction(i.label))}
                size="large"
                sx={i.type ? buttonStyles[i.type] : styleButtonConfirm}
              >
                {i.label}
              </ThemedButton>
            ))}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export { ConfirmMessage };
export type { ConfirmationDialogAction };
