import { createContext, useState, useEffect } from 'react';
import {
  ConfirmMessage,
  ConfirmationDialog,
} from '../components/ConfirmationDialog';
import { useAuth } from '../hooks/useAuth';

type ConfirmationDialogConfig = {
  title?: string;
  message?: string;
  id?: string | number;
  children?: React.ReactNode;
  onAction?: (message?: ConfirmMessage) => void;
};

const initialConfig: ConfirmationDialogConfig = {
  title: 'Are you sure you want to discard the changes?',
  message: '',
  onAction: () => {
    console.log('onActionCallBack');
  },
};

export const ConfirmationDialogContext = createContext<{
  openDialog?: (params: ConfirmationDialogConfig) => void;
}>({});

export const ConfirmationDialogProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { user } = useAuth();
  const [dialogConfig, setDialogConfig] =
    useState<ConfirmationDialogConfig>(initialConfig);
  useEffect(() => {
    if (!user) {
      setDialogOpen(false);
    }
  }, [user]);
  const openDialog = (config: ConfirmationDialogConfig) => {
    setDialogOpen(true);
    setDialogConfig({
      ...initialConfig,
      ...config,
    });
  };
  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(initialConfig);
  };

  const onAction = (i: ConfirmMessage) => {
    resetDialog();
    i === 'Confirm' ? dialogConfig.onAction(i) : '';
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        onAction={onAction}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};
