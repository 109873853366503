import { NodeModel } from '@minoru/react-dnd-treeview';
import initial from '../../components/form/configForm/initialValue';

export type FormApplications = typeof initial.application;
export type FormConnections = typeof initial.connection;
export type FormApis = typeof initial.api;
export type FormWorkflowSteps = typeof initial.workflowStep;
export type FormTransforms = typeof initial.datatransform;
export type FormWorkflows = typeof initial.workflow;
export type FormTemplates = typeof initial.template;
export type FormRules = typeof initial.rule;
export type FormHl7MessageTemplate = typeof initial.hl7messagetemplate;
export type FormKmsLocalKey = typeof initial.kmslocalkey;
export type FormKeyManagement = typeof initial.keymanagement;
export type FormSecrets = typeof initial.secrets;
export type FormDataValues = typeof initial.datavalues;
export type ForStoredProcedure = typeof initial.storedprocedures;
export type ForGenericConfiguration = typeof initial.genericconfigurations;

export type FormConfigs =
  | FormApplications
  | FormConnections
  | FormApis
  | FormWorkflowSteps
  | FormTransforms
  | FormWorkflows
  | FormTemplates
  | FormRules
  | FormHl7MessageTemplate
  | FormKmsLocalKey
  | FormKeyManagement
  | FormSecrets
  | FormDataValues
  | ForStoredProcedure
  | ForGenericConfiguration;

export type TypeConfigs =
  | 'applications'
  | 'connections'
  | 'APIs'
  | 'workflow-steps'
  | 'transforms'
  | 'workflows'
  | 'templates'
  | 'rules'
  | 'hl7messagetemplate'
  | 'kmslocalkey'
  | 'keymanagement'
  | 'KeyContainer'
  | 'secrets'
  | 'datavalues'
  | 'storedprocedures'
  | 'genericconfigurations';

export enum WorkItemType {
  'folder' = 'folder',
  'config' = 'config',
}

export type WorkflowFolder = {
  id: number;
  parentId: number /* root element 0 */;
  folderId: string;
  type: WorkItemType.folder;
  name: string;
  sort: number;
  edit: boolean;
  open: boolean;
  level: number;
  localCreate?: boolean;
};
export type WorkflowConfig = {
  id: number;
  parentId: number /* root element 0 */;
  type: WorkItemType.config;
  name: string;
  typeConfig: TypeConfigs;
  sort: number;
  configId: string;
  itemId: string;
  edit: boolean;
  open: boolean;
  duplicateId?: number | string;
  localCreate?: boolean;
  copy?: boolean;
};
export type Workflow = WorkflowFolder | WorkflowConfig;

export type Config = {
  id?: NodeModel['id'];
  parentId?: NodeModel['id'];
  configType?: TypeConfigs;
};

export type ServerDataConfig = {
  itemId: string;
  sort: number;
  configId: string;
  typeConfig: WorkflowConfig['typeConfig'];
};

export type ServerDataFolder = {
  folderId: string;
  displayName: string | null;
  sort: number;
  configurations: ServerDataConfig[];
  folders: ServerDataFolder[];
};

export type WorkflowItem = {
  type: WorkItemType;
  edit: boolean;
  isDuplicate: boolean;
  level?: number;
  duplicateId?: number | string;
  folderId?: string;
  open?: boolean;
  typeConfig?: TypeConfigs;
  localCreate?: boolean;
};

export type WorkflowStatusItem = {
  id: NodeModel['id'];
  edit: boolean;
  open?: boolean;
};

export type CreateConfigAction = 'create-template' | 'create';

export type WorkflowsTreeHash = {
  [key: string]: string[];
};
