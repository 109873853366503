import React from 'react';

import { Box, CSSObject, Theme, Tooltip, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAuth } from '../hooks/useAuth';
import { bottomMenus, menus } from './data';
import { Menu } from './Menu';
import { colorsTheme } from '../constants';

interface OwnProps {
  onLinkClick: (route: string) => void;
}

const drawerWidth = 'auto';

const openedMixin = (): CSSObject => ({
  width: '230px',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 0,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    transition: 'all 0.5s ease-in-out',
    overflowX: 'hidden',
    ...(open ? openedMixin() : closedMixin(theme)),
  },
}));

export const Sidebar: React.FC<OwnProps> = ({ onLinkClick }) => {
  const { open, setOpen } = useAuth();
  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'text.primary',
          border: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      <Box style={{ textAlign: 'right' }}>
        <IconButton
          onClick={() => setOpen(!open)}
          sx={{
            width: '20px',
            height: '60px',
            mt: 7,
            borderRadius: '10px 0px 0px 10px',
            backgroundColor: colorsTheme.white,
            '&.MuiButtonBase-root:hover': {
              bgcolor: colorsTheme.white,
            },
          }}
        >
          {open && (
            <Tooltip title="Collapse" arrow>
              <ChevronLeftIcon color="primary" />
            </Tooltip>
          )}
          {!open && (
            <Tooltip title="Expand" arrow>
              <ChevronRightIcon color="primary" />
            </Tooltip>
          )}
        </IconButton>

        <Menu items={menus} onLinkClick={onLinkClick} />
      </Box>

      <Menu
        items={bottomMenus}
        onLinkClick={onLinkClick}
        sx={{ marginBottom: '40px' }}
      />
    </Drawer>
  );
};
