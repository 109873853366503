import * as yup from 'yup';

const schema = yup.object().shape({
  endpoint: yup.object().required(),
  connection: yup.object().required(),
  workflow: yup.object().required(),
  application: yup.object().required(),
  payload: yup.string().when('endpoint', (value) => {
    return value && value[0]?.type === 'text/plain'
      ? yup.string().required()
      : yup.object().required();
  }),
});

export default schema;
