import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { Tooltip, Grid, AppBar } from '@mui/material';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import logo from '../assets/images/logo.svg';
import CustomLink from './CustomLink';
import { useAuth } from '../hooks/useAuth';
import Loading from '../hooks/util';
import { useEnvironment } from '../hooks/useEnvironment';
import { colorsTheme } from '../constants';
import { Logo } from './Logo';
import { ChangePassword } from './form/changePassword';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Header = () => {
  const location = useLocation();
  const { environment } = useEnvironment();
  const {
    logout,
    setVerificationBoxopen,
    verificationBoxopen,
    success,
    setsuccess,
    loggedInUser,
    user,
  } = useAuth();

  useEffect(() => {
    if (success) {
      setsuccess(false);
    }
  }, [success]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container>
      <AppBar
        style={
          loggedInUser
            ? { backgroundColor: colorsTheme.white }
            : { backgroundColor: 'transparent', boxShadow: 'none' }
        }
      >
        <Grid container direction="row">
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                pl: { md: 5, xs: 4 },
                mt: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Logo
                type="small"
                style={{
                  height: '50px',
                }}
              />
              {loggedInUser && (
                <CustomLink to="/">
                  <img src={logo} width="110" alt="HDC" />
                </CustomLink>
              )}

              {!loggedInUser && (
                <Box sx={{ ml: { xs: -0.5, md: 0 } }}>
                  <img src={logo} width="110" alt="HDC" />
                </Box>
              )}

              <Loading />
            </Box>
          </Grid>

          {location.pathname.slice(1) !== 'sign-in' &&
            location.pathname.slice(1) !== 'sign-up' &&
            location.pathname.slice(1) !== '404' &&
            loggedInUser && (
              <Grid item xs={6} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mr: { md: 5, xs: 0 },
                  }}
                >
                  <Box sx={{ display: { xs: 'none', md: 'inline-block' } }}>
                    <Tooltip title="Login Date & Time" arrow>
                      <p
                        style={{
                          fontWeight: 'bold',
                          color: colorsTheme.yankeesBlue,
                        }}
                      >
                        {user && dayjs(user.loginTime).format('MMM - DD ddd')}{' '}
                        <Box
                          sx={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                          component="span"
                        >
                          <QueryBuilderIcon />
                        </Box>
                        {user && dayjs(user.loginTime).format(' hh:mm A')}
                      </p>
                    </Tooltip>
                  </Box>

                  <Box sx={{ ml: 2, mt: 0.2 }}>
                    <Button
                      disableElevation
                      onClick={handleClick}
                      sx={{
                        '&.MuiButtonBase-root': {
                          backgroundColor: 'transparent',
                        },
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                      endIcon={
                        <Box sx={{ color: colorsTheme.black, mt: 1 }}>
                          <KeyboardArrowDownIcon />
                        </Box>
                      }
                    >
                      <Box
                        sx={{
                          fontWeight: 'bold',
                          color: colorsTheme.yankeesBlue,
                          textTransform: 'capitalize',
                        }}
                      >
                        {loggedInUser}
                      </Box>
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      {environment && !environment.onprem && (
                        <MenuItem
                          onClick={() =>
                            loggedInUser && setVerificationBoxopen(true)
                          }
                          disableRipple
                          sx={{
                            '&:hover': {
                              backgroundColor: colorsTheme.antiFlashWhite,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              fontWeight: 'bold',
                              color: colorsTheme.yankeesBlue,
                            }}
                          >
                            Change Password
                          </Box>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => logout()}
                        disableRipple
                        sx={{
                          '&:hover': {
                            backgroundColor: colorsTheme.antiFlashWhite,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: 'bold',
                            color: colorsTheme.yankeesBlue,
                          }}
                        >
                          Sign out
                        </Box>
                      </MenuItem>
                    </StyledMenu>
                  </Box>

                  <Tooltip title="Sign out" arrow>
                    <Button onClick={() => logout()} size="large">
                      <Box
                        sx={{
                          color: colorsTheme.yankeesBlue,
                          mt: { md: 0.2, xs: 1 },
                        }}
                      >
                        <ExitToAppIcon />
                      </Box>
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            )}
        </Grid>
      </AppBar>
      {loggedInUser && (
        <ChangePassword
          open={verificationBoxopen}
          onClose={() => setVerificationBoxopen(false)}
        />
      )}
    </Grid>
  );
};

export default Header;
